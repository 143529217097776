import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useRef,
} from 'react';
import useOnClickOutside from './clickOutside';

const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = 'main-menu';

  useOnClickOutside(node, () => setOpen(false));
  const toogleMenu = useCallback(() => setOpen((s) => !s), [setOpen]);

  return (
    <MenuContext.Provider value={{ open, node, menuId, toogleMenu, setOpen }}>
      {children}
    </MenuContext.Provider>
  );
};

export function useMenu() {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be used within an MenuProvider');
  }

  return context;
}
