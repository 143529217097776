import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ small }) => small ? '32px' : '44px'};
  width: 100%;
  margin-top: 20px;
  max-width: 230px;
  border-radius: 36px;
  border: 0;
  background-color: ${({ theme, alternativeButton, deleteButton }) => deleteButton ? theme.colors.red : (alternativeButton ? theme.colors.blue : theme.colors.green)} !important;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ small }) => small ? '13px' : '17px'};
  font-weight: bold;
  padding: 0 10px;
  text-transform: uppercase;
  transition: all 0.3s linear;
  &:hover{
    background-color: ${({ theme, alternativeButton, deleteButton }) => shade(0.2, deleteButton ? theme.colors.red : (alternativeButton ? theme.colors.blue : theme.colors.green))} !important;
  }
`;
