import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ThemeProvider from './styles/ThemeProvider';
import AppProvider from './hooks';
import Routes from './routes';

function App() {

  return(
    <Router>
      <ThemeProvider>
        <AppProvider>
          <Routes />
        </AppProvider>
      </ThemeProvider>
    </Router>
  )
};

export default App;
