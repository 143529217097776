import React from 'react';
import { Container, IconContainer } from './styles';

const MenuItem = ({
  onClick,
  children,
  hasIcon = false,
  iconText,
  alt,
  icon,
  selected = false,
}) => {
  return (
    <Container onClick={onClick} style={{ color: (selected ? 'green' : ''), marginTop: (selected ? '-20px' : '') }} >
      {children}
      {hasIcon && (
        <IconContainer>
          <img src={icon} alt={alt} />
          <p>{iconText}</p>
        </IconContainer>
      )}
    </Container>
  );
}

export default MenuItem;
