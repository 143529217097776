import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { formPost } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Button from '../../Buttons/Button';
import Loading from '../Loading';
import EmptyTable from '../EmptyTable';

const TableCorretores = () => {
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const { addToast } = useToast();
  const history = useHistory();

  const submit = async(produtorID, index) => {
    setLoading((l) => {
      const arr = [...l];
      arr[index] = true;
      return arr;
    });

    const produtor = data[index];
    history.push(`/cadastro-corretor?produtorID=${produtorID}`,{
      produtor
    });
  };
  // useEffect(() => {
  //   setData(user.produtores);
  // }, [setData]);

  useEffect(() => {
    formPost('/PortalCorretor/ListarProdutor', {
      paginacao: -1,
      pagina: 0,
      filtros: `corretoraID.toString() == "${user.corretoraID}"`
    })
      .then((response) => {
        setData(response.data.dados.lista);
        setLoading(new Array(response.data.dados.lista.length).fill(false));
      })
      .catch(() => {
        addToast({
          title: 'Erro ao buscar lista de corretores, tente novamente',
          type: 'error',
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }, [setData, setLoadingTable]);

  if (loadingTable) {
    return <Loading />;
  }

  if (data.length === 0) {
    return <EmptyTable />;
  }

  function formatJsonDate(date) {
    if (!date || date === "") return "";
    return date.substr(0, 10);
  }

  return (
    <TableContainer>
      <thead>
        <tr>
          <th className="tdNome">Nome</th>
          <th>CPF</th>
          <th>Data de nascimento</th>
          <th>E-mail</th>
          <th>Telefone</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          (
            {
              produtorID,
              produtor,
              cpfCnpj,
              dataNascimento,
              contato
            },
            index,
          ) => (
            <tr key={produtorID}>
              <td className="tdNome">{produtor}</td>
              <td>{cpfCnpj}</td>
              <td>{formatJsonDate(dataNascimento)}</td>
              <td>{contato ? contato.email : ''}</td>
              <td>{contato ? contato.telefone : ''}</td>
              <td>
                <Button
                  onClick={() => submit(produtorID, index)}
                  loading={loading[index]}
                  small
                  alternativeButton
                >
                  Editar
                </Button>
              </td>
            </tr>
          ),
        )}
      </tbody>
    </TableContainer>
  );
};

const TableContainer = styled.table`
  margin: 15px 0;
  border: 0;
  width: 100%;

  th {
    font-size: 16px;
    font-family: 'SegoeUI';
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }

  .tdNome {
    text-align: left;
  }

  td {
    font-size: 15px;
    padding: 5px;
    font-family: 'SegoeUI';
    font-weight: normal;
    color: ${({ theme }) => theme.colors.blue};
    vertical-align: middle;
    text-align: center;
    * {
      margin: auto;
    }
  }
`;

export default TableCorretores;
