import React from 'react';
import { Container, StyledBurger, Icon } from './styles';
import menu from '../../assets/images/menu.png';

const Burger = ({ open, setOpen, darkBackground = false, ...props }) => {
  const isExpanded = !!open;

  return (
    <Container>
      <StyledBurger
        aria-label="Toggle menu"
        aria-expanded={isExpanded}
        open={open}
        dark={darkBackground}
        onClick={() => setOpen(!open)}
        {...props}
      >
        <span />
        <span />
        <Icon src={menu} />
      </StyledBurger>
    </Container>
  );
};

export default Burger;
