import React from 'react';
import { useHistory } from 'react-router-dom';
import HeaderPortal from '../../components/Headers/HeaderPortal';
// import TextInput from '../../components/Inputs/TextInput';
// import FormTitle from '../../components/Form/FormTitle';
// import SimpleButton from '../../components/Buttons/SimpleButton';

import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import Container from '../../components/Containers/Container';
import { Main } from './styles';
import { useAuth } from '../../hooks/auth';
import TableCorretores from '../../components/Tables/TableCorretores';
import Button from '../../components/Buttons/Button';

const Corretores = () => {
  const { user } = useAuth();
  const history = useHistory();

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar>
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
              Cotação
            </SidebarListItem>}
          </SidebarList>
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          <Main>
            <ul className="breadcrumb">
              <li><a href="/portal"><span>Home</span></a></li>
              <li><span>Corretores</span></li>
            </ul>
            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Corretores">
                <p>
                  Produtores associados.
                </p>
                <Button
                  onClick={() => history.push('/cadastro-corretor')}
                  // loading={loading[index]}
                  small
                  style={{ "float": "right", "marginBottom": "15px", "marginTop": "0" }}
                // alternativeButton
                >
                  Cadastrar novo
                </Button>
                <TableCorretores />
              </Container>
            </FullPageFlexSection>
          </Main>
        </div>
      </div>
    </>
  );
};

export default Corretores;
