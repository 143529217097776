import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../Buttons/Button';
import { Container, Divider } from './styles';

const OptionCard = ({
  empty = false,
  noSelected = false,
  name,
  price,
  plan,
  submit,
  submitButtonText = 'Contratar',
  buttonProps,
}) => {
  const history = useHistory();

  if (empty) {
    return (
      <Container>
        <p style={{ textAlign: "center" }} >Não há opções disponíveis para esta cotação.</p>
        <Divider />
        {/* <div style={{ justifyContent: 'center', marginBottom: '10px' }}>
          <Button onClick={() => history.go(-1)}>Tentar novamente</Button>
        </div> */}
      </Container>
    );
  }

  if (noSelected) {
    return (
      <Container>
        <p style={{ textAlign: "center" }} >Selecione o plano para visualização.</p>
        <Divider />
      </Container>
    );
  }

  return (
    <Container>
      <h1>{name || plan.nome}</h1>
      <Divider />
      <div style={{ textAlign: "center" }}>
        <h2>{price || `R$ ${plan.valorTotal.toFixed(2)} / mês`}</h2>
      </div>
      <p>
        <b>Operadora:</b> {plan.operadora}
      </p>
      <p>
        <b>Segmentação:</b> {plan.segmentacao}
      </p>
      <p>
        <b>Acomodação:</b> {plan.acomodacao}
      </p>
      <p>
        <b>Abrangência:</b> {plan.abrangencia}
      </p>
      <p>
        <b>Cobertura:</b> {plan.tipoCoparticipacao}
      </p>
      <p>
        <b>Beneficiários:</b> {plan.beneficiarios.length}
      </p>
      <Divider />
      <div>
        <span>ANS {plan.ans}</span>
        <Button onClick={submit} {...buttonProps} style={{ marginBottom: '10px' }}>
          {submitButtonText}
        </Button>
      </div>
    </Container>
  );
};

export default OptionCard;
