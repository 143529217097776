import api, { formPost } from '../../services/api';


export const ListarTipoDependencia = async () => {
  const form = {
    paginacao: -1,
    pagina: 0,
  }
  const response = await formPost('/PortalCorretor/ListarTipoDependencia', form)
  return response.data.dados.lista;
}

export const ListarTipoEstadoCivil = async () => {
  const form = {
    paginacao: -1,
    pagina: 0,
  }
  const response = await formPost('/PortalCorretor/ListarTipoEstadoCivil', form)
  return response.data.dados.lista;
}

export const ListarInformacoesContrato = async (contratoProdutoID) => {
  const form = {
    contratoProdutoID
  }
  const response = await formPost('/PortalCorretor/ListarInformacoesContrato', form)
  return response.data.dados;
}

export const ListarCidades = async (estadoID) => {
  const form = {
    filtros: `estadoID==${estadoID}`,
    paginacao: -1,
    pagina: 0,
  }
  const response = await formPost('/Utils/ListarCidades', form)
  return response.data.dados.lista;
}

export const SelecionarVencimento = async (contratoProdutoID, dataVigencia) => {
  const form = {
    contratoProdutoID,
    dataVigencia
  }

  const response = await formPost('/PortalCorretor/SelecionarVencimento', form)
  return response.data.dados.diasVencimento;
}

export const ConsultarCEP = async (cep) => {
  const form = {
    cep: cep,
  }

  try {
    const response = await formPost('/Utils/ConsultarCEP', form)
    return response?.data?.endereco;
  } catch {
    return undefined;
  }
}

export const ConsultarCPF = async (cpf) => {
  const form = {
    cpf: cpf,
  }
  try {
    const response = await formPost('/Utils/ConsultarCPF', form)
    return response?.data?.dados;
  } catch {
    return undefined;
  }
}

export const ListarEstados = async () => {
  const form = {
    paginacao: -1,
    pagina: 0,
  }
  const response = await formPost('/Utils/ListarEstados', form)
  return response.data.dados.lista;
}

export const SalvarVenda = async (data) => {
  const response = await api.post('/PortalCorretor/SalvarVenda', data)
  if (response.data.sucesso) {
    return response.data.dados.vendaID;
  } else {
    throw new Error(response.data.mensagem)
  }
}

export const EditarVenda = async (data) => {
  console.log('dataA', data)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  const response = await api.post('/PortalCorretor/EditarVenda', data, config)

  if (response.data.sucesso) {
    return response.data.dados.vendaID;
  } else {
    throw new Error(response.data.mensagem)
  }
}

export const requestForm = async (contratoProdutoID) => {
  const {
    formasCobranca,
    diasVencimento,
    datasVigencia,
    urlFichaAssociativa
  } = await ListarInformacoesContrato(contratoProdutoID);
  return {
    tipoDependencia: await ListarTipoDependencia(),
    tipoEstadoCivil: await ListarTipoEstadoCivil(),
    estados: await ListarEstados(),
    formasCobranca,
    diasVencimento,
    datasVigencia,
    urlFichaAssociativa
  }
}
