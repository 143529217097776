import styled from 'styled-components';

export const ContainerStyle = styled.div`
  width: 100%;
  padding: 17px 17px 10px;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 5px 5px;
  height: ${({ fullHeight }) => fullHeight ? '100%' : 'none'};
  ${({ minHeight }) => minHeight && 'min-height: 600px;'}
  ${({ flex }) => (flex &&
    "display: flex; flex-wrap: wrap; align-items: center;"
  )}

  column-gap: 40px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 10px 10px;
    width: 100%;
    width: calc(100% - 10px) !important;
    padding: 20px;
  }

  @media (min-width: ${({ theme }) => theme.mobile}) {
    ${({ columns }) => (
    columns &&
    `column-count: ${columns};`
  )}
  }

  h2{
    font-size: 56px;
    font-family: 'Exo';
    font-weight: bolder;
    color: ${({ theme }) => theme.colors.green};
  }

  h3{
    font-size: 16px;
    font-family: 'Exo';
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 4px;
  }

  p{
    font-size: 15px;
    font-family: 'Exo';
    color: ${({ theme }) => theme.colors.blue};
  }

  h4{
    font-size: 15px;
    font-family: 'SegoeUI';
    color: ${({ theme }) => theme.colors.blue};
    font-weight: bold;
    text-transform: uppercase;
  }

  ul{
    list-style: none;
    padding-top: 40px;
  }

  li, li>button{
    font-size: 18px;
    font-family: 'Exo';
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 10px;
    background-color: transparent;
    border: 0;
  }

`;


export const Remove = styled.button`
  border: 0;
  background-color: transparent;
  height: 100%;

  img{
    margin: 10px;
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
