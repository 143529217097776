import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Plus } from './styles';
import InputText from '../TextInput';
import plusIcon from '../../../assets/images/plus.png';
import minusIcon from '../../../assets/images/minus.png';

const DateInput = ({
  hasIcon,
  icon,
  alt,
  plus,
  minus,
  onMinus,
  onPlus,
  value,
  name,
  onChange,
  ...props
}) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {hasIcon && <img src={icon} alt={alt} />}
          <InputText name={name} type="date" value={value} onChange={onChange} {...props} />
        </div>
        {minus && (
          <Plus type="button" onClick={onMinus}>
            <img src={minusIcon} alt="Remover" />
          </Plus>
        )}
        {plus && (
          <Plus type="button" onClick={onPlus} data-tip="Adicionar Dependente">
            <img src={plusIcon} alt="Adicionar" />
          </Plus>
        )}<ReactTooltip />
      </div>
    </>
  );
};

export default DateInput;
