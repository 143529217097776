import styled from 'styled-components';

export const Container = styled.li`
  height: 90px;
  width: 100%;
  display: inline;
  justify-content: left;
  align-items: center;
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  cursor: pointer;
  &,*{
    font-size: 12px;
    font-family: 'SegoeUI';
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
  }
`;

export const IconContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  /* height: 26px; */
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  p{
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    text-transform: none;
  }
  img{
    height: 20px;
    margin: 0 30px 0 10px;
  }
`;
