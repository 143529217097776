import React from 'react';
import styled from 'styled-components';

const BannerFullPage = ({ text, subtext, onClick, ...props }) => (
  <Container onClick={onClick}>
    <BannerImage {...props} />
    <div>
      <h1>{text}</h1>
      <h2>{subtext}</h2>
    </div>
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;

  & > div {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    right: 30%;
    top: 50%;
    transform: translate(50%, -50%);
  }
  h1 {
    font-size: clamp(12px, 3vw, 49px);
    font-family: 'Exo';
    line-height: 3vw;
    margin-bottom: 1vw;
    color: ${({ theme }) => theme.colors.white};
    span {
      font-weight: bold;
    }
  }
  h2 {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue};
    border-radius: 3vw;
    padding: 0.2vw 2.5vw;
    font-size: clamp(12px, 2.6vw, 44px);
    font-family: 'Exo';
    font-weight: bold;
  }
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
`;

export default BannerFullPage;
