import React from 'react';
import HeaderDeclaracao from '../../components/Headers/HeaderDeclaracao';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import MainContent from '../../components/Sections/MainContent';

import { Main, Flex } from './styles';
import MessageCard from '../../components/Cards/MessageCard';

const DeclaracaoFinalizada = () => {
  return (
    <>
      <MainContent>
        <Sidebar />
        <div style={{ width: '100%', backgroundColor: '#F9F9F9' }}>
          <HeaderDeclaracao />
          <Main>
            <Flex>
              <FullPageFlexSection>
                <MessageCard
                  title="Obrigado"
                  noBorder
                  fullHeight={false}
                  style={{ maxWidth: '482px' }}
                  centerTitle
                >
                  <p>
                    Sua declaração de saúde está em análise. Em breve o corretor
                    entrará em contato com você para dar andamento a contratação
                    do seu Plano de Saúde.
                  </p>
                </MessageCard>
              </FullPageFlexSection>
            </Flex>
          </Main>
        </div>
      </MainContent>
    </>
  );
};

export default DeclaracaoFinalizada;
