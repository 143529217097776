import React from 'react';
import { ErrorMessage } from 'formik';
import { SelectStyle, Container, Error } from './styles';
import dropdownIcon from '../../../../assets/images/dropdown.png';

const SelectSidebar = ({
    hasIcon,
    icon,
    alt,
    options,
    children,
    name,
    placeholderDefault = false,
    placeholderText = 'Selecione...',
    all = false,
    allText = 'Todos',
    ...props
}) => (
    <>
        <Container>
            {hasIcon && <img src={icon} alt={alt} />}
            <SelectStyle as="select" name={name} {...props}>
                {placeholderDefault && <option value="">{placeholderText}</option>}
                {all && <option value="null">{allText}</option>}
                {options &&
                    options.map((option) => {

                        console.log('PROPS', ...props)
                        return (<option value={option.text}>{option.text}</option>)

                    }
                    )}
                {children}
            </SelectStyle>
            <img src={dropdownIcon} alt="selecionar" />
        </Container>
        <Error>{name && <ErrorMessage name={name} />}</Error>
    </>
);

export default SelectSidebar;
