import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import { DatePicker } from 'react-rainbow-components';

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 230px;
  border: 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.blue};
  padding: 0 10px;
  img{
    margin: 10px;
    width: 16px;
    height: 16px;
  }
`;

export const Input = styled(MaskedInput)`
  border: 0;
  padding: 0 0px 0px 20px;
  color: ${({theme}) => theme.colors.blue};
  background-color: transparent;
  width: 100%;

  &, &::placeholder{
    font-family: 'SegoeUI';
    font-size: 16px;
    color: ${({theme}) => theme.colors.blue};
  }
`;

export const DateInput = styled(DatePicker)`
  border: 0;
  padding: 0px 0px 0px 20px;
  color: ${({theme}) => theme.colors.blue};
  background-color: transparent;
  width: 100%;
  span{
    display: none;
  }

  &, &::placeholder{
    font-family: 'SegoeUI';
    font-size: 16px;
    color: ${({theme}) => theme.colors.blue};
  }
  div{
    width: 100%;
  }
  *{
    border: 0px !important;
    /* margin: 0px !important; */
    padding-left: 0px !important;
  }
  input, input:active {
    padding: 0px !important;
    height: 50px;
    background-color: ${({theme}) => theme.colors.backgroundWhite} !important;
    width: 100%;
    color: ${({theme}) => theme.colors.blue};
    border: 0px;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  input, input::placeholder{
    font-family: 'SegoeUI';
    color: ${({theme}) => theme.colors.blue};
  }
`;

export const Error = styled.span`
  display: block;
  color: ${({theme}) => theme.colors.red};
  font-size: 14px;
  padding: 2px 0px;
  height: 16px;
  width: 100%;
`;
