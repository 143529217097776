import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  p{
    text-align: center;
  }
`;

export const Body = styled.div`
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  *{
    width: 100%;
    text-align: center;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 3px;
  margin: 15px 0;
  border: 0;
  background: ${({theme}) =>theme.colors.green};
`;
