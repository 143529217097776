export const phoneNumberMask = (userInput) => {
  let numbers = userInput.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join("").length;
  }

  if (numberLength > 10) {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  } else {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
}

export const cpfMask = [
  /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,
];

export const cnpjMask = [
  /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/ ,/\d/,
];

export const cepMask = [
  /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/
];

export const dateMask = [
  /[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/
]

export const numberMask = (userInput) => {
  const mascara = [];
  const resultado = userInput?.replace(/[^0-9]/g, '');
  for (var i=0; i < resultado.length;i++) {
    mascara.push(/[0-9]/);
  }
  return mascara;
}

export const getMaskByType = (type) => {
  switch (type) {
    case 'phone':
      return phoneNumberMask;
    case 'cpf':
      return cpfMask;
      return phoneNumberMask;
    case 'cnpj':
      return cnpjMask;
    case 'cep':
      return cepMask;
    case 'date':
      return dateMask;
    case 'number':
      return numberMask;
    default:
      return false;
  }
}
