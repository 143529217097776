import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  FirstContainer,
  MainContainer,
  MenuItem,
  UserItem,
} from './styles';
import HeaderMobile from '../HeaderMobile';
import logo from '../../../assets/images/logoLogin.png';
import { useAuth } from '../../../hooks/auth';

const Header = () => {
  const history = useHistory();
  const { user } = useAuth();

  return (
    <>
      <HeaderMobile />
      <Container>
        <FirstContainer>
          <div>
            <div>
              <p>ANS 420671</p>
            </div>
            <div>
              <p>
                Capitais | <span>4007.2664</span>
              </p>
              <p>
                Capitais | <span>4007.2664</span>
              </p>
            </div>
          </div>
        </FirstContainer>
        <MainContainer>
          <div style={{ width: '100%' }}>
            <img src={logo} alt="Logo da Addere" />
            <div>
              <MenuItem onClick={() => history.push('/portal')}>
                Portal de Vendas
              </MenuItem>
              <MenuItem>Comunicados</MenuItem>
              <MenuItem>Campanhas</MenuItem>
              <UserItem>Olá, {user.nome.split(' ')[0]}</UserItem>
            </div>
          </div>
        </MainContainer>
      </Container>
    </>
  );
};

export default Header;
