import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { Input, InputReadonly, Container, Plus, Error } from './styles';
import plusIcon from '../../../assets/images/plus.png';
import { getMaskByType } from '../masks';
import FileBase64 from '../FileBase64';

const TextInput = ({
  hasIcon,
  icon,
  alt,
  plus,
  onPlus,
  name,
  type,
  // value,
  placeholder,
  disabled,
  error,
  onChange,
  onDone,
  ...props
}) => {
  const mask = getMaskByType(type);
  return (
    <>
      <div style={{ display: 'flex', width:'100%' }}>
        <Container {... props.style ? {style:props.style} : {}}>
          {hasIcon && <img src={icon} alt={alt} />}
          {type !== 'file' ?
            <Field name={name} type="text" onChange={onChange} {...props}>
              {({ field }) => (
                <Input
                  {...field}
                  mask={mask}
                  placeholder={placeholder}
                  type="text"
                  disabled={disabled}
                  // value={value}
                />
              )}
            </Field>
            :
            <Field name={name} type="text" onChange={onChange} {...props}>
              {({ field }) => (
                <FileBase64
                  multiple={false}
                  onDone={onDone}
                />
                // <InputReadonly
                //   {...field}
                //   mask={mask}
                //   placeholder={placeholder}
                //   type="text"
                //   disabled={disabled}
                //   // onChange={onChange}
                //   // value={value}
                // />
              )}
            </Field>
          }
        </Container>
        {plus && (
          <Plus onClick={onPlus}>
            <img src={plusIcon} alt="Adicionar" />
          </Plus>
        )}
      </div>
      <Error>
        <ErrorMessage name={name} />
        {error}
      </Error>
    </>
  );
};

export default TextInput;
