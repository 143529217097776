import React from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import GlobalStyleProvider from '../GlobalStyleProvider';

const standartTheme = {
  mobile: '768px',
  colors: {
    gray: "#717272",
    backgroundWhite: "#F9F9F9",
    white: "#FFF",
    green: "#6DB85E",
    blue: "#182268",
    red: "#BB0000",
    darkGreen: "#25363F",
    alternativeGreen: "#537A4B",
    black: "#16171C",
    darkBlue: "#153353",
    header: {
      background: '#25363F',
      backgroundLight: '#FFF'
    }
  }
}

const ThemeProvider = ({children}) => {
  return (
    <SCThemeProvider theme={standartTheme}>
      <GlobalStyleProvider/>
      {children}
    </SCThemeProvider>
  )
}

export default ThemeProvider;
