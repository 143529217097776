import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import userIcon from '../../../assets/images/user.png';
// import search from '../../../assets/images/search.png';
import { useAuth } from '../../../hooks/auth';
import HeaderMobile from '../HeaderMobile';

import {
  Container,
  FirstContainer,
  Bar,
  // Search,
  UserArea,
  TabArea,
  Tab,
  Avatar,
  // SearchContainer,
} from './styles';

const HeaderPortal = () => {
  const history = useHistory();
  const { user, signOut } = useAuth();
  const urlSelected = window.location.href.split('/').pop();
  const [selected, setSelected] = useState(urlSelected);
  const [dadoEquipe, setDadoEquipe] = useState(sessionStorage.getItem('dadoEquipe'));

  useEffect(() => {
  }, [dadoEquipe, setDadoEquipe]);

  return (
    <>
      <HeaderMobile usuario={user} logout={signOut} />
      <Container>
        <FirstContainer>
          <Bar>
            <span>Portal de vendas</span>
            {/* <SearchContainer>
              <img src={search} alt="pesquisar" />
              <Search />
            </SearchContainer> */}
            <UserArea>
              <div>
                <p>{user.nome.split(' ')[0]}</p>
                <button type="button" onClick={signOut}>
                  Sair
                </button>
              </div>
              <Avatar>
                <img src={userIcon} alt="user" />
              </Avatar>
            </UserArea>
          </Bar>
          <TabArea>
            {selected === 'portal' &&
              <Tab selected onClick={() => {
                history.push('/portal');
              }}>
                Movimentação
              </Tab>
            }
            {selected !== 'portal' &&
              <Tab onClick={() => {
                history.push('/portal');
              }}>
                Movimentação
              </Tab>
            }
            {selected === 'cadastro-envio' &&
              <Tab selected onClick={() => {
                history.push('cadastro-envio')
              }}>
                Status de Resposta
              </Tab>
            }
            {selected !== 'cadastro-envio' &&
              <Tab onClick={() => {
                if (sessionStorage.getItem('dadoEquipe') && !user.corretora) {
                  history.push('cadastro-envio')
                } else if (!sessionStorage.getItem('dadoEquipe') && !user.corretora) {
                  alert('Selecione a corretora para consulta.')
                } else {
                  history.push('cadastro-envio')
                }
              }}>
                Status de Resposta
              </Tab>
            }
            {selected === 'vendas-confirmadas' &&
              <Tab selected onClick={() => history.push('vendas-confirmadas')}>
                Vendas confirmadas
              </Tab>
            }
            {selected !== 'vendas-confirmadas' &&
              <Tab onClick={() => {
                if (sessionStorage.getItem('dadoEquipe') && !user.corretora) {
                  history.push('vendas-confirmadas')
                } else if (!sessionStorage.getItem('dadoEquipe') && !user.corretora) {
                  alert('Selecione a corretora para consulta.')
                } else {
                  history.push('vendas-confirmadas')
                }
              }}>
                Vendas confirmadas
              </Tab>
            }

            {/* <Tab>Carteirinha</Tab> */}
          </TabArea>
        </FirstContainer>
        {/* <div className="fluxo-nav">
          <p>.</p>
        </div> */}
      </Container>
      {/* <div>
        <Breadcrumb crumbs={crumbs} selected={selected} />
      </div> */}
    </>
  );
};

export default HeaderPortal;
