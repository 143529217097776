import styled from 'styled-components';


export const ResumoStyled = styled.div`
  h3 {
    font-size: 15px;
  }

  .dividir { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border: 0;
    background-color: transparent;
  
    @media (max-width: ${({ theme }) => theme.mobile}) {
      & > *{
        width: 100% !important;
      }
    }
    & > *{
      width: calc(20% - 10px) !important;
    }
  }`