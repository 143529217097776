import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderPortal from '../../components/Headers/HeaderPortal';

import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import OptionCard from '../../components/Cards/OptionCard';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import Container from '../../components/Containers/Container';
import { Main, Flex } from './styles';

// import astra from '../../assets/images/icons/astra.png';
// import card from '../../assets/images/icons/card.png';
import file from '../../assets/images/icons/file.png';
// import heart from '../../assets/images/icons/heart.png';
import search from '../../assets/images/icons/search.png';
import status from '../../assets/images/icons/status.png';
import { useAuth } from '../../hooks/auth';
import SelectSidebar from '../../components/Inputs/Select/SelectSidebar';

const SalesPortal = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [dadoEquipe, setDadoEquipe] = useState();

  useEffect(() => {
    setDadoEquipe(sessionStorage.getItem('dadoEquipe'))
  }, [dadoEquipe, setDadoEquipe]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar>
          {!user.corretora && user.equipes.length > 1 &&
            <SidebarList title="Corretoras">
              <SelectSidebar
                placeholderDefault
                onChange={(e) => {
                  sessionStorage.setItem('dadoEquipe', e.target.value);
                  setDadoEquipe(e.target.value);
                }}
                value={sessionStorage.getItem('dadoEquipe')}
              >
                {user.equipes.map(equipes => (
                  <option
                    value={equipes.equipeVendasID}
                  >
                    {equipes.corretora}
                  </option>
                ),
                )}
              </SelectSidebar>
            </SidebarList>
          }
          {(!user.corretora && user.equipes.length === 1) ?
            (
              <SidebarList title="Corretoras">
                <SelectSidebar
                  value={sessionStorage.getItem('dadoEquipe')}
                >
                  {user.equipes.map(equipes => {
                    sessionStorage.setItem('dadoEquipe', equipes.equipeVendasID);
                    // setDadoEquipe(user.equipes);
                    return (
                      <option
                        value={equipes.equipeVendasID}
                      >
                        {equipes.corretora}
                      </option>
                    )
                  },
                  )}
                </SelectSidebar>
              </SidebarList>) : ''
          }
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => {
              if (dadoEquipe) {
                history.push('/cadastro');
              } else if (!dadoEquipe) {
                alert('Selecione a corretora para venda.')
              }
            }}>
              Cotação
            </SidebarListItem>}
          </SidebarList>
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          <Main>
            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <div style={{ display: 'none' }} className="mobile">
                {!user.corretora && user.equipes.length > 1 &&
                  <SidebarList title="Corretoras" >
                    <SelectSidebar
                      placeholderDefault
                      onChange={(e) => {
                        sessionStorage.setItem('dadoEquipe', e.target.value);
                        setDadoEquipe(e.target.value);
                      }}
                      value={sessionStorage.getItem('dadoEquipe')}
                    >
                      {user.equipes.map(equipes => (
                        <option
                          value={equipes.equipeVendasID}
                        >
                          {equipes.corretora}
                        </option>
                      ),
                      )}
                    </SelectSidebar>
                  </SidebarList>
                }
                {(!user.corretora && user.equipes.length === 1) ?
                  (
                    <SidebarList title="Corretoras">
                      <SelectSidebar
                        value={sessionStorage.getItem('dadoEquipe')}
                      >
                        {user.equipes.map(equipes => {
                          sessionStorage.setItem('dadoEquipe', equipes.equipeVendasID);
                          // setDadoEquipe(user.equipes);
                          return (
                            <option
                              value={equipes.equipeVendasID}
                            >
                              {equipes.corretora}
                            </option>
                          )
                        },
                        )}
                      </SelectSidebar>
                    </SidebarList>) : ''
                }
              </div>
              <Container
                title="O que você deseja fazer?"
              >
                <Flex>
                  {!user.corretora && <OptionCard src={search} title="Cotação" onClick={() => {
                    if (dadoEquipe) {
                      history.push('/cadastro');
                    } else if (!dadoEquipe) {
                      alert('Selecione a corretora para venda.')
                    }
                  }} />}
                  {!user.corretora && <OptionCard src={file} title="Cadastro do Beneficiário" onClick={() => {
                    if (dadoEquipe) {
                      history.push('/cadastro');
                    } else if (!dadoEquipe) {
                      alert('Selecione a corretora para venda.')
                    }
                  }} />}
                  {user.corretora && <OptionCard src={file} title="Corretores" onClick={() => history.push('/corretores')} />}
                  <OptionCard src={status} title="Status da Proposta" onClick={() => {
                    if (dadoEquipe && !user.corretora) {
                      history.push('/cadastro-envio');
                    } else if (!dadoEquipe && !user.corretora) {
                      alert('Selecione a corretora para consulta.')
                    } else {
                      history.push('/cadastro-envio');
                    }
                  }} />
                  {/* <OptionCard src={card} title="Carteirinha" /> */}
                </Flex>
              </Container>
            </FullPageFlexSection>
            {/* <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Envio de documentação">
                <Flex>
                  <OptionCard src={heart} title="Declaração de saúde" />
                  <OptionCard src={astra} title="Ficha de Filiação Astra" />
                </Flex>
              </Container>
            </FullPageFlexSection> */}
          </Main>
        </div>
      </div>
    </>
  );
};

export default SalesPortal;
