import React from 'react';
import { useHistory } from 'react-router-dom';
import HeaderPortal from '../../components/Headers/HeaderPortal';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import MainContent from '../../components/Sections/MainContent';

import { Main, Flex } from './styles';
import MessageCard from '../../components/Cards/MessageCard';
import { useAuth } from '../../hooks/auth';

const CadastroFinalizado = () => {
  const { user } = useAuth();
  const history = useHistory();

  return (
    <>
      <MainContent>
        <Sidebar>
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
              Cotação
            </SidebarListItem>}
            {/* <SidebarListItem onClick={() => history.push('/cadastro-plano')}>
              Cadastro de Beneficiário
            </SidebarListItem> */}
          </SidebarList>
          {/* <SidebarList title="Material de Apoio">
            <SidebarListItem>Tabela de Preços</SidebarListItem>
            <SidebarListItem>Ficha Astra</SidebarListItem>
            <SidebarListItem>Declaração de Saúde</SidebarListItem>
          </SidebarList> */}
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          <Main>
            <Flex>
              <FullPageFlexSection>
                <MessageCard
                  title="Obrigado"
                  noBorder
                  fullHeight={false}
                  style={{ maxWidth: '482px' }}
                  centerTitle
                >
                  <p>
                    Solicitamos ao beneficiário que preencha a declaração de
                    saúde. Aguarde e acompanhe o andamento desta proposta
                    através do menu status da proposta.
                  </p>
                </MessageCard>
              </FullPageFlexSection>
            </Flex>
          </Main>
        </div>
      </MainContent>
    </>
  );
};

export default CadastroFinalizado;
