import React from 'react';
import styled from 'styled-components';

const FormtTitle = ({ children, ...props }) => (
  <Title {...props}>{children}</Title>
);

const Title = styled.h3`
  border: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.blue};
  padding: 0px 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
`;

export default FormtTitle;
