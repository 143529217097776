import styled from 'styled-components';
import { Field } from 'formik';
import { DatePicker } from 'react-rainbow-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  border: 0;
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  border-radius: 30px;
  
  max-width:10px !important;
  img{
    margin: 10px;
    width: 16px;
    height: 16px;
  }
`;

export const Input = styled(Field)`
  width: 100%;
  border: 0;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.backgroundWhite};

  &, &::placeholder{
    font-family: 'SegoeUI';
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

`;

export const Plus = styled.button`
  border: 0;
  background-color: transparent;
  height: 100%;

  img{
    margin: 7px;
    width: 15px;
    height: 100%;
  }

`;

export const Minus = styled.button`
  border: 0;
  background-color: transparent;
  height: 100%;

  img{
    margin: 0px;
    width: 15px;
    height: 100%;
  }

`;

export const DateInputPicker = styled(DatePicker)`
  width: 100%;
  border: 0px !important;
  /* margin: 0px !important; */
  /* padding-left: 0px !important; */
  div{
    width: 100%;
  }
  *{
    border: 0px !important;
    /* margin: 0px !important; */
    padding-left: 0px !important;
  }
  input {
    padding: 0 20px !important;
    height: 50px;
    background-color: ${({ theme }) => theme.colors.backgroundWhite} !important;
    width: 100%;
    color: ${({ theme }) => theme.colors.blue};
  }
  input, input::placeholder{
    font-family: 'SegoeUI';
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

`;

export const Error = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding: 2px 0px 5px 20px;
  height: 16px;
  width: 100%;
  margin-bottom: 20px;
`;
