import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* top: 0;
  position: sticky;
  z-index: 999; */

  @media (max-width: ${({theme}) => theme.mobile}){
    display:none;
  }
`;

export const FirstContainer = styled.div`
  height: 69px;
  background-color: ${({theme}) => theme.colors.header.background};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &>div{
    display: initial;
    max-width: 1020px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div{
    display: flex;
  }

  p{
    font-size: 16px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.white};
  }
  p+p{
    margin-left: 20px;
  }
  span{
    color: ${({theme}) => theme.colors.green};
  }
`;
export const MainContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.colors.header.backgroundLight};

  &>div{
    height: 80px;
    background-color: ${({theme}) => theme.colors.header.backgroundLight};
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;

    img{
      max-height: 75%;
    }
    button{
      padding: 0 30px;
    }
  }
`;
export const MenuItem = styled.button`
  border: 0;
  background: transparent;
  color: ${({theme}) => theme.colors.blue};
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Exo';
`;
export const UserItem = styled.button`
  border: 0;
  padding: 0 10px !important;
  background: transparent;
  color: ${({theme}) => theme.colors.green};
  font-weight: bold;
  font-size: 16px;
  font-family: 'Exo';
`;

export const Icon = styled.img`
  height: 18px;
  padding: 0 26px;
  z-index: 999;
`;

export const Logo = styled.img`
  height: 38px;
  justify-self: center;
  display: ${({display}) => display ? 'initial' : 'none'};
`;
