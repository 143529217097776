import React, { useState, useContext, createContext, useCallback } from 'react';
import { formPost } from '../services/api';

const AppContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const userData = localStorage.getItem('@Addere:user');

    if (userData) {
      return JSON.parse(userData);
    }

    return null;
  });

  const signIn = useCallback(
    async ({ cpf, dataNascimento }) => {
      cpf = cpf.replace('.','').replace('/','').replace('-','');
      const response = await formPost('/AreaCorretor/Autenticar', {
        cpf,
        dataNascimento,
      });

      const userData = response.data;

      if (userData) {
        localStorage.setItem('@Addere:user', JSON.stringify(userData));
        setUser(userData);
      }
    },
    [setUser],
  );

  const signInCorretora = useCallback(
    async ({ cnpj, codigo }) => {
      cnpj = cnpj.replace('.','').replace('/','').replace('-','');
      const response = await formPost('/AreaCorretor/AutenticarCorretora', {
        cnpj,
        codigo,
      });

      const userData = response.data;

      if (userData) {
        localStorage.setItem('@Addere:user', JSON.stringify(userData));
        setUser(userData);
      }
    },
    [setUser],
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem('@Addere:user');
    setUser(null);
  }, [setUser]);

  return (
    <AppContext.Provider value={{ user, signIn, signInCorretora, signOut }}>
      {children}
    </AppContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
