import styled from 'styled-components';

export const Container = styled.header`
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 65px;
  transition: background-color 0.2s linear;
  background-color: ${({ theme, menu }) => !menu ?
    theme.colors.header.background :
    theme.colors.header.backgroundLight
  };
  z-index: 999;
  top: 0;
  position: sticky;

  @media (min-width: ${({ theme }) => theme.mobile}){
    display:none;
  }

  .usuario {
    margin-right: 10px;
    color: white;
    font-family: 'Exo';

    button {

      float:right;

      border: 0;
      background: transparent;
      color: ${({ theme }) => theme.colors.green};
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

export const Icon = styled.img`
  height: 18px;
  padding: 0 26px;
  z-index: 999;
`;

export const Logo = styled.img`
  height: 38px;
  justify-self: center;
  display: ${({ show }) => show ? 'initial' : 'none'};
`;
