import React from 'react';
import GlobalStyle from './styles';

const GlobalStyleProvider = () => {
  return (
    <GlobalStyle />
  );
}

export default GlobalStyleProvider;
