import React from 'react';
import { Body, Divider } from './styles';
import Container from '../../Containers/Container';

const MessageCard = ({ children, ...props }) => (
  <Container {...props}>
    <Divider />
    <Body>{children}</Body>
  </Container>
);

export default MessageCard;
