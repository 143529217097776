import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import HeaderDeclaracao from '../../components/Headers/HeaderDeclaracao';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import Container from '../../components/Containers/Container';
import RadioButton from '../../components/Inputs/RadioButton';
import Pagination from '../../components/Pagination';
import TextInput from '../../components/Inputs/TextInput';
import DateInput from '../../components/Inputs/DateInput';
import { formPost } from '../../services/api';
import Loading from '../../components/Tables/Loading';
import { parseDateString } from '../../utils/parser';

import {
  Main,
  Flex,
  Item,
  Beneficiarios,
  Esclarecimentos,
  Row,
  InputContainer,
  Textarea,
  ContainerStyled
} from './styles';

const calculateIMC = (height, weight) =>
  height ? `${(weight / (height / 100) ** 2).toFixed(2)} kg/m2` : 'IMC';

const obesityAlert = (height, weight) => {
  const imc = weight / (height / 100) ** 2;
  if (imc >= 40) {
    return 'Obesidade Grave - Obesidade Grau III';
  }
  if (imc >= 30) {
    return 'Obesidade - Obesidade Grau II';
  }
  if (imc >= 25) {
    return 'Sobrepeso - Obesidade Grau I';
  }
  return '';
};

const today = new Date();

const ComplementaresSchema = Yup.object().shape({
  respostas: Yup.array().of(
    Yup.object().shape({
      sim: Yup.boolean(),
      dataOcorrido: Yup.date()
          .when('sim', {
            is: true,
            then: Yup.date().typeError('Data inválida')
            .max(today, 'Data inválida')
            .transform(parseDateString)
            .required('Data do diagnóstico é obrigatória'),
          }),
      esclarecimento: Yup.string()
                        .when('sim', {
                          is: true,
                          then: Yup.string().min(10, 'Mínimo de 10 caracteres')
                                            .required('Campo obrigatório')
                        }),
    }),
  ),
  beneficiarios: Yup.array().of(
    Yup.object().shape({
      beneficiario: Yup.object().shape({
        peso: Yup.string().required('Campo obrigatório'),
        altura: Yup.string().required('Campo obrigatório'),
      }),
    }),
  ),
});

const Complementares = () => {
  const history = useHistory();
  const [radioValue, setRadio] = useState(false);
  const { state } = useLocation();
  const { beneficiarios, respostas } = state;
  const [perguntas, setPerguntas] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    formPost('/PortalCorretor/ListarDeclaracaoSaude', {
      paginacao: -1,
      pagina: 0,
      vendaID: state.vendaID,
    })
      .then((response) => {
        setPerguntas(response.data.dados.perguntas);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, [setPerguntas]);

  const respostasFiltradas = useMemo(() => {
    return respostas.filter(({ sim }) => sim);
  }, [respostas]);

  return (
    <>
      <div style={{ display: 'flex', backgroundColor: '#F9F9F9' }}>
        <Sidebar />
        <div style={{ width: '100%' }}>
          <HeaderDeclaracao />
          <Main>
            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Formik
                enableReinitialize
                validationSchema={ComplementaresSchema}
                initialValues={{
                  respostas: respostas.map(
                    (resposta) => ({
                      ...resposta,
                      dataOcorrido: undefined,
                      esclarecimento: '',
                    }),
                  ),
                  beneficiarios: beneficiarios.map(
                    ({ vendaBeneficiarioID }) => ({
                      vendaBeneficiarioID,
                      beneficiario: {
                        peso: '',
                        altura: '',
                      },
                    }),
                  ),
                }}
                onSubmit={(data) => {
                  history.push('/declaracao-resumo', {
                    ...state,
                    declaracaoSaude: data,
                  });
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    {respostasFiltradas.length > 0 && (
                      <Container title="Esclarecimentos complementares">
                        {loadingPage ? (
                          <Loading />
                        ) : (
                          <>
                            <p>
                              Você respondeu sim para os itens listados abaixo.
                              Agora, descreva e esclareça a doença existente
                              (data, tratamento, quadro atual e tudo mais que
                              julgar importante para avaliação de sua saúde).
                              Passe o mouse ou clique sob o item para relembrar
                              a questão.
                            </p>
                            <FieldArray name="respostas">
                              <>
                                {respostas.map((resposta, index) => {
                                  if (!resposta.sim) {
                                    return null;
                                  }
                                  const perguntaFiltrada = perguntas.filter(
                                    ({ operadoraDeclaracaoSaudeID }) =>
                                      operadoraDeclaracaoSaudeID ===
                                      resposta.operadoraDeclaracaoSaudeID,
                                  );
                                  const beneficiarioFiltrada = beneficiarios.filter(
                                    ({ vendaBeneficiarioID }) =>
                                      vendaBeneficiarioID ===
                                      resposta.vendaBeneficiarioID,
                                  );
                                  const {
                                    pergunta,
                                    ordem,
                                  } = perguntaFiltrada[0];
                                  const { nome } = beneficiarioFiltrada[0];

                                  return (
                                    <Flex
                                      key={
                                        resposta.operadoraDeclaracaoSaudeID +
                                        resposta.vendaBeneficiarioID
                                      }
                                    >
                                      <Item>
                                        <h3>Item</h3>

                                        <p>
                                          <span>{ordem}</span>
                                          {pergunta}
                                        </p>
                                      </Item>
                                      <Beneficiarios>
                                        <h3>Beneficiários</h3>
                                        <p>{nome}</p>
                                        <h3>Data do diagnóstico</h3>
                                        <DateInput
                                          name={`respostas.${index}.dataOcorrido`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `respostas.${index}.dataOcorrido`,
                                              e.target.value,
                                            );
                                          }}
                                          value={values.respostas[index].dataOcorrido}
                                        />
                                      </Beneficiarios>
                                      <Esclarecimentos>
                                        <h3>Esclarecimentos</h3>
                                        <TextInput
                                          as="textarea"
                                          name={`respostas.${index}.esclarecimento`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `respostas.${index}.esclarecimento`,
                                              e.target.value,
                                            );
                                          }}
                                          style={{ 'width':'100%', 'height':'100px' }}
                                          value={
                                            values.respostas[index]
                                              .esclarecimento !== null
                                              ? values.respostas[
                                                index
                                              ].esclarecimento.toString()
                                              : ''
                                          }
                                        />
                                      </Esclarecimentos>
                                    </Flex>
                                  );
                                })}
                              </>
                            </FieldArray>
                            <div>
                              <RadioButton
                                label="Confirmo ser verdade"
                                name="respostasRadio"
                                value={radioValue}
                                checked={radioValue}
                                onChange={() => {
                                  setRadio((s) => !s);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Container>
                    )}
                    <Container
                      title="Calcule o índice de Massa Corporal"
                      style={{ width: '100%' }}
                    >
                      {loadingPage ? (
                        <Loading />
                      ) : (
                        <>
                          <FieldArray name="beneficiarios">
                            <>
                              {beneficiarios.map((beneficiario, index) => (
                                <Row key={beneficiario.vendaBeneficiarioID}>
                                  <InputContainer flex={2}>
                                    <TextInput
                                      name="nome"
                                      placeholder={beneficiario.nome}
                                      disabled
                                    />
                                  </InputContainer>
                                  <InputContainer>
                                    <TextInput
                                      name={`beneficiarios[${index}].beneficiario.peso`}
                                      placeholder="100Kg"
                                      type="number"
                                    />
                                  </InputContainer>
                                  <InputContainer>
                                    <TextInput
                                      name={`beneficiarios[${index}].beneficiario.altura`}
                                      placeholder="100cm"
                                      type="number"
                                    />
                                  </InputContainer>
                                  <InputContainer>
                                    <TextInput
                                      name="imc"
                                      placeholder={calculateIMC(
                                        values.beneficiarios[index].beneficiario
                                          .altura,
                                        values.beneficiarios[index].beneficiario
                                          .peso,
                                      )}
                                      error={obesityAlert(
                                        values.beneficiarios[index].beneficiario
                                          .altura,
                                        values.beneficiarios[index].beneficiario
                                          .peso,
                                      )}
                                    />
                                  </InputContainer>
                                </Row>
                              ))}
                            </>
                          </FieldArray>
                          <ContainerStyled>
                            <Pagination
                              anterior
                              anteriorOnClick={() => history.go(-1)}
                              proximo
                              type="submit"
                            />
                          </ContainerStyled>
                        </>
                      )}
                    </Container>
                  </Form>
                )}
              </Formik>
            </FullPageFlexSection>
          </Main>
        </div>
      </div>
    </>
  );
};

export default Complementares;
