import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProductBanner from '../../components/Banners/ProductBanner';
import BannerFullPage from '../../components/Banners/BannerFullPage';
import Header from '../../components/Headers/Header';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Container from '../../components/Containers/Container';

import { formPost } from '../../services/api';

import saudeSim from '../../assets/images/banners/saudeSim.png';
import vitallis from '../../assets/images/banners/vitallis.png';

import BannerHome from '../../assets/images/bannerHome.png';

const HomePage = () => {
  const history = useHistory();
  const [campanhas, setCampanhas] = useState([]);
  const [comunicados, setComunicados] = useState([]);
  const [comunicadoSelecionado, setComunicadoSelecionado] = useState(0);

  useEffect(() => {
    formPost('/PortalCorretor/ListarCampanha', {
      paginacao: -1,
      pagina: 0,
    }).then(({ data }) => {
      setCampanhas(data.dados.lista);
    });
    formPost('/PortalCorretor/ListarComunicado', {
      paginacao: -1,
      pagina: 0,
    }).then(({ data }) => {
      setComunicados(data.dados.lista);
    });
  }, [setCampanhas, setComunicados]);

  return (
    <>
      <Header fullHeader />
      <main>
        <BannerFullPage
          src={BannerHome}
          text={
            <>
              Ficou fácil <span>gerenciar</span>
              <br /> sua vida na Addere
            </>
          }
          subtext="Portal de vendas"
          onClick={() => history.push('/portal')}
        />
        <FullPageFlexSection justifyContent="space-evenly">
          <ProductBanner
            text="Material de Apoio de Vendas"
            subtext="Informações atualizadas sobre o plano SaúdeSim"
            buttonText="Tabela de Preços"
            src={saudeSim}
          />
          <ProductBanner
            text="Material de Apoio de Vendas"
            subtext="Informações atualizadas sobre o plano SaúdeSim"
            buttonText="Tabela de Preços"
            src={vitallis}
          />
        </FullPageFlexSection>
        {comunicados?.[comunicadoSelecionado] && (
          <FullPageFlexSection
            title="Comunicados"
            darkBackground
            style={{ flexDirection: 'column' }}
          >
            <Container
              minHeight
              columns={2}
              style={{ width: 'calc(75% - 40px)' }}
            >
              <h2>{comunicados?.[comunicadoSelecionado].titulo}</h2>
              <h3>{comunicados?.[comunicadoSelecionado].subTitulo}</h3>
              <p> {comunicados?.[comunicadoSelecionado].comunicado}</p>
            </Container>
            <Container
              minHeight
              style={{ width: 'calc(25% - 40px)', padding: '70px 50px' }}
            >
              <h4>Últimos comunicados</h4>
              <ul>
                {comunicados.map(({ titulo, tipoObjetoID }, index) => (
                  <li>
                    <button
                      onClick={() => setComunicadoSelecionado(index)}
                      type="button"
                      key={tipoObjetoID}
                    >
                      {titulo}
                    </button>
                  </li>
                ))}
              </ul>
            </Container>
          </FullPageFlexSection>
        )}
        {campanhas && (
          <FullPageFlexSection
            title="Campanhas Ativas"
            justifyContent="space-evenly"
          >
            {campanhas.map(({ arquivo, regulamento }) => (
              <ProductBanner
                fullImage
                buttonText="Regulamento"
                src={arquivo.urlPreview}
                description={regulamento}
              />
            ))}
          </FullPageFlexSection>
        )}
      </main>
    </>
  );
};

export default HomePage;
