import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;

  p{
    font-family: 'SegoeUI';
    font-size: 16px;
    padding: 6px 20px;
  }

  h1{
    font-family: 'Exo';
    font-size: 16px;
    font-weight: bold;
  }

  h2{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.colors.green};
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
  }

  div{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: -10px;
    span{
      margin-top: 20px;
    }
  }
`;

export const Button = styled.button`
  border: 0;
  width: 193px;
  height: 174px;
  border-radius: 35px;
  padding: 0;
  margin: 10px;
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  img{
    width: 95px;
    object-fit: contain;
  }

`;

export const Divider = styled.hr`
  width: 100%;
  height: 3px;
  margin: 15px 0;
  border: 0;
  background: ${({ theme }) => theme.colors.green};
`;
