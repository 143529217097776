import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import HeaderDeclaracao from '../../components/Headers/HeaderDeclaracao';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import Container from '../../components/Containers/Container';
import Pagination from '../../components/Pagination';
import RadioButton from '../../components/Inputs/RadioButtonField';
import Loading from '../../components/Tables/Loading';
import { formPost } from '../../services/api';
import { Main, Flex, Row, Question, Column, Error, ContainerStyled } from './styles';

const DeclaracaoSchema = Yup.object().shape({
  resposta: Yup.array().of(
    Yup.object().shape({
      beneficiario: Yup.array().of(
        Yup.string().required('Campo é obrigatório'),
      ),
    }),
  ),
});

const DeclaracaoForm = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [perguntas, setPerguntas] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    formPost('/PortalCorretor/ListarDeclaracaoSaude', {
      paginacao: -1,
      pagina: 0,
      vendaID: state.vendaID,
    })
      .then((response) => {
        setPerguntas(response.data.dados.perguntas);
        setBeneficiarios(response.data.dados.beneficiarios);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, [setPerguntas, setBeneficiarios]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ width: '100%', backgroundColor: '#F9F9F9' }}>
          <HeaderDeclaracao />
          <Main>
            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Formik
                enableReinitialize
                initialValues={{
                  resposta: perguntas.map(({ operadoraDeclaracaoSaudeID }) => ({
                    beneficiario: beneficiarios.map(() => ''),
                    operadoraDeclaracaoSaudeID,
                  })),
                }}
                validationSchema={DeclaracaoSchema}
                onSubmit={(e) => {
                  history.push('/complementares', {
                    ...state,
                    respostas: e.resposta.reduce(
                      (acc, { beneficiario, operadoraDeclaracaoSaudeID }) => {
                        const buf = beneficiario.map((resposta, index) => ({
                          operadoraDeclaracaoSaudeID,
                          sim: resposta === 'S',
                          dataOcorrido: null,
                          esclarecimento: null,
                          vendaBeneficiarioID:
                            beneficiarios[index].vendaBeneficiarioID,
                        }));
                        return [...acc, ...buf];
                      },
                      [],
                    ),
                    beneficiarios,
                  });
                }}
              >
                {({ values }) => (
                  <>
                    {loadingPage ? (
                      <Loading />
                    ) : (
                      <Form style={{ width: '100%' }}>
                        <Container title="Preencha por si e pelos Dependentes">
                          <Flex>
                            <Row style={{ height: 'initial' }}>
                              <Question />
                              {beneficiarios.map(({ nome }) => (
                                <Column key={nome}>
                                  <p>{nome}</p>
                                </Column>
                              ))}
                            </Row>
                            <FieldArray name="resposta">
                              <>
                                {perguntas.map(
                                  ({ pergunta, codigo }, index) => (
                                    <Row key={codigo}>
                                      <Question>
                                        <span>{index + 1}</span>
                                        {pergunta}
                                      </Question>
                                      <FieldArray
                                        name={`resposta.${index}.beneficiario`}
                                      >
                                        <>
                                          {beneficiarios.map(
                                            (b, beneficiario) => (
                                              <Column
                                                key={b.vendaBeneficiarioID}
                                              >
                                                <div>
                                                  <RadioButton
                                                    label="sim"
                                                    name={`resposta.${index}.beneficiario.${beneficiario}`}
                                                    value="S"
                                                    checked={
                                                      values.resposta[index]
                                                        .beneficiario[
                                                      beneficiario
                                                      ] === 'S'
                                                    }
                                                  />
                                                  <RadioButton
                                                    label="não"
                                                    name={`resposta.${index}.beneficiario.${beneficiario}`}
                                                    value="N"
                                                    checked={
                                                      values.resposta[index]
                                                        .beneficiario[
                                                      beneficiario
                                                      ] === 'N'
                                                    }
                                                  />
                                                </div>
                                                <Error>
                                                  <ErrorMessage
                                                    name={`resposta.${index}.beneficiario.${beneficiario}`}
                                                  />
                                                </Error>
                                              </Column>
                                            ),
                                          )}
                                        </>
                                      </FieldArray>
                                    </Row>
                                  ),
                                )}
                              </>
                            </FieldArray>
                            <ContainerStyled>
                              <div></div>
                              <Pagination proximo type="submit" />
                            </ContainerStyled>
                          </Flex>
                          {/* <Pagination proximo type="submit" /> */}
                        </Container>
                      </Form>
                    )}
                  </>
                )}
              </Formik>
            </FullPageFlexSection>
          </Main>
        </div>
      </div>
    </>
  );
};

export default DeclaracaoForm;
