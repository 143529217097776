import styled from 'styled-components';


export const Divider = styled.hr`
  width: 100%;
  height: 3px;
  margin: 0px 0 10px;
  border: 0;
  background: ${({ theme }) => theme.colors.green};
`;

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 50px;
  @media (max-width: ${({ theme }) => theme.mobile}){
    display:none;
  }
`;

export const Main = styled.main`
  padding: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}){
    padding: 10px;
  }
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &>div{
    padding: 0 10px;
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  p{
    margin-bottom: 10px;
    line-height: 1.75;
  }

  span{
    font-weight: bold;
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const MessageContainer = styled.p`
  padding: 35px;
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  border-radius: 30px;
`;
