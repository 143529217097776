import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../Buttons/Button';

const ProductBanner = ({
  text,
  subtext,
  fullImage,
  buttonText,
  description,
  ...props
}) => {
  const [flip, setFlip] = useState(false);

  return (
    <Container>
      {fullImage ? (
        <Flip flip={flip}>
          <FullImage {...props} />
          <FullDescription>
            <p>{description}</p>
          </FullDescription>
        </Flip>
      ) : (
        <BannerImage {...props} />
      )}
      {!fullImage && (
        <div>
          <h1>{text}</h1>
          <h2>{subtext}</h2>
        </div>
      )}
      <Button
        type="button"
        alternativeButton={flip}
        onClick={() => setFlip((t) => !t)}
      >
        {buttonText}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  max-width: 250px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  h1 {
    font-size: 24px;
    font-family: 'Exo';
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.darkBlue};
    font-weight: bold;
    text-align: center;
  }
  h2 {
    color: ${({ theme }) => theme.colors.black};
    font-size: 20px;
    font-family: 'Exo';
    text-align: center;
  }
`;

const BannerImage = styled.img`
  width: 220px;
  height: 74px;
  object-fit: contain;
  margin-bottom: 10px;
`;

const FullImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const FullImage = ({ ...props }) => (
  <FullImageContainer>
    <img {...props} alt="alt" />
  </FullImageContainer>
);

const FullDescriptionContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: ${({ theme }) => theme.colors.darkGreen};
  * {
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-family: 'Exo';
  }
  padding: 20px;
`;

const FullDescription = ({ children }) => (
  <FullDescriptionContainer>{children}</FullDescriptionContainer>
);

const FlipContainer = styled.div`
  background-color: transparent;
  width: 310px;
  height: 600px;
  perspective: 1500px;

  & > div {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .inner {
    ${({ flip }) => flip && 'transform: rotateY(180deg);'}
  }
`;

const Flip = ({ flip, children }) => (
  <FlipContainer flip={flip}>
    <div className="inner">{children}</div>
  </FlipContainer>
);

export default ProductBanner;
