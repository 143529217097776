import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  p{
    text-align: center;
  }
`;

export const Button = styled.button`
  border: 0;
  width: 193px;
  height: 174px;
  border-radius: 35px;
  padding: 0;
  margin: 10px;
  background-color: ${({theme}) => theme.colors.backgroundWhite};
  img{
    width: 95px;
    object-fit: contain;
  }

`;
