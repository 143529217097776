import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  height: calc(100vh - 65px);

  @media (min-width: ${({theme}) =>theme.mobile}){
    min-height: 100vh;
  }
`;
