import React from 'react';
import FocusLock from 'react-focus-lock';
import { Container, Icon, Logo } from './styles';
import logo from '../../../assets/images/logo.png';
import user from '../../../assets/images/user.png';
import userDark from '../../../assets/images/userDark.png';
import { useMenu } from '../../../hooks/menu';
import Burger from '../../Burger';
import Menu from '../../Menu';


const HeaderMobile = ({ fullHeader = false, usuario, logout }) => {
  const { open, node, menuId, setOpen } = useMenu();
  return (
    <Container menu={open}>
      <div ref={node}>
        <FocusLock disabled={!open}>
          <Burger
            open={open}
            setOpen={setOpen}
            aria-controls={menuId}
            darkBackground={fullHeader}
          />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </div>
      <Logo src={logo} show={!open} />
      {usuario && (
        <div className="usuario">
          <p>{usuario?.nome.split(' ')[0]}</p>
          {/* <p>AQUII</p> */}
          <button type="button" onClick={logout}>
            Sair
          </button>
        </div>
      )}
      {!usuario && <Icon src={open ? userDark : user} dark={!open} />}
    </Container>
  );
};

export default HeaderMobile;
