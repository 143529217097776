import styled from 'styled-components';

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 50px;
  @media (max-width: ${({theme}) =>theme.mobile}){
    display:none;
  }
`;

export const Main = styled.main`
  padding: 40px;
  @media (max-width: ${({theme}) =>theme.mobile}){
    padding: 10px;
  }
  background-color: ${({theme}) =>theme.colors.backgroundWhite};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  /* align-items: center;
  flex-wrap: wrap; */
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
`;

export const Divider = styled.hr`
  width: 100%;
  height: 3px;
  margin: 15px 0;
  border: 0;
  background: ${({theme}) =>theme.colors.green};
`;

export const AddButton = styled.span`
  font-size: 25px;
  font-family: 'Exo';
  font-weight: normal;
  color: ${({ theme }) => theme.colors.blue};
`;

export const AddButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 25px;

  img{
    width: 20px;
    margin-left: 20px;
  }
`;

export const Tag = styled.span`
  font-size: 20px;
  font-family: 'Exo';
  font-weight: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blue};
`;
