import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { Input, InputContainer, Container, Error } from './styles';
import { getMaskByType } from '../masks';

const LoginInput = ({
  hasIcon,
  icon,
  alt,
  name,
  type,
  placeholder,
  ...props
}) => {
  const mask = getMaskByType(type);

  return (
    <Container>
      <InputContainer>
        {hasIcon && <img src={icon} alt={alt} />}
        {type === 'date' ? (
          <Field name={name} type="text" {...props}>
            {({ field }) => (
              <Input
                {...field}
                placeholder={placeholder}
                mask={mask}
                type="text"
              />
            )}
          </Field>
        ) : (
          <Field name={name} type="text" {...props}>
            {({ field }) => (
              <Input
                {...field}
                placeholder={placeholder}
                mask={mask}
                type="text"
              />
            )}
          </Field>
        )}
      </InputContainer>
      <Error>
        <ErrorMessage name={name} />
      </Error>
    </Container>
  );
};

export default LoginInput;
