import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import HeaderPortal from '../../components/Headers/HeaderPortal';
import TablePending from '../../components/Tables/TablePending';
import TableSend from '../../components/Tables/TableSend';
import TableApprove from '../../components/Tables/TableApprove';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import Container from '../../components/Containers/Container';
import { Main } from '../CadastroEnvio/styles';
import SelectSidebar from '../../components/Inputs/Select/SelectSidebar';

const SalesPortal = () => {
    const { user } = useAuth();
    const history = useHistory();
    const [dadoEquipe, setDadoEquipe] = useState(sessionStorage.getItem('dadoEquipe'));

    useEffect(() => {
    }, [dadoEquipe, setDadoEquipe]);

    return (
        <>
            <div style={{ display: 'flex' }}>
                <Sidebar>
                    {!user.corretora &&
                        <SidebarList title="Corretoras">
                            <SelectSidebar
                                placeholderDefault
                                onChange={(e) => {
                                    sessionStorage.setItem('dadoEquipe', e.target.value);
                                    setDadoEquipe(e.target.value);
                                }}
                                value={sessionStorage.getItem('dadoEquipe')}
                            >
                                {user.equipes.map(equipes => (
                                    <option
                                        value={equipes.equipeVendasID}
                                    >
                                        {equipes.corretora}
                                    </option>
                                ),
                                )}
                            </SelectSidebar>
                        </SidebarList>}
                    <SidebarList title="Movimentação">
                        <SidebarListItem onClick={() => history.push('/portal')}>
                            Home
                        </SidebarListItem>
                        {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
                            Corretores
                        </SidebarListItem>}
                        {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
                            Cotação
                        </SidebarListItem>}
                    </SidebarList>
                </Sidebar>
                <div style={{ width: '100%' }}>
                    <HeaderPortal />
                    <Main>
                        <ul className="breadcrumb">
                            <li><a href="/portal"><span>Home</span></a></li>
                            <li><span>Vendas Confirmadas</span></li>
                        </ul>
                        <FullPageFlexSection style={{ flexDirection: 'column' }}>
                            <Container title="Vendas Confirmadas">
                                <p>
                                    As proposta com declarações respondidas e aceitas pelo beneficiário aparecem listada abaixo.
                                </p>
                                <TableApprove />
                            </Container>
                        </FullPageFlexSection>
                    </Main>
                </div>
            </div>
        </>
    );
};

export default SalesPortal;
