import React from 'react';
import { AuthProvider } from './auth';
import { MenuProvider } from './menu';
import { ToastProvider } from './toast';

const AppProvider = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <MenuProvider>{children}</MenuProvider>
    </AuthProvider>
  </ToastProvider>
);

export default AppProvider;
