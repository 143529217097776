import styled from 'styled-components';
import MaskedInput from 'react-text-mask';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 20px;
  box-shadow: 2px 2px #bab8b8;
  border: 1px solid #bab8b8;
  width: 100%;
  border: 0;
  // border: ${({ error, theme }) => error ? `1px solid ${theme.colors.red}` : `1px solid #bab8b8`};
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  border-radius: 20px;
  img{
    margin: 10px;
    width: 16px;
    height: 16px;
  }
`;

export const Input = styled(MaskedInput)`
  width: 100%;
  border: 0;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.backgroundWhite};

  &, &::placeholder{
    font-family: 'SegoeUI';
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  &:disabled::placeholder{
    opacity: 0.35;
    font-family: 'SegoeUI';
    font-size: 16px;
    color: ${({ theme }) => theme.colors.darkGreen};
  }
`;

export const Plus = styled.button`
  border: 0;
  background-color: transparent;
  height: 100%;
  img{
    margin: 10px;
    width: 20px;
    height: 100%;
  }

`;

export const Error = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding: 2px 0px 6px 20px;
  min-height: 16px;
  width: 100%;
  margin-bottom: 20px;
`;
