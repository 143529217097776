import React from 'react';
import styled from 'styled-components';

const SidebarListItem = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>

);

const Container = styled.li`
  width: 100%;
  font-family: 'Exo';
  font-size: 15px;
  font-weight: 600;
  padding: 8px 30px;
  cursor: pointer;
  transition: 0.5s;
  &:hover { 
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.gray}; ;
  }
`;

// &:selected { 
//   color: ${({ theme }) => theme.colors.white};
//   background-color: ${({ theme }) => theme.colors.gray}; ; 
// }
// ${portal === 'portal' ? 'color: white; background: red;' : ''}

export default SidebarListItem;
