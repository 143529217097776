import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Logo } from './styles';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import HeaderMobile from '../../components/Headers/HeaderMobile';
import LoginSection from '../../components/Sections/LoginSection';
import LoginInput from '../../components/Inputs/LoginInput';
import Button from '../../components/Buttons/Button';
import Select from '../../components/Inputs/Select';
// import LinkButton from '../../components/Buttons/LinkButton';
import user from '../../assets/images/userGreen.png';
import lock from '../../assets/images/lock.png';
import logo from '../../assets/images/logoLogin.png';
import { parseDateString } from '../../utils/parser';

const today = new Date();

const SignupSchema = Yup.object().shape({
  cpf: Yup.string().required('CPF é necessário'),
  dataNascimento: Yup.date()
    .typeError('Data inválida')
    .max(today, 'Data inválida')
    .transform(parseDateString)
    .required('Data de nascimento é obrigatória'),
});

const SignupCorretoraSchema = Yup.object().shape({
  cnpj: Yup.string().required('CNPJ é necessário'),
  codigo: Yup.string().required('Código é necessário'),
});

const StartPage = () => {
  const { signIn, signInCorretora } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [autenticarCorretora, setAutenticarCorretora] = useState('false');

  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    let i;

    for (i = 0; i < inputs.length; i+=1) {
      inputs[i].value = "";
    }
  }, [autenticarCorretora]);

  return (
    <>
      <HeaderMobile fullHeader />
      {autenticarCorretora === 'true' ? <LoginSection>
        <Logo src={logo} alt="Logo da Addere" />
        <Formik
          initialValues={{
            cnpj: '',
            codigo: '',
          }}
          validationSchema={SignupCorretoraSchema}
          onSubmit={async ({ cnpj, codigo }) => {
            setLoading(true);

            try {
              await signInCorretora({
                cnpj,
                codigo,
              });
            } catch (err) {
              addToast({
                type: 'error',
                title: 'CNPJ ou código inválidos, tente novamente',
              });
              setLoading(false);
            }
          }}
        >
          {() => (
            <Form>
              <Select
                placeholder=""
                name='autenticarCorretora'
                onChange={(e) => {
                  setAutenticarCorretora(e.target.value);
                }}
                value={autenticarCorretora}
              >
                <option value="false">Corretor</option>
                <option value="true">Corretora</option>
              </Select>
              <LoginInput
                id="cnpj"
                name="cnpj"
                placeholder="CNPJ"
                type="cnpj"
                hasIcon
                icon={user}
              />
              <LoginInput
                id="codigo"
                name="codigo"
                placeholder="Código"
                type="text"
                hasIcon
                icon={lock}
              />
              {/* <LinkButton>Esqueci minha senha</LinkButton>
            <LinkButton>Alterar minha senha</LinkButton> */}
              <Button loading={loading} type="submit">
                Entrar
              </Button>
            </Form>
          )}
        </Formik>
      </LoginSection>
      : 
      <LoginSection>
        <Logo src={logo} alt="Logo da Addere" />
        <Formik
          initialValues={{
            cpf: '',
            dataNascimento: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={async ({ cpf, dataNascimento }) => {
            setLoading(true);

            try {
              await signIn({
                cpf,
                dataNascimento,
              });
            } catch (err) {
              addToast({
                type: 'error',
                title: 'CPF ou data de nascimento incorretos, tente novamente',
              });
              setLoading(false);
            }
          }}
        >
          {() => (
            <Form>
            <Select
              placeholder=""
              name='autenticarCorretora'
              onChange={(e) => {
                setAutenticarCorretora(e.target.value);
              }}
              value={autenticarCorretora}
            >
              <option value="false">Corretor</option>
              <option value="true">Corretora</option>
            </Select>
              <LoginInput
                id="cpf"
                name="cpf"
                placeholder="CPF"
                type="cpf"
                hasIcon
                icon={user}
              />
              <LoginInput
                id="dataNascimento"
                name="dataNascimento"
                placeholder="Data de nascimento"
                type="date"
                hasIcon
                icon={lock}
              />
              {/* <LinkButton>Esqueci minha senha</LinkButton>
            <LinkButton>Alterar minha senha</LinkButton> */}
              <Button loading={loading} type="submit">
                Entrar
              </Button>
            </Form>
          )}
        </Formik>
      </LoginSection>
      }
    </>
  );
};

export default StartPage;
