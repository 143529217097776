import styled from 'styled-components';
import { Field } from "formik";


export const Logo = styled.img`
  width: 250px;
  margin-bottom: 50px;
  @media (max-width: ${({ theme }) => theme.mobile}){
    display:none;
  }
`;

export const ContainerStyled = styled.div`
  padding: 17px 17px 30px !important;
`;

export const Main = styled.main`
  padding: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}){
    padding: 10px;
  }
  background-color: ${({ theme }) => theme.colors.backgroundWhite};  
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &>div{
    padding: 0 10px;
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  p{
    margin-bottom: 10px;
    line-height: 1.75;
  }

  span{
    /* font-weight: bold; */
    /* color: ${({ theme }) => theme.colors.green}; */
  }
`;

export const MessageContainer = styled.p`
  padding: 35px;
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  border-radius: 30px;
`;

export const Item = styled.div`
  height: 100%;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start !important;

  span{
    margin-right: 10px;
  }
  h3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  p{
    font-size: 14px;
  }
`;
export const Beneficiarios = styled.div`
  align-items: flex-start !important;

  height: 100%;
  align-self: flex-start;
  flex-direction: column;
  h3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.green};
  }
`;
export const Esclarecimentos = styled.div`
  align-items: flex-start !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-self: flex-start;
  h3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }

  p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    font-family: 'Exo';

  }
`;

export const Textarea = styled(Field)`
  height: 150px;
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  width: 100%;
  border: 0;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  border-radius: 30px;

  &::placeholder{
    font-family: 'SegoeUI';
    font-size: 20px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;


export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex:1;
  width: 100%;
  padding-top: 30px !important;
`;

export const InputContainer = styled.div`
  flex: ${({ flex }) => flex ? flex : 1};
  & + &{
    margin-left: 20px;
  }

  input::placeholder{
    opacity: 0.35;
    font-family: 'SegoeUI';
    font-size: 20px;
    color: ${({ theme }) => theme.colors.darkGreen};
  }
`;
