import api,{formPost} from '../../services/api';

export const SalvarProdutor = async (data) => {
  const response = await api.post('/PortalCorretor/CadastrarProdutor', data)

  if(response.data.sucesso){
    return response.data.sucesso;
  }else{
    throw new Error(response.data.mensagem)
  }
}

export const EditarProdutor = async (data) => {
  const response = await api.post('/PortalCorretor/CadastrarProdutor', data)

  if(response.data.sucesso){
    return response.data.sucesso;
  }else{
    throw new Error(response.data.mensagem)
  }
}

export const ExcluirProdutor = async (data) => {
  const response = await api.post('/PortalCorretor/ExcluirProdutor', data)

  if(response.data.sucesso){
    return response.data.sucesso;
  }else{
    throw new Error(response.data.mensagem)
  }
}

export const requestForm = async (contratoProdutoID) => {
  const {
    formasCobranca,
    diasVencimento,
    datasVigencia
  } = await ListarInformacoesContrato(contratoProdutoID);
  return {
    tipoDependencia: await ListarTipoDependencia(),
    tipoEstadoCivil: await ListarTipoEstadoCivil(),
    estados: await ListarEstados(),
    formasCobranca,
    diasVencimento,
    datasVigencia
  }
}
