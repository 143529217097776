import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  button{
    font-size: 18px;
    font-family: 'SegoeUI';
    padding: 10px 0px;
    list-style-type: none;
    transition: color 300ms;
    color:  ${({ theme }) => theme.colors.blue};
    cursor: pointer;
    border: 0;
    background-color: transparent;
    text-align: left;
  }
  .selected{
    color:  ${({ theme }) => theme.colors.green};
    font-weight: bold;
  }
  button:hover{
    color:  ${({ theme }) => theme.colors.green};
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 3px;
  margin: 15px 0;
  border: 0;
  background: ${({ theme }) => theme.colors.green};
`;
