import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import HeaderPortal from '../../components/Headers/HeaderPortal';
import FormTitle from '../../components/Form/FormTitle';
import TextInput from '../../components/Inputs/TextInput';
import DateInput from '../../components/Inputs/DateInput';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import Container from '../../components/Containers/Container';
import Button from '../../components/Buttons/Button';
import {
  SalvarProdutor,
  EditarProdutor,
  ExcluirProdutor
} from './requests';
import { useToast } from '../../hooks/toast';
import {
  Main,
  Flex,
  Divider,
} from './styles';
import { parseDateString } from '../../utils/parser';
import { useAuth } from '../../hooks/auth';

const today = new Date();

const CadastroProdutorSchema = Yup.object().shape({
  // corretoraID: Yup.string().required('Campo obrigatório'),
  // produtorID: Yup.string().required('Campo obrigatório'),
  dataNascimento: Yup.date()
    .typeError('Data inválida')
    .max(today, 'Data inválida')
    .transform(parseDateString)
    .required('Data de nascimento é obrigatória'),
  nome: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string().required('CPF é obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .required('Email é obrigatório'),
  telefone: Yup.string().required('Telefone é obrigatório')
});

const CadastroCorretor = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { state, search } = useLocation();
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(search);
  const produtorID = query.get('produtorID');
  const { addToast } = useToast();
  const [produtor, setProdutor] = useState();


  useEffect(() => {
    setProdutor(state ? state.produtor : null);
  }, [setProdutor]);

  const initialValuesProp = useMemo(() => {
    const initial = {
      corretoraID: user.corretoraID,
      produtorID: '',
      produtor: '',
      cpfCnpj: '',
      dataNascimento: '',
      email: '',
      telefone: '',
      telefoneAlternativo: ''
    };

    if (produtor) {
      console.log('aq', produtor)
      produtor.nome = produtor.produtor;
      produtor.cpf = produtor.cpfCnpj;
      if (produtor.contato) {
        produtor.email = produtor.contato.email;
        produtor.telefone = produtor.contato.telefone;
        produtor.telefoneAlternativo = produtor.contato.telefoneAlternativo;
      }
      return produtor;
    }
    return initial;
  }, [produtor, setProdutor]);

  function excluirProdutor() {
    const data = {
      corretoraID: produtor.corretoraID,
      produtorID: produtor.produtorID,
    };
    try {
      ExcluirProdutor(data).then(() => {
        addToast({
          title: 'Corretor excluído com sucesso.',
          type: 'success',
        });
        history.push('/corretores');
      });
    } catch {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro ao excluir corretor',
      });
    }
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar>
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
              Cotação
            </SidebarListItem>}
          </SidebarList>
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          <Main>
            <ul className="breadcrumb">
              <li><a href="/portal"><span>Home</span></a></li>
              <li><a href="/corretores"><span>Corretores</span></a></li>
              <li><span>Cadastro</span></li>
            </ul>
            <Formik
              enableReinitialize
              validationSchema={CadastroProdutorSchema}
              initialValues={initialValuesProp}
              onSubmit={async (values) => {
                setLoading(true);
                const data = values;
                try {
                  let response;
                  if (produtorID) {
                    response = await EditarProdutor(data);
                  } else {
                    response = await SalvarProdutor(data);
                  }
                  addToast({
                    title: 'Corretor salvo com sucesso.',
                    type: 'success',
                  });
                  history.push('/corretores', {
                    produtorID: response,
                  });
                } catch {
                  setLoading(false);
                  addToast({
                    type: 'error',
                    title: 'Erro ao enviar formulário',
                  });
                }
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Flex>
                    <FullPageFlexSection>
                      <Container
                        title="Informação do Corretor"
                        noBorder
                        fullHeight={false}
                      >
                        <Divider />
                        <FormTitle>
                          Nome completo e sem abreviações
                        </FormTitle>
                        <TextInput
                          placeholder=""
                          name='nome'
                          onChange={(e) => {
                            setFieldValue(
                              `nome`,
                              e.target.value,
                            );
                          }}
                        />

                        <FormTitle>
                          CPF
                        </FormTitle>
                        <TextInput
                          placeholder=""
                          name='cpf'
                          type="cpf"
                          onChange={(e) => {
                            setFieldValue(
                              `cpf`,
                              e.target.value,
                            );
                          }}
                        />

                        <FormTitle>Data de nascimento</FormTitle>
                        <DateInput
                          placeholder=""
                          name='dataNascimento'
                          onChange={(e) => {
                            setFieldValue(
                              `dataNascimento`,
                              e,
                            );
                          }}
                          maxDate={new Date(Date.now())}
                        />
                      </Container>
                    </FullPageFlexSection>
                    <FullPageFlexSection>
                      <Container
                        title="Contatos"
                        noBorder
                        fullHeight={false}
                      >
                        <Divider />
                        <FormTitle>
                          E-mail
                        </FormTitle>
                        <TextInput
                          placeholder=""
                          name='email'
                          onChange={(e) => {
                            setFieldValue(
                              `email`,
                              e.target.value,
                            );
                          }}
                        />

                        <FormTitle>
                          Telefone
                        </FormTitle>
                        <TextInput
                          placeholder=""
                          name='telefone'
                          type="phone"
                          onChange={(e) => {
                            setFieldValue(
                              `telefone`,
                              e.target.value,
                            );
                          }}
                        />

                        <FormTitle>
                          Telefone alternativo
                        </FormTitle>
                        <TextInput
                          placeholder=""
                          name='telefoneAlternativo'
                          type="phone"
                          onChange={(e) => {
                            setFieldValue(
                              `telefoneAlternativo`,
                              e.target.value,
                            );
                          }}
                        />

                        {produtor && <Button
                          type="button"
                          onClick={() => excluirProdutor()}
                          style={{ "float": "left", "marginTop": "-10px", "marginBottom": "15px" }}
                          className="btnExcluir"
                          loading={loading}
                          deleteButton
                        >
                          Excluir Corretor
                        </Button>}
                        <Button
                          type="submit"
                          style={{ "float": "right", "marginTop": "-10px", "marginBottom": "15px" }}
                          loading={loading}
                        >
                          Salvar Corretor
                        </Button>
                      </Container>
                    </FullPageFlexSection>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Main>
        </div>
      </div>
    </>
  );
};

export default CadastroCorretor;
