import React from 'react';
import styled from 'styled-components';

const SidebarList = ({ title, children }) => (
  <>
    <Title>{title}</Title>
    <List>{children}</List>
  </>
);

const List = styled.ul`
  list-style: none;
  margin-bottom: 60px;
  width: 100%;
`;

const Title = styled.p`
  width: 100%;
  font-family: 'Exo';
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 0 20px;
  color: ${({ theme }) => theme.colors.blue};
  text-transform: uppercase;
`;

export default SidebarList;
