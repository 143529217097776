import React from 'react';
import styled from 'styled-components';

const EmptyTable = () => {
  return (
    <Container>
      <h3>Nenhum dado encontrado</h3>
    </Container>
  );
};

export default EmptyTable;

const Container = styled.div`
  height: 200px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
