import React from 'react';
import { Container, Button } from './styles';

const OptionCard = ({ children, title, alt, ...props }) => (
  <Container>
    <Button>
      <img {...props} alt={alt} />
      {children}
    </Button>
    <p>{title}</p>
  </Container>
);

export default OptionCard;
