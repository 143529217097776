import styled from 'styled-components';

export const Container = styled.header`
  z-index: 999;
  top: 0;
  position: sticky;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({theme}) => theme.mobile}){
    display:none;
  }
`;

export const FirstContainer = styled.div`
  min-height: 100px;
  background-color: ${({theme}) => theme.colors.header.background};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10px;
  width: 100%;
  &>div{
    display: initial;
    max-width: 1020px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div{
    display: flex;
  }

  p{
    font-size: 16px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.white};
  }
  p+p{
    margin-left: 20px;
  }
  span{
    color: ${({theme}) => theme.colors.green};
  }
`;
export const MainContainer = styled.div`
  height: 80px;
  background-color: ${({theme}) => theme.colors.header.backgroundLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1020px;
  width: 100%;

  img{
    max-height: 75%;
  }
  button{
    padding: 0 30px;
  }
`;
export const MenuItem = styled.button`
  border: 0;
  background: transparent;
  color: ${({theme}) => theme.colors.blue};
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Exo';
`;
export const UserItem = styled.button`
  border: 0;
  background: transparent;
  color: ${({theme}) => theme.colors.green};
  font-weight: bold;
  font-size: 18px;
  font-family: 'Exo';
`;

export const Icon = styled.img`
  height: 18px;
  padding: 0 26px;
  z-index: 999;
`;

export const Logo = styled.img`
  height: 38px;
  justify-self: center;
  display: ${({display}) => display ? 'initial' : 'none'};
`;

export const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 36px 0px 21px 0px;
  padding: 10px 35px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.colors.white};

  span{
    color: ${({theme}) => theme.colors.blue};
    font-family: 'Exo';
    font-weight: 500;
    font-size: 14px;
  }
`;
export const Search = styled.input`
  width: 100%;
  max-width: 250px;
  padding: 5px;
  border: 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.blue};
`;
export const UserArea = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  div{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  p{
    color: ${({theme}) => theme.colors.blue};
    font-weight: bold;
    font-family: 'Exo';
    font-size: 16px;
  }
  span, button{
    color: ${({theme}) => theme.colors.green};
    border: 0;
    background: transparent;
    text-align: left;
    font-family: 'Exo';
    font-weight: 500;
    font-size: 14px;
  }

`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 40px;
  img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;


export const Avatar = styled.div`
  width: 40px !important;
  height: 40px !important;
  background-color: ${({theme}) => theme.colors.green};
  margin-left: 10px;
  border-radius: 50%;

  img{
    padding: 10px;
    object-fit: contain;
  }
`;

export const TabArea = styled.div`
  width: 100%;
  padding: 15px 0;
`;
export const Tab = styled.button`
    color: ${({theme, selected}) => selected ?theme.colors.white :  theme.colors.alternativeGreen};

    border: 0;
    background-color: transparent;
    text-align: center;
    font-family: 'SegoeUI';
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
`;
