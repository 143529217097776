import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import HeaderPortal from '../../components/Headers/HeaderPortal';
import Container from '../../components/Containers/Container';
import PlanCard from '../../components/Cards/PlanCard';
import PlanCardSelect from '../../components/Cards/PlanCard/PlanCardSelect';
import ContainerTitle from '../../components/Containers/ContainerTitle';
import ContainerSubtitle from '../../components/Containers/ContainerSubtitle';

import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import { Main, Flex } from './styles';
import { useAuth } from '../../hooks/auth';

const Planos = () => {
  const { user } = useAuth();
  const { state } = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState(
    state.plans[0] ? state.plans[0] : null,
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar>
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
              Cotação
            </SidebarListItem>}
            {/* <SidebarListItem onClick={() => history.push('/cadastro-plano')}>
              Cadastro de Beneficiário
            </SidebarListItem> */}
          </SidebarList>
          {/* <SidebarList title="Material de Apoio">
            <SidebarListItem>Tabela de Preços</SidebarListItem>
            <SidebarListItem>Ficha Astra</SidebarListItem>
            <SidebarListItem>Declaração de Saúde</SidebarListItem>
          </SidebarList> */}
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          {/* <Header fullHeader /> */}
          <Main>
            <ul className="breadcrumb">
              <li><a href="/portal"><span>Home</span></a></li>
              <li><a href="/cadastro"><span>Cotação</span></a></li>
              <li><span>Cálculo Cotação</span></li>
            </ul>
            <Flex style={{ alignItems: 'flex-start' }}>
              <div
                style={{
                  width: '100%',
                  flex: 1,
                  justifyContent: 'flex-start',
                  padding: '35px',
                }}
              >
                <ContainerTitle
                  noBorder
                  style={{
                    marginBottom: '50px',
                  }}
                >
                  Cotação
                  <ContainerSubtitle>Opções disponíveis</ContainerSubtitle>
                </ContainerTitle>
                <PlanCardSelect>
                  {state &&
                    state.plans &&
                    state.plans.map((plano) => (
                      <button
                        type="button"
                        className={
                          plano.contratoProdutoID === selected.contratoProdutoID
                            ? 'selected'
                            : 'opcao'
                        }
                        onClick={() => {
                          setSelected(plano);
                        }}
                        key={plano.contratoProdutoID}
                      >
                        {plano.nome}
                      </button>
                    ))}
                </PlanCardSelect>
              </div>
              <FullPageFlexSection style={{ flex: 1 }}>
                <Container>
                  {selected ? (
                    <PlanCard
                      name={`Plano [ ${selected.nome} | ${selected.tipo} )`}
                      price={`R$ ${selected.valorTotal.toFixed(2)} / mês`}
                      plan={selected}
                      empty={state.plans.length === 0}
                      submit={() =>
                        history.push('cadastro-plano', {
                          plan: selected,
                          ...state,
                        })
                      }
                    />
                  ) : (
                    <PlanCard empty />
                  )}
                </Container>
              </FullPageFlexSection>
            </Flex>
          </Main>
        </div>
      </div>
    </>
  );
};

export default Planos;
