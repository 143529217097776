import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { formPost } from '../../services/api';
import HeaderPortal from '../../components/Headers/HeaderPortal';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import Button from '../../components/Buttons/Button';
import MainContent from '../../components/Sections/MainContent';
import { Main, Flex, ButtonContainer } from './styles';
import MessageCard from '../../components/Cards/MessageCard';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import SelectSidebar from '../../components/Inputs/Select/SelectSidebar';

const CadastroConcluido = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [dadoEquipe, setDadoEquipe] = useState(sessionStorage.getItem('dadoEquipe'));

  useEffect(() => {
  }, [dadoEquipe, setDadoEquipe]);

  const submit = async () => {
    setLoading(true);
    try {
      await formPost('/PortalCorretor/EnviarEmailCadastroBeneficiario', {
        vendaID: state.vendaID,
      });
      history.push('/cadastro-envio');
    } catch (err) {
      addToast({
        title: 'Falha ao enviar email, tente novamente',
        type: 'error',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <MainContent>
        <Sidebar>
          {!user.corretora &&
            <SidebarList title="Corretoras">
              <SelectSidebar
                // placeholderDefault
                onChange={(e) => {
                  sessionStorage.setItem('dadoEquipe', e.target.value);
                  setDadoEquipe(e.target.value);
                }}
                value={sessionStorage.getItem('dadoEquipe')}
              >
                {user.equipes.map(equipes => (
                  <option
                    value={equipes.equipeVendasID}
                  >
                    {equipes.corretora}
                  </option>
                ),
                )}
              </SelectSidebar>
            </SidebarList>}
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
              Cotação
            </SidebarListItem>}
            {/* <SidebarListItem onClick={() => history.push('/cadastro-plano')}>
              Cadastro de Beneficiário
            </SidebarListItem> */}
          </SidebarList>
          {/* <SidebarList title="Material de Apoio">
            <SidebarListItem>Tabela de Preços</SidebarListItem>
            <SidebarListItem>Ficha Astra</SidebarListItem>
            <SidebarListItem>Declaração de Saúde</SidebarListItem>
          </SidebarList> */}
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          <Main>
            <Flex>
              <FullPageFlexSection>
                <MessageCard
                  title="Cadastro feito com sucesso"
                  noBorder
                  fullHeight={false}
                  style={{ maxWidth: '482px' }}
                  centerTitle
                >
                  <p>
                    Envie para o seu cliente uma declaração de saúde para dar
                    andamento ao processo
                  </p>
                  <ButtonContainer>
                    <Button onClick={submit} loading={loading} style={{ marginBottom: '10px' }}>
                      Enviar Email
                    </Button>
                  </ButtonContainer>
                </MessageCard>
              </FullPageFlexSection>
            </Flex>
          </Main>
        </div>
      </MainContent>
    </>
  );
};

export default CadastroConcluido;
