import axios from 'axios';

const api = axios.create({
  //baseURL: 'https://adderehml.sistemaquiron.com.br',
  baseURL: 'https://addere.sistemaquiron.com.br',
});

export const formPost = async (url, form) => {
  const formData = new FormData();
  for (let key in form) {
    formData.append(key, form[key]);
  }
  const config = {
    'Content-Type': 'multipart/form-data',
  };

  const response = await api.post(url, formData, config);
  if (response?.data) {
    if (response?.data?.sucesso) {
      return response
    } else {
      throw new Error(response?.data?.mensagem)
    }
  }
}

export default api;
