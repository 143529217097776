import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { setLocale, mixed } from 'yup';
// import FileBase64 from 'react-file-base64';
import HeaderPortal from '../../components/Headers/HeaderPortal';
import FormTitle from '../../components/Form/FormTitle';
import Select from '../../components/Inputs/Select';
import FileBase64 from '../../components/Inputs/FileBase64';
import TextInput from '../../components/Inputs/TextInput';
import DateInput from '../../components/Inputs/DateInput';
import SplitInputs from '../../components/Inputs/SplitInputs';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import Container from '../../components/Containers/Container';
import TableHistory from '../../components/Tables/TableHistory';
import Button from '../../components/Buttons/Button';
import {
  requestForm,
  ListarCidades,
  SalvarVenda,
  EditarVenda,
  ConsultarCEP,
  ConsultarCPF,
  SelecionarVencimento,
} from './requests';
// import plusIcon from '../../assets/images/plus.png';
import { useToast } from '../../hooks/toast';
import PlanCard from '../../components/Cards/PlanCard';
import api, { formPost } from '../../services/api';
import {
  Main,
  Flex,
  Divider,
  AddButton,
  AddButtonContainer,
  ContainerFile,
} from './styles';
import { parseDateString } from '../../utils/parser';
import { useAuth } from '../../hooks/auth';
import { Input } from '../../components/Inputs/TextInput/styles';
import SelectSidebar from '../../components/Inputs/Select/SelectSidebar';
import { Minus, Plus } from '../../components/Inputs/DateInput/styles';
import plusIcon from '../../assets/images/plus.png';
import removeIcon from '../../assets/images/remove.png';

const initialValuesPush = {
  nome: '',
  titular: false,
  responsavelFinanceiro: false,
  filiacao: '',
  masculino: '',
  tipoEstadoCivilID: '',
  cpf: '',
  cns: '',
  dataNascimento: '',
  nascidoVivo: '',
  tipoDependenciaID: '',
  planoAnteriorSaude: '',
  planoAnteriorOdontologico: '',
  planoAnteriorANSSaude: '',
  planoAnteriorANSOdontologico: '',
};

const responsavelPush = {
  nome: '',
  titular: false,
  cpf: '',
  masculino: '',
  dataNascimento: '',
  responsavelFinanceiro: true,
  filiacao: '',
  tipoDependenciaID: '',
  contatos: [
    {
      fiscal: true,
      telefone: '',
      email: '',
    },
  ],
};

const today = new Date();

setLocale({
  mixed: {
    notType: '${path} é obrigatório',
  }
})

const CadastroPlanoSchema = Yup.object().shape({
  contratoCobrancaID: Yup.string().required('Campo obrigatório'),
  dataVigencia: Yup.string().required('Campo obrigatório'),
  diaVencimento: Yup.string().required('Campo obrigatório'),
  documentos: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string()
              //  .test('fileFormat', 'PDF only', (value) => {
              //       if (!value) {
              //         alert('Arquivo é obrigatório')
              //         return false;
              //       }
              //       // return value && ['application/pdf'].includes(value.type);
              //       return true;
              //  })
               .required('Arquivo é obrigatório'),
    }),
  ),
  beneficiarios: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string().required('Nome é obrigatório'),
      responsavelFinanceiro: Yup.boolean(),
      // cns: Yup.string().required('CNS é obrigatório'),
      cns: Yup.string().when('responsavelFinanceiro', {
        is: false,
        then: Yup.string().required('CNS é obrigatório'),
        otherwise: Yup.string().nullable(),
      }),
      cpf: Yup.string().required('CPF é obrigatório'),
      // filiacao: Yup.string().required('Nome da mãe é obrigatório'),
      filiacao: Yup.string().when('responsavelFinanceiro', {
        is: false,
        then: Yup.string().required('Nome da mãe é obrigatório'),
      }),
      dataNascimento: Yup.date()
        .typeError('Data inválida')
        .max(today, 'Data inválida')
        .transform(parseDateString)
        .required('Data de nascimento é obrigatória'),
      masculino: Yup.string().required('Campo obrigatório'),
      // masculino: Yup.string().when('responsavelFinanceiro', {
      //   is: false,
      //   then: Yup.string().required('Campo obrigatório'),
      // }),
      // tipoEstadoCivilID: Yup.string().required('Campo obrigatório'),
      tipoEstadoCivilID: Yup.string().when('responsavelFinanceiro', {
        is: false,
        then: Yup.string().required('Campo obrigatório'),
      }),
      matricula: Yup.string().nullable(
        true,
      ) /* .required('Matrícula é obrigatória') */,
      dataAdmissao: Yup.date()
        .typeError('Data inválida')
        .max(today, 'Data inválida')
        .transform(parseDateString)
        .nullable() /* .required('Data de admissão é obrigatória') */,
      // planoAnteriorSaude: Yup.string().required('Plano Médico é obrigatório'),
      // planoAnteriorOdontologico: Yup.string().required(
      //   'Plano Dental é obrigatória',
      // ),
      enderecos: Yup.array().of(
        Yup.object().shape({
          CEP: Yup.string().required('CEP é obrigatório'),
          logradouro: Yup.string().required('Logradouro é obrigatório'),
          bairro: Yup.string().required('Bairro é obrigatório'),
          complemento: Yup.string().nullable(true),
          numero: Yup.number().required('Número é obrigatório'),
        }),
      ),
      contatos: Yup.array().of(
        Yup.object().shape({
          telefone: Yup.string().required('Telefone é obrigatório'),
          email: Yup.string()
            .email('Email inválido')
            .required('Email é obrigatório'),
        }),
      ),
    }),
  ),
});

const CadastroPlano = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { state, search } = useLocation();
  // const [cep, setCep] = useState();
  const [logradouro, setLogradouro] = useState('');
  const [estado, setEstado] = useState();
  const [dataVigencia, setDataVigencia] = useState();
  const [cidades, setCidades] = useState([]);
  const [diasVencimento, setDiasVencimento] = useState([]);
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(search);
  const vendaID = query.get('vendaID');
  const { addToast } = useToast();
  const [venda, setVenda] = useState();
  const [plano, setPlano] = useState(state?.plan);
  const [cotacaoID, setCotacaoID] = useState(state?.cotacaoID);
  const [dadosCPF, setDadosCPF] = useState();
  const [dadoEquipe, setDadoEquipe] = useState(sessionStorage.getItem('dadoEquipe'));
  const [idadeTitular, setIdadeTitular] = useState();
  // const [plusDocumento, setPlusDocumento] = useState();
  const [documentoTipo0, setDocumentoTipo0] = useState(0);
  const [documentoTipo1, setDocumentoTipo1] = useState(0);
  const [documentoTipo2, setDocumentoTipo2] = useState(0);

  var initialValues = state?.quantidade
    ? new Array(state.quantidade - 1).fill(initialValuesPush)
    : [];

  var valoresAuxiliares = [];
  initialValues.forEach(function callbackFn(element, index) {
    element.dataNascimento = plano?.beneficiarios[index + 1].dataNascimento.substring(0,10);
    valoresAuxiliares.push({
      ...element
    });
  });
  initialValues = valoresAuxiliares;

  const responsavelValues = (idadeTitular < 18)
    ? new Array(1).fill(responsavelPush)
    : [];

  useEffect(() => {
  }, [dadoEquipe, setDadoEquipe, documentoTipo0, setDocumentoTipo0, documentoTipo1, setDocumentoTipo1, documentoTipo2, setDocumentoTipo2]);
  useEffect(() => {
    // let start_date = '';
    // if (vendaID) {
    //   start_date = new Date(plano?.beneficiarios[0].dataNascimento);
    // }
    if (plano) {
      const match = /(\d+)\/(\d+)\/(\d+)/.exec(plano?.beneficiarios[0].dataNascimento)
      const start_date = new Date(match[3], match[2], match[1]);
      const d = new Date;
      const ano_atual = d.getFullYear();
      const mes_atual = d.getMonth() + 1;
      const dia_atual = d.getDate();
      const ano_titular = start_date.getFullYear();
      const mes_titular = start_date.getMonth() + 1;
      const dia_titular = start_date.getDate();
      let quantos_anos = ano_atual - ano_titular;
      if (mes_atual < mes_titular || mes_atual === mes_titular && dia_atual < dia_titular) {
        quantos_anos--;
      }
      setIdadeTitular(quantos_anos)
    }
  }, [idadeTitular, setIdadeTitular]);

  useEffect(() => {
    if (estado) {
      ListarCidades(estado).then((response) => {
        setCidades(response);
      });
    }
  }, [estado, setCidades]);

  // useEffect(() => {
  // }, [diasVencimento, setDiasVencimento]);

  useEffect(() => {
    if (dataVigencia) {
      SelecionarVencimento(plano.contratoProdutoID, dataVigencia).then((response) => {
        setDiasVencimento(response);
      });
    }
  }, [dataVigencia, setDataVigencia]);

  const [form, setForm] = useState({
    tipoDependencia: [],
    tipoEstadoCivil: [],
    formasCobranca: [],
    diasVencimento: [],
    datasVigencia: [],
    estados: [],
    urlFichaAssociativa: '',
  });

  useEffect(() => {
    if (state && state.plan) {
      requestForm(state.plan.contratoProdutoID)
        .then((response) => {
          setForm(response);
          setEstado(response.estados[0].estadoID);
        })
        .catch(() =>
          addToast({
            title: 'Erro ao carregar dados. Tente novamente',
            type: 'error',
          }),
        );
    }
    formPost('/PortalCorretor/SelecionarVenda', {
      vendaID,
    }).then(({ data }) => {
      if (data && data.venda && data.venda.beneficiarios) {
        data.venda.beneficiarios.sort(function (a, b) {
          if (b.titular) {
              return 1;
          } else {
            return -1;
          }
        });
        if (data.venda.beneficiarios.length > 0) {
          data.venda.beneficiarios[0].enderecos = data.venda.enderecos;
        }
      }
      setVenda(data.venda);
      setPlano(data.produto);
      requestForm(data.venda.contratoProdutoID)
        .then((response) => {
          setForm(response);
          setEstado(data.venda.enderecos[0].estadoID);
          setDataVigencia(data.venda.dataVigencia);
        })
        .catch(() =>
          addToast({
            title: 'Erro ao carregar dados. Tente novamente',
            type: 'error',
          }),
        );
    });
  }, [setForm, setEstado, setVenda, setPlano]);

  const initialValuesProp = useMemo(() => {
    const initial = {
      cotacaoID,
      dadoEquipe,
      contratoProdutoID:
        state && state.plan.contratoProdutoID
          ? state.plan.contratoProdutoID
          : '',
      contratoCobrancaID: '',
      dataVigencia: '',
      diaVencimento: '',
      documentosNome: '',
      beneficiarios: [
        {
          nome: '',
          cns: '',
          titular: true,
          masculino: '',
          tipoEstadoCivilID: '',
          cpf: '',
          dataNascimento: plano ? plano.beneficiarios[0].dataNascimento : '',
          responsavelFinanceiro: (idadeTitular > 18) ? true : false,
          filiacao: '',
          tipoDependenciaID: '18f91ba9-ba79-4142-9b57-0b5775f4261c',
          matricula: '',
          dataAdmissao: '',
          planoAnteriorSaude: '',
          planoAnteriorOdontologico: '',
          planoAnteriorANSSaude: '',
          planoAnteriorANSOdontologico: '',
          enderecos: [
            {
              CEP: '',
              logradouro: '',
              cidadeID: '',
              bairro: '',
              complemento: '',
              numero: '',
            },
          ],
          contatos: [
            {
              // nome: '',
              fiscal: true,
              telefone: '',
              email: '',
            },
          ],
        },
        ...responsavelValues,
        ...initialValues,
      ],
      documentos: [
        {
          nome: ''
        }, 
        {
          nome: ''
        }, 
        {
          nome: ''
        }
      ],
    };
    if (venda) {
      return venda;
    }
    // setVenda(initial);
    return initial;
  }, [venda, responsavelValues, initialValues]);

  function chamarConsultarCEP(cep, setFieldValue) {
    const cepAux = cep?.replaceAll('_', '');
    if (cepAux && cepAux.length > 8) {
      ConsultarCEP(cepAux).then((endereco) => {
        if (endereco) {
          setFieldValue(`beneficiarios.${0}.enderecos.${0}.logradouro`, endereco.logradouro);
          setEstado(endereco.estadoID);
          setFieldValue(`beneficiarios.${0}.enderecos.${0}.cidadeID`, endereco.cidadeID);
          setFieldValue(`beneficiarios.${0}.enderecos.${0}.complemento`, endereco.complemento);
          setFieldValue(`beneficiarios.${0}.enderecos.${0}.bairro`, endereco.bairro);
        }
      });
    }
  }

  function chamarConsultarCPF(cpf, setFieldValue) {
    ConsultarCPF(cpf).then((dados) => {
      setDadosCPF(dados);
      if (!dados) {
        setFieldValue(`beneficiarios.${0}.nome`, '');
        setFieldValue(`beneficiarios.${0}.filiacao`, '');
        setFieldValue(`beneficiarios.${0}.masculino`, '');
      }
      if (dados) {
        if (dados.genero === 'F') {
          dados.genero = false;
        } else if (dados.genero === 'M') {
          dados.genero = true;
        }
        const dataInicial = new Date(initialValuesProp.beneficiarios[0].dataNascimento);
        const dataTeste = new Date(dados.dataNascimento)
        if (dataInicial.toDateString() !== dataTeste.toDateString()) {
          alert('Data de nascimento calculada difere da data de nascimento do CPF informado.');
          return;
        }
        setFieldValue(`beneficiarios.${0}.nome`, dados.nome);
        setFieldValue(`beneficiarios.${0}.dataNascimento`, dados.dataNascimento);
        setFieldValue(`beneficiarios.${0}.filiacao`, dados.nomeMae);
        setFieldValue(`beneficiarios.${0}.masculino`, dados.genero);
      }
    })
  }

  function downloadElegibilidade() {
    api.get(form.urlFichaAssociativa);
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar>
          {!user.corretora &&
            <SidebarList title="Corretoras">
              <SelectSidebar
                onChange={(e) => {
                  sessionStorage.setItem('dadoEquipe', e.target.value);
                  setDadoEquipe(e.target.value);
                }}
                value={
                  sessionStorage.getItem('dadoEquipe')}
              >
                {user.equipes.map(equipes => {
                  return (
                    <option
                      value={equipes.equipeVendasID}
                    >
                      {(equipes.corretora)}
                    </option>
                  )
                },
                )}
              </SelectSidebar>
            </SidebarList>}
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
              Cotação
            </SidebarListItem>}
          </SidebarList>
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          <Main>
            <ul className="breadcrumb">
              <li><a href="/portal"><span>Home</span></a></li>
              <li><a href="/cadastro-envio"><span>Status Proposta</span></a></li>
              <li><a href="/cadastro"><span>Cotação</span></a></li>
              <li><span>Cadastro</span></li>
            </ul>
            <Formik
              enableReinitialize
              validationSchema={CadastroPlanoSchema}
              initialValues={initialValuesProp}
              onSubmit={async (values) => {
                setLoading(true);
                const data = values;
                var formData = new FormData();
                data.beneficiarios = data.beneficiarios.map((b) => {
                  const parsedData = {
                    ...b,
                  };
                  if (parsedData.contatos?.length) {
                    parsedData.contatos[0].nome = parsedData.nome;
                  }
                  return {
                    beneficiario: parsedData,
                  };
                });

                //  https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data
                var buildFormData = function (fd, d, parentKey) {
                  if (d && typeof d === 'object' && !(d instanceof Date) && !(d instanceof File)) {
                    Object.keys(d).forEach(key => {
                      buildFormData(fd, d[key], parentKey ? `${parentKey}[${key}]` : key);
                    });
                  } else {
                    const value = d == null ? '' : d;
                    fd.append(parentKey, value);
                  }
                }
                buildFormData(formData, data);
                try {
                  let response;
                  if (vendaID) {
                    response = await EditarVenda(formData);
                    history.push('/cadastro-envio')
                  } else {
                    response = await SalvarVenda(formData);
                    history.push('/cadastro-concluido', {
                      vendaID: response,
                    });
                  }
                } catch {
                  setLoading(false);
                  addToast({
                    type: 'error',
                    title: 'Erro ao enviar formulário',
                  });
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Flex>
                    <FieldArray name="beneficiarios">
                      {({ push, remove }) => (
                        <>
                          <FullPageFlexSection>
                            <Container
                              title="Dados do titular"
                              noBorder
                              fullHeight={false}
                            >
                              <Divider />
                              <SplitInputs>
                                <div onChange={(e) => {
                                  chamarConsultarCPF(e.target.value, setFieldValue);
                                }}>
                                  {/* <div> */}
                                  <FormTitle>CPF</FormTitle>
                                  <TextInput
                                    placeholder=""
                                    name={`beneficiarios.${0}.cpf`}
                                    type="cpf"
                                  />
                                </div>
                                <div>
                                  <FormTitle>Data de Nascimento</FormTitle>
                                  <DateInput
                                    placeholder=""
                                    name={`beneficiarios.${0}.dataNascimento`}
                                    disabled="disabled"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `beneficiarios.${0}.dataNascimento`,
                                        e,
                                      );
                                    }}
                                    value={
                                      values?.beneficiarios[0]?.dataNascimento
                                    }
                                    maxDate={new Date(Date.now())}
                                  />
                                </div>
                              </SplitInputs>
                              <SplitInputs>
                                <div>
                                  <FormTitle>Matrícula Funcional</FormTitle>
                                  <TextInput
                                    placeholder=""
                                    name={`beneficiarios.${0}.matricula`}
                                    type="number"
                                  />
                                </div>
                                <div>
                                  <FormTitle>Data de Admissão</FormTitle>
                                  <DateInput
                                    placeholder=""
                                    name={`beneficiarios.${0}.dataAdmissao`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `beneficiarios.${0}.dataAdmissao`,
                                        e,
                                      );
                                    }}
                                    value={
                                      values?.beneficiarios[0]?.dataAdmissao
                                    }
                                    maxDate={new Date(Date.now())}
                                  />
                                </div>
                              </SplitInputs>
                              {/* <SplitInputs>
                                <div>
                                  <FormTitle>Plano Médico</FormTitle>
                                  <TextInput
                                    placeholder=""
                                    name={`beneficiarios.${0}.planoAnteriorSaude`}
                                  />
                                </div>
                                <div>
                                  <FormTitle>Plano Dental</FormTitle>
                                  <TextInput
                                    placeholder=""
                                    name={`beneficiarios.${0}.planoAnteriorOdontologico`}
                                  />
                                </div>
                              </SplitInputs> */}
                            </Container>
                            <Container
                              title="Informação básica do Titular"
                              noBorder
                              fullHeight={false}
                            >
                              <Divider />
                              <FormTitle>
                                Nome completo e sem abreviações
                              </FormTitle>
                              <TextInput
                                placeholder=""
                                disabled={dadosCPF ? 'disabled' : ''}
                                name={`beneficiarios.${0}.nome`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `beneficiarios.${0}.nome`,
                                    e.target.value,
                                  );
                                  setFieldValue(
                                    `beneficiarios.${0}.contatos.${0}.nome`,
                                    e.target.value,
                                  );
                                }}
                              />
                              <FormTitle>
                                Nome completo da mãe e sem abreviações
                              </FormTitle>
                              <TextInput
                                placeholder=""
                                disabled={dadosCPF ? 'disabled' : ''}
                                name={`beneficiarios.${0}.filiacao`}
                              />
                              <FormTitle>Nº Cartão Nacional de Saúde - <a href="http://cnesadm.datasus.gov.br/cnesadm/publico/usuarios/cadastro" target="_blank" rel="noreferrer">clique aqui para consultar</a></FormTitle>
                              <TextInput
                                name={`beneficiarios.${0}.cns`}
                                type="number"
                              />

                              <SplitInputs>
                                <div>
                                  <FormTitle>Sexo</FormTitle>
                                  <Select
                                    placeholder=""
                                    disabled={dadosCPF ? 'disabled' : ''}
                                    name={`beneficiarios.${0}.masculino`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `beneficiarios.${0}.masculino`,
                                        e.target.value === 'masculino',
                                      );
                                    }}
                                    placeholderDefault
                                    value={
                                      (values.beneficiarios[0].masculino ===
                                        false) ? 'feminino'
                                        : (values.beneficiarios[0].masculino ===
                                          true ? 'masculino' : '')
                                    }
                                  >
                                    <option value="masculino">Masculino</option>
                                    <option value="feminino">Feminino</option>
                                  </Select>
                                </div>
                                <div>
                                  <FormTitle>Estado Civil</FormTitle>
                                  <Select
                                    placeholder=""
                                    name={`beneficiarios.${0}.tipoEstadoCivilID`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `beneficiarios.${0}.tipoEstadoCivilID`,
                                        e.target.value,
                                      );
                                    }}
                                    placeholderDefault
                                    value={
                                      values?.beneficiarios[0]
                                        ?.tipoEstadoCivilID
                                    }
                                  >
                                    {form.tipoEstadoCivil.map(
                                      ({ tipoEstadoCivilID, nome }) => (
                                        <option
                                          key={tipoEstadoCivilID}
                                          value={tipoEstadoCivilID}
                                        >
                                          {nome}
                                        </option>
                                      ),
                                    )}
                                  </Select>
                                </div>
                              </SplitInputs>
                            </Container>
                            <Container
                              title="Endereço do Titular"
                              noBorder
                              fullHeight={false}
                            >
                              <FieldArray name={`beneficiarios.${0}.contatos`}>
                                <FieldArray
                                  name={`beneficiarios.${0}.enderecos`}
                                >
                                  <>
                                    <Divider />
                                    <SplitInputs>
                                      <div
                                        onChange={(e) => {
                                          chamarConsultarCEP(e.target.value, setFieldValue);
                                        }}>
                                        <FormTitle>CEP</FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${0}.enderecos.${0}.CEP`}
                                          type="cep"
                                        />
                                      </div>
                                      <div>
                                        <FormTitle>Endereço</FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${0}.enderecos.${0}.logradouro`}
                                        // value={logradouro}
                                        // onChangeText={setLogradouro}
                                        />
                                      </div>
                                    </SplitInputs>
                                    <SplitInputs>
                                      <div>
                                        <FormTitle>Estado</FormTitle>
                                        <Select
                                          placeholderDefault
                                          onChange={(e) => {
                                            setEstado(e.target.value);
                                          }}
                                          value={estado}
                                        >
                                          {form.estados.map(
                                            ({ estadoID, estadoUF }) => (
                                              <option
                                                key={estadoID}
                                                value={estadoID}
                                              >
                                                {estadoUF}
                                              </option>
                                            ),
                                          )}
                                        </Select>
                                      </div>
                                      <div>
                                        <FormTitle>Cidade</FormTitle>
                                        <Select
                                          placeholder=""
                                          name={`beneficiarios.${0}.enderecos.${0}.cidadeID`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `beneficiarios.${0}.enderecos.${0}.cidadeID`,
                                              e.target.value,
                                            );
                                          }}
                                          placeholderDefault
                                          value={
                                            values?.beneficiarios?.[0]
                                              ?.enderecos?.[0]?.cidadeID
                                          }
                                        >
                                          {cidades.map(
                                            ({ cidadeID, cidadeNome }) => (
                                              <option
                                                key={cidadeID}
                                                value={cidadeID}
                                              >
                                                {cidadeNome}
                                              </option>
                                            ),
                                          )}
                                        </Select>
                                      </div>
                                    </SplitInputs>
                                    <SplitInputs>
                                      <div>
                                        <FormTitle>Bairro</FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${0}.enderecos.${0}.bairro`}
                                        />
                                      </div>
                                      <div>
                                        <FormTitle>Número</FormTitle>
                                        <TextInput
                                          type="number"
                                          placeholder=""
                                          name={`beneficiarios.${0}.enderecos.${0}.numero`}
                                        />
                                      </div>
                                    </SplitInputs>
                                    <SplitInputs>
                                      <div>
                                        <FormTitle>Complemento</FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${0}.enderecos.${0}.complemento`}
                                        />
                                      </div>
                                      <div>
                                        <FormTitle>Telefone</FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${0}.contatos.${0}.telefone`}
                                          type="phone"
                                        />
                                      </div>
                                    </SplitInputs>
                                    <FormTitle>Email</FormTitle>
                                    <TextInput
                                      placeholder=""
                                      name={`beneficiarios.${0}.contatos.${0}.email`}
                                    />
                                  </>
                                </FieldArray>
                              </FieldArray>
                            </Container>
                          </FullPageFlexSection>
                          <FullPageFlexSection>
                            {values.beneficiarios &&
                              values.beneficiarios.length > 1 &&
                              values.beneficiarios
                                .slice(1)
                                .map((_, arrIndex) => {

                                  let index = arrIndex + 1;
                                  if (values.beneficiarios[index].responsavelFinanceiro) {
                                    return (
                                      <Container
                                        title="Dados do Responsável Financeiro"
                                        noBorder
                                        fullHeight={false}>
                                        <Divider />
                                        <SplitInputs>
                                          <div
                                            onChange={(e) => {
                                              ConsultarCPF(e.target.value, setFieldValue).then((dados) => {
                                                setDadosCPF(dados);
                                                if (!dados) {
                                                  setFieldValue(`beneficiarios.${index}.nome`, '');
                                                  setFieldValue(`beneficiarios.${index}.dataNascimento`, '');
                                                  setFieldValue(`beneficiarios.${index}.masculino`, '');
                                                }
                                                if (dados) {
                                                  if (dados.genero === 'F') {
                                                    dados.genero = false;
                                                  } else if (dados.genero === 'M') {
                                                    dados.genero = true;
                                                  }
                                                  setFieldValue(`beneficiarios.${index}.nome`, dados.nome);
                                                  setFieldValue(`beneficiarios.${index}.dataNascimento`, dados.dataNascimento);
                                                  setFieldValue(`beneficiarios.${index}.masculino`, dados.genero);
                                                }
                                              })
                                            }}
                                          >
                                            {/* <div> */}
                                            <FormTitle>CPF</FormTitle>
                                            <TextInput
                                              placeholder=""
                                              type="cpf"
                                              name={`beneficiarios.${index}.cpf`}
                                            />
                                          </div>
                                          <div>
                                            <FormTitle>Data de Nascimento</FormTitle>
                                            <DateInput
                                              placeholder=""
                                              name={`beneficiarios.${index}.dataNascimento`}
                                              maxDate={new Date(Date.now())}
                                              disabled={dadosCPF ? 'disabled' : ''}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `beneficiarios.${index}.dataNascimento`,
                                                  e,
                                                );
                                              }}
                                            />
                                          </div>
                                        </SplitInputs>
                                        <FormTitle>Nome completo e sem abreviações</FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${index}.nome`}
                                          disabled={dadosCPF ? 'disabled' : ''}
                                        />
                                        <SplitInputs>
                                          <div>
                                            <FormTitle>Grau de Parentesco</FormTitle>
                                            <Select
                                              placeholder=""
                                              placeholderDefault
                                              name={`beneficiarios.${index}.tipoDependenciaID`}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `beneficiarios.${index}.tipoDependenciaID`,
                                                  e.target.value,
                                                );
                                              }}
                                              value={
                                                values.beneficiarios[index]
                                                  .tipoDependenciaID
                                              }
                                            >
                                              {form.tipoDependencia.map(
                                                ({ tipoDependenciaID, nome }) => (
                                                  <option
                                                    key={tipoDependenciaID}
                                                    value={tipoDependenciaID}
                                                  >
                                                    {nome}
                                                  </option>
                                                ),
                                              )}
                                            </Select>
                                          </div>
                                          <div>
                                            <FormTitle>Telefone</FormTitle>
                                            <TextInput
                                              placeholder=""
                                              type="phone"
                                              name={`beneficiarios.${index}.contatos.${0}.telefone`}
                                            />
                                          </div>
                                        </SplitInputs>
                                        <SplitInputs>
                                          <div>
                                            <FormTitle>Sexo</FormTitle>
                                            <Select
                                              placeholder=""
                                              name={`beneficiarios.${index}.masculino`}
                                              disabled={dadosCPF ? 'disabled' : ''}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `beneficiarios.${index}.masculino`,
                                                  e.target.value === 'masculino',
                                                );
                                              }}
                                              placeholderDefault
                                              value={
                                                values.beneficiarios[index].masculino ===
                                                  false ||
                                                  values.beneficiarios[index].masculino ===
                                                  'feminino'
                                                  ? 'feminino'
                                                  : (values.beneficiarios[index].masculino ? 'masculino' : '')
                                              }
                                            >
                                              <option value="masculino">
                                                Masculino
                                              </option>
                                              <option value="feminino">
                                                Feminino
                                              </option>
                                            </Select>
                                          </div>
                                          <div>
                                            <FormTitle>Email</FormTitle>
                                            <TextInput
                                              placeholder=""
                                              type="email"
                                              name={`beneficiarios.${index}.contatos.${0}.email`}
                                            />
                                          </div>
                                        </SplitInputs>
                                      </Container>
                                    );
                                  } else {
                                    return (
                                      <Container
                                        title={`Dados do Dependente ${(idadeTitular < 18) ? arrIndex : index}`}
                                        key={`Benefíciario ${index}`}
                                        noBorder
                                        fullHeight={false}
                                        onRemove={() => remove(arrIndex)}
                                      >
                                        <Divider />
                                        <SplitInputs>
                                          <div
                                            onChange={(e) => {
                                              ConsultarCPF(e.target.value, setFieldValue).then((dados) => {
                                                setDadosCPF(dados);
                                                if (!dados) {
                                                  setFieldValue(`beneficiarios.${index}.nome`, '');
                                                  setFieldValue(`beneficiarios.${index}.filiacao`, '');
                                                  setFieldValue(`beneficiarios.${index}.masculino`, '');
                                                }
                                                if (dados) {
                                                  if (dados.genero === 'F') {
                                                    dados.genero = false;
                                                  } else if (dados.genero === 'M') {
                                                    dados.genero = true;
                                                  }
                                                  const dataInicial = new Date(initialValuesProp.beneficiarios[index].dataNascimento);
                                                  const dataTeste = new Date(dados.dataNascimento)
                                                  if (dataInicial.toDateString() !== dataTeste.toDateString()) {
                                                    alert('Data de nascimento calculada difere da data de nascimento do CPF informado.');
                                                    return;
                                                  }
                                                  setFieldValue(`beneficiarios.${index}.nome`, dados.nome);
                                                  setFieldValue(`beneficiarios.${index}.dataNascimento`, dados.dataNascimento);
                                                  setFieldValue(`beneficiarios.${index}.filiacao`, dados.nomeMae);
                                                  setFieldValue(`beneficiarios.${index}.masculino`, dados.genero);
                                                }
                                              })
                                            }}
                                          >
                                            <FormTitle>CPF</FormTitle>
                                            <TextInput
                                              placeholder=""
                                              name={`beneficiarios.${index}.cpf`}
                                              type="cpf"
                                            />
                                          </div>
                                          <div>
                                            <FormTitle>
                                              Data de Nascimento
                                            </FormTitle>
                                            <DateInput
                                              placeholder=""
                                              name={`beneficiarios.${index}.dataNascimento`}
                                              disabled="disabled"
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `beneficiarios.${index}.dataNascimento`,
                                                  e,
                                                );
                                              }}
                                              value={
                                                values.beneficiarios[index]
                                                  .dataNascimento
                                              }
                                              maxDate={new Date(Date.now())}
                                            />
                                          </div>
                                        </SplitInputs>
                                        <FormTitle>
                                          Nome completo e sem abreviações
                                        </FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${index}.nome`}
                                          disabled={dadosCPF ? 'disabled' : ''}
                                        />
                                        <FormTitle>
                                          Nome completo da mãe e sem abreviações
                                        </FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${index}.filiacao`}
                                          disabled={dadosCPF ? 'disabled' : ''}
                                        />
                                        <SplitInputs>
                                          <div>
                                            <FormTitle>Sexo</FormTitle>
                                            <Select
                                              placeholder=""
                                              name={`beneficiarios.${index}.masculino`}
                                              disabled={dadosCPF ? 'disabled' : ''}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `beneficiarios.${index}.masculino`,
                                                  e.target.value === 'masculino',
                                                );
                                              }}
                                              placeholderDefault
                                              value={
                                                values.beneficiarios[index].masculino ===
                                                  false ||
                                                  values.beneficiarios[index].masculino ===
                                                  'feminino'
                                                  ? 'feminino'
                                                  : (values.beneficiarios[index].masculino ? 'masculino' : '')
                                              }
                                            >
                                              <option value="masculino">
                                                Masculino
                                              </option>
                                              <option value="feminino">
                                                Feminino
                                              </option>
                                            </Select>
                                          </div>
                                          <div>
                                            <FormTitle>Estado Civil</FormTitle>
                                            <Select
                                              placeholder=""
                                              name={`beneficiarios.${index}.tipoEstadoCivilID`}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `beneficiarios.${index}.tipoEstadoCivilID`,
                                                  e.target.value,
                                                );
                                              }}
                                              placeholderDefault
                                              value={
                                                values.beneficiarios[index]
                                                  .tipoEstadoCivilID
                                              }
                                            >
                                              {form.tipoEstadoCivil.map(
                                                ({ tipoEstadoCivilID, nome }) => (
                                                  <option
                                                    key={tipoEstadoCivilID}
                                                    value={tipoEstadoCivilID}
                                                  >
                                                    {nome}
                                                  </option>
                                                ),
                                              )}
                                            </Select>
                                          </div>
                                        </SplitInputs>
                                        <FormTitle>
                                          Nº de Declaração de Nascido Vivo ( a
                                          partir de 01/10/2010)
                                        </FormTitle>
                                        <TextInput
                                          placeholder=""
                                          name={`beneficiarios.${index}.nascidoVivo`}
                                          type="number"
                                        />

                                        <FormTitle>
                                          Nº cartão Nacional do Sus - <a href="http://cnesadm.datasus.gov.br/cnesadm/publico/usuarios/cadastro" target="_blank" rel="noreferrer">clique aqui para consultar</a>
                                        </FormTitle>
                                        <TextInput
                                          type="number"
                                          placeholder=""
                                          name={`beneficiarios.${index}.cns`}
                                        />

                                        <FormTitle>Grau de Parentesco</FormTitle>
                                        <Select
                                          placeholder=""
                                          name={`beneficiarios.${index}.tipoDependenciaID`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `beneficiarios.${index}.tipoDependenciaID`,
                                              e.target.value,
                                            );
                                          }}
                                          value={
                                            values.beneficiarios[index]
                                              .tipoDependenciaID
                                          }
                                          placeholderDefault
                                        >
                                          {form.tipoDependencia.map(
                                            ({ tipoDependenciaID, nome }) => (
                                              <option
                                                key={tipoDependenciaID}
                                                value={tipoDependenciaID}
                                              >
                                                {nome}
                                              </option>
                                            ),
                                          )}
                                        </Select>

                                        {/* <SplitInputs>
                                        <div>
                                          <FormTitle>Plano Médico</FormTitle>
                                          <TextInput
                                            placeholder=""
                                            name={`beneficiarios.${index}.planoAnteriorSaude`}
                                          />
                                        </div>
                                        <div>
                                          <FormTitle>Plano Dental</FormTitle>
                                          <TextInput
                                            placeholder=""
                                            name={`beneficiarios.${index}.planoAnteriorOdontologico`}
                                          />
                                        </div>
                                      </SplitInputs> */}
                                      </Container>
                                    );
                                  }
                                })}
                            {/* <AddButtonContainer
                              onClick={() => {
                                push({
                                  nome: '',
                                  titular: false,
                                  filiacao: '',
                                  masculino: '',
                                  tipoEstadoCivilID: '',
                                  cpf: '',
                                  cns: '',
                                  dataNascimento: '',
                                  nascidoVivo: '',
                                  tipoDependenciaID: '',
                                  planoAnteriorSaude: '',
                                  planoAnteriorOdontologico: '',
                                  planoAnteriorANSSaude: '',
                                  planoAnteriorANSOdontologico: '',
                                });
                              }}
                            >
                              <AddButton>Adicionar dependente</AddButton>
                              <img src={plusIcon} alt="Sinal de mais" />
                            </AddButtonContainer> */}
                            {values?.historico && (
                              <Container
                                title="Histórico"
                                noBorder
                                fullHeight={false}
                              >
                                <Divider />

                                <TableHistory data={values.historico} />
                              </Container>
                            )}
                            <Container
                              title="Informação de cobrança"
                              noBorder
                              fullHeight={false}
                            >
                              <Divider />
                              <FormTitle>Formas de cobrança</FormTitle>
                              <Select
                                placeholder=""
                                name="contratoCobrancaID"
                                onChange={(e) => {
                                  setFieldValue(
                                    'contratoCobrancaID',
                                    e.target.value,
                                  );
                                }}
                                placeholderDefault
                                value={values.contratoCobrancaID}
                              >
                                {form.formasCobranca.map(
                                  ({ contratoCobrancaID, nome }) => (
                                    <option
                                      key={contratoCobrancaID}
                                      value={contratoCobrancaID}
                                    >
                                      {nome}
                                    </option>
                                  ),
                                )}
                              </Select>
                              <SplitInputs>
                                <div>
                                  <FormTitle>Data de vigência</FormTitle>
                                  <Select
                                    placeholder=""
                                    name="dataVigencia"
                                    onChange={(e) => {
                                      setFieldValue(
                                        'dataVigencia',
                                        e.target.value,
                                      );
                                      setDataVigencia(e.target.value);
                                    }}
                                    value={values.dataVigencia}
                                    placeholderDefault
                                  >
                                    {dataVigencia ?
                                      (<option
                                        key={dataVigencia}
                                        value={dataVigencia}
                                      >
                                        {dataVigencia}
                                      </option>
                                      ) : (
                                        form.datasVigencia.map((dv) => (
                                          <option
                                            key={dv}
                                            value={dv}
                                          >
                                            {dv}
                                          </option>
                                        ))
                                      )}
                                  </Select>
                                </div>
                                <div>
                                  <FormTitle>Dia do vencimento</FormTitle>
                                  <Select
                                    placeholder=""
                                    name="diaVencimento"
                                    onChange={(e) => {
                                      setFieldValue(
                                        'diaVencimento',
                                        e.target.value,
                                      );
                                    }}
                                    placeholderDefault
                                    value={values.diaVencimento}
                                  >
                                    {dataVigencia ?
                                      (<option
                                        key={diasVencimento}
                                        value={diasVencimento}
                                      >
                                        {diasVencimento}
                                      </option>
                                      ) : (
                                        form.diasVencimento.map(
                                          (diaVencimento) => (
                                            <option
                                              key={diaVencimento}
                                              value={diaVencimento}
                                            >
                                              {diaVencimento}
                                            </option>
                                          ),
                                        )
                                      )}
                                  </Select>
                                </div>
                              </SplitInputs>
                            </Container>
                            <Container
                              title="Arquivos"
                              noBorder
                              fullHeight={false}
                            >
                              <FieldArray name="documentos">
                                {({ pushed, removed }) =>
                                (<>
                                  <Divider />
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <FormTitle >Documento com foto</FormTitle>
                                    </div>
                                    <div style={{ maxHeight: '25px', margin: '-10px' }}>
                                      <Plus
                                        type="button"
                                        onClick={(e) => {
                                          // if (values.documentos[0].nome) {
                                            values.documentos.push({ id: 0, nome: '' });
                                            setDocumentoTipo0(documentoTipo0 + 1)
                                          // }
                                        }}
                                        data-tip="Adicionar Documento">
                                        <img src={plusIcon} alt="Adicionar" />
                                      </Plus>
                                    </div>
                                  </div>
                                  <ContainerFile>
                                    {values.documentos[0]?.nomeArquivo && (
                                      <p>{values.documentos[0].nomeArquivo}</p>
                                    )}
                                    <TextInput
                                      type='file'
                                      multiple={false}
                                      name={`documentos.${0}.nome`}
                                      style={{ padding:'0', boxShadow:'0 0 0', backgroundColor:'transparent'}}
                                      onDone={({ base64, name }) => 
                                        {
                                          setFieldValue(`documentos.${0}`, {
                                            nome: name,
                                            tipoImagemID:
                                              '74C26CCB-5D7F-4EAE-8953-2F82F2C5A578',
                                            base64,
                                          })
                                        }
                                      }
                                    />
                                  </ContainerFile>
                                  {
                                    values.documentos.slice(3).map((documento, index) => {
                                      index = index + 3;
                                      if (values.documentos[index].id === 0) {
                                        return (
                                          <>
                                            <div style={{ display: 'flex' }}>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <ContainerFile>
                                                  {values.documentos && values.documentos[index]?.nome && (
                                                    <p>{values.documentos[index].nome}</p>
                                                  )}
                                                  <TextInput
                                                    type='file'
                                                    multiple={false}
                                                    name={`documentos.${index}.nome`}
                                                    style={{ padding:'0', boxShadow:'0 0 0', backgroundColor:'transparent'}}
                                                    onDone={({ base64, name }) => 
                                                      {
                                                        setFieldValue(`documentos.${index}`, {
                                                          id: 0,
                                                          nome: name,
                                                          tipoImagemID:
                                                            '74C26CCB-5D7F-4EAE-8953-2F82F2C5A578',
                                                          base64,
                                                        })
                                                      }
                                                    }
                                                  />
                                                  {/* <FileBase64
                                                    multiple={false}
                                                    onDone={({ base64, name }) =>
                                                      setFieldValue(`documentos.${index}`, {
                                                        id: 0,
                                                        nome: name,
                                                        tipoImagemID: '74C26CCB-5D7F-4EAE-8953-2F82F2C5A578',
                                                        base64,
                                                      })
                                                    }
                                                  /> */}
                                                </ContainerFile>
                                              </div>
                                              <div style={{ maxHeight: '25px', margin: '0' }}>
                                                <Minus type="button" onClick={() => {
                                                  values.documentos.splice(index, 1)
                                                  setDocumentoTipo0(documentoTipo0 + 1)
                                                }}>
                                                  <img src={removeIcon} alt="Remover" />
                                                </Minus>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      } else {
                                        return ''
                                      }
                                    })
                                  }
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <FormTitle>Comprovante de residência</FormTitle>
                                    </div>
                                    <div style={{ maxHeight: '25px', margin: '-10px' }}>
                                      <Plus
                                        type="button"
                                        onClick={() => {
                                          // if (values.documentos[1].nome) {
                                            values.documentos.push({ id: 1, nome: '' });
                                            setDocumentoTipo1(documentoTipo1 + 1);
                                          // }
                                        }}
                                        data-tip="Adicionar Documento">
                                        <img src={plusIcon} alt="Adicionar" />
                                      </Plus>
                                    </div>
                                  </div>
                                  <ContainerFile>
                                    {values.documentos[1]?.nomeArquivo && (
                                      <p>{values.documentos[1].nomeArquivo}</p>
                                    )}
                                    <TextInput
                                      type='file'
                                      multiple={false}
                                      name={`documentos.${1}.nome`}
                                      style={{ padding:'0', boxShadow:'0 0 0', backgroundColor:'transparent'}}
                                      onDone={({ base64, name }) => 
                                        {
                                          setFieldValue(`documentos.${1}`, {
                                            id: 0,
                                            nome: name,
                                            tipoImagemID:
                                              'C7C7667B-004B-11EC-884C-A0D37AFBA409',
                                            base64,
                                          })
                                        }
                                      }
                                    />
                                    {/* <FileBase64
                                      multiple={false}
                                      onDone={({ base64, name }) =>
                                        setFieldValue(`documentos.${1}`, {
                                          nome: name,
                                          tipoImagemID:
                                            'C7C7667B-004B-11EC-884C-A0D37AFBA409',
                                          base64,
                                        })
                                      }
                                    /> */}
                                  </ContainerFile>
                                  {
                                    values.documentos.slice(3).map((documento, index) => {
                                      index = index + 3;
                                      if (values.documentos[index].id === 1) {
                                        return (
                                          <>
                                            <div style={{ display: 'flex' }}>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <ContainerFile>
                                                  {values.documentos[index]?.nomeArquivo && (
                                                    <p>{values.documentos[index].nomeArquivo}</p>
                                                  )}
                                                  <TextInput
                                                    type='file'
                                                    multiple={false}
                                                    name={`documentos.${index}.nome`}
                                                    style={{ padding:'0', boxShadow:'0 0 0', backgroundColor:'transparent'}}
                                                    onDone={({ base64, name }) => 
                                                      {
                                                        setFieldValue(`documentos.${index}`, {
                                                          id: 1,
                                                          nome: name,
                                                          tipoImagemID:
                                                            'C7C7667B-004B-11EC-884C-A0D37AFBA409',
                                                          base64,
                                                        })
                                                      }
                                                    }
                                                  />
                                                  {/* <FileBase64
                                                    multiple={false}
                                                    onDone={({ base64, name }) =>
                                                      setFieldValue(`documentos.${index}`, {
                                                        id: 1,
                                                        nome: name,
                                                        tipoImagemID: 'C7C7667B-004B-11EC-884C-A0D37AFBA409',
                                                        base64,
                                                      })
                                                    }
                                                  /> */}
                                                </ContainerFile>
                                              </div>
                                              <div style={{ maxHeight: '25px', margin: '0' }}>
                                                <Minus type="button" onClick={() => {
                                                  values.documentos.splice(index, 1)
                                                  setDocumentoTipo1(documentoTipo1 + 1);
                                                }}>
                                                  <img src={removeIcon} alt="Remover" />
                                                </Minus>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      } else {
                                        return '';
                                      }
                                    })
                                  }
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <FormTitle>
                                        Comprovante de elegibilidade
                                        {form.urlFichaAssociativa ? (<> - <a target="_blank" rel="noreferrer" href={form.urlFichaAssociativa}>Download</a></>) : <></>}
                                      </FormTitle>
                                    </div>
                                    <div style={{ maxHeight: '25px', margin: '-10px' }}>
                                      <Plus
                                        type="button"
                                        onClick={() => {
                                          // if (values.documentos[2].nome) {
                                            values.documentos.push({ id: 2, nome: '' });
                                            setDocumentoTipo2(documentoTipo2 + 1);
                                          // }
                                        }}
                                        data-tip="Adicionar Documento">
                                        <img src={plusIcon} alt="Adicionar" />
                                      </Plus>
                                    </div>
                                  </div>
                                  <ContainerFile>
                                    {values.documentos[2]?.nomeArquivo && (
                                      <p>{values.documentos[2].nomeArquivo}</p>
                                    )}
                                    <TextInput
                                      type='file'
                                      multiple={false}
                                      name={`documentos.${2}.nome`}
                                      style={{ padding:'0', boxShadow:'0 0 0', backgroundColor:'transparent'}}
                                      onDone={({ base64, name }) => 
                                        {
                                          setFieldValue(`documentos.${2}`, {
                                            nome: name,
                                            tipoImagemID:
                                              '6C439340-C0FB-491A-8AAE-93E9C89925C1',
                                            base64,
                                          })
                                        }
                                      }
                                    />
                                    {/* <FileBase64
                                      multiple={false}
                                      onDone={({ base64, name }) =>
                                        setFieldValue(`documentos.${2}`, {
                                          nome: name,
                                          tipoImagemID:
                                            '6C439340-C0FB-491A-8AAE-93E9C89925C1',
                                          base64,
                                        })
                                      }
                                    /> */}
                                  </ContainerFile>
                                  {
                                    values.documentos.slice(3).map((documento, index) => {
                                      index = index + 3;
                                      if (values.documentos[index].id === 2) {
                                        return (
                                          <>
                                            <div style={{ display: 'flex' }}>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <ContainerFile>
                                                  {values.documentos[index]?.nomeArquivo && (
                                                    <p>{values.documentos[index].nomeArquivo}</p>
                                                  )}
                                                  <TextInput
                                                    type='file'
                                                    multiple={false}
                                                    name={`documentos.${index}.nome`}
                                                    style={{ padding:'0', boxShadow:'0 0 0', backgroundColor:'transparent'}}
                                                    onDone={({ base64, name }) => 
                                                      {
                                                        setFieldValue(`documentos.${index}`, {
                                                          id: 2,
                                                          nome: name,
                                                          tipoImagemID:
                                                            '6C439340-C0FB-491A-8AAE-93E9C89925C1',
                                                          base64,
                                                        })
                                                      }
                                                    }
                                                  />
                                                  {/* <FileBase64
                                                    multiple={false}
                                                    onDone={({ base64, name }) =>
                                                      setFieldValue(`documentos.${index}`, {
                                                        id: 2,
                                                        nome: name,
                                                        tipoImagemID: '6C439340-C0FB-491A-8AAE-93E9C89925C1',
                                                        base64,
                                                      })
                                                    }
                                                  /> */}
                                                </ContainerFile>
                                              </div>
                                              <div style={{ maxHeight: '25px', margin: '0' }}>
                                                <Minus type="button" onClick={() => {
                                                  values.documentos.splice(index, 1)
                                                  setDocumentoTipo2(documentoTipo2 + 1);
                                                }}>
                                                  <img src={removeIcon} alt="Remover" />
                                                </Minus>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      } else {
                                        return '';
                                      }
                                    })
                                  }
                                </>)}
                              </FieldArray>
                            </Container>
                            {vendaID && (
                              <Container
                                title="Observação"
                                noBorder
                                fullHeight={false}
                              >
                                <Divider />
                                <FormTitle>
                                  Descreva quais alterações foram feitas
                                </FormTitle>
                                <TextInput placeholder="" name="descricao" />
                              </Container>
                            )}

                            {plano && (
                              <Container>
                                <PlanCard
                                  plan={plano}
                                  submitButtonText="Salvar Cadastro"
                                  buttonProps={{
                                    loading,
                                    type: 'submit',
                                  }}
                                />
                              </Container>
                            )}
                          </FullPageFlexSection>
                        </>
                      )}
                    </FieldArray>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Main>
        </div>
      </div>
    </>
  );
};

export default CadastroPlano;
