import { parse, isDate} from "date-fns";

export function parseDateString(_, originalValue) {
  // console.log(originalValue)

  if(!originalValue?.length){
    // console.log("")
    return null
  }
  const parsedDate = isDate(originalValue.substr(0, 10))
    ? originalValue.substr(0, 10)
    : parse(originalValue.substr(0, 10), "dd/MM/yyyy", new Date());
  // console.log(parsedDate)
  return parsedDate;
}
