import React from 'react';
import styled from 'styled-components';

const Pagination = ({
  anterior,
  anteriorOnClick,
  proximo,
  proximoOnClick,
  ...props
}) => (
  <Container>
    {anterior && (
      <Button anterior={anterior} onClick={anteriorOnClick} {...props}>
        Anterior
      </Button>
    )}

    {proximo && (
      <Button proximo={proximo} onClick={proximoOnClick} {...props}>
        Próximo
      </Button>
    )}
  </Container>
);

export default Pagination;

const Button = styled.button`
  font-style: 'SegoeUI';
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green};
  border: 0;
  background-color: transparent;
  & + & {
    margin-left: 200px;
  }
`;

const Container = styled.div`
  max-width: 380px; 
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
