import React from 'react';
import FullPageFlexSection from '../Sections/FullPageFlexSection';
import Container from '../Containers/Container';
import FormTitle from '../Form/FormTitle';
import { ResumoStyled } from './styles';

export default function Resumo(props) {
    return (
        <>
            <ResumoStyled>
                <FullPageFlexSection>
                    <Container title='Dados do Plano'>
                        <h3 style={{ margin: '5px 10px' }}>Titular</h3>
                        <div className="dividir">
                            <div className="campoDado">
                                <FormTitle>
                                    Nome
                                    <h3>
                                        {props.venda ? props.venda.contatos[0].nome : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div className="campoDado">
                                <FormTitle>
                                    Nº Cartão Nacional de Saúde
                                    <h3>
                                        {props.venda ? props.venda.beneficiarios[0].cns : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div className="campoDado">
                                <FormTitle>
                                    CPF
                                    <h3>
                                        {props.venda ? props.venda.beneficiarios[0].cpf : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div className="campoDado">
                                <FormTitle>
                                    Filiação Materna
                                    <h3>
                                        {props.venda ? props.venda.beneficiarios[0].filiacao : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div className="campoDado">
                                <FormTitle>
                                    Sexo
                                    <h3>
                                        {props.venda ? props.venda.beneficiarios[0].sexo : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div className="campoDado">
                                <FormTitle>
                                    Data Nascimento
                                    <h3>
                                        {props.venda ? props.venda.beneficiarios[0].dataNascimento : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div className="campoDado">
                                <FormTitle>
                                    CEP
                                    <h3>
                                        {props.venda ? props.venda.enderecos[0].CEP : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div className="campoDado">
                                <FormTitle>
                                    Telefone
                                    <h3>
                                        {props.venda ? props.venda.contatos[0].telefone : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div>
                                <FormTitle>
                                    E-mail
                                    <h3>
                                        {props.venda ? props.venda.contatos[0].email : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div>
                                <FormTitle>
                                </FormTitle>
                            </div>
                        </div>
                        <h3 style={{ margin: '5px 10px' }}>Plano</h3>
                        <div className="dividir" >
                            <div>
                                <FormTitle>
                                    Operadora
                                    <h3>
                                        {props.produto ? props.produto.operadora : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div className="campoDado">
                                <FormTitle>
                                    Forma de cobrança
                                    <h3>
                                        {props.venda ? props.venda.contratoCobranca : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div>
                                <FormTitle>
                                    Data de vigência
                                    <h3>
                                        {props.venda ? (props.venda.dataVigencia) : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div>
                                <FormTitle>
                                    Dia Vencimento
                                    <h3>
                                        {props.venda ? props.venda.diaVencimento : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div>
                                <FormTitle>
                                    Valor
                                    <h3>
                                        {props.produto ? (`R$ ${props.produto.valorTotal}`) : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div>
                                <FormTitle>
                                    Plano
                                    <h3>
                                        {props.produto ? props.produto.nome : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div>
                                <FormTitle>
                                    Segmentação
                                    <h3>
                                        {props.produto ? props.produto.segmentacao : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div >
                                <FormTitle>
                                    Acomodação
                                    <h3>
                                        {props.produto ? props.produto.acomodacao : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div >
                                <FormTitle>
                                    Abrangência
                                    <h3>
                                        {props.produto ? props.produto.abrangencia : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                            <div>
                                <FormTitle>
                                    Cobertura
                                    <h3>
                                        {props.produto ? props.produto.tipoCoparticipacao : ''}
                                    </h3>
                                </FormTitle>
                            </div>
                        </div>
                        <div>
                            {
                                Object.values(props.beneficiarios).map(element => {
                                    if (element.beneficiario.titular === false) {
                                        return (
                                            <>
                                                <h3 style={{ margin: '5px 10px' }}>Dependente</h3>
                                                <div className="dividir">
                                                    <div >
                                                        <FormTitle>
                                                            Nome
                                                            <h3>
                                                                {element.beneficiario.nome}
                                                            </h3>
                                                        </FormTitle>
                                                    </div>
                                                    <div >
                                                        <FormTitle>
                                                            Nº Cartão Nacional de Saúde
                                                            <h3>
                                                                {element.beneficiario.cns}
                                                            </h3>
                                                        </FormTitle>
                                                    </div>
                                                    <div >
                                                        <FormTitle>
                                                            CPF
                                                            <h3>
                                                                {element.beneficiario.cpf}
                                                            </h3>
                                                        </FormTitle>
                                                    </div>
                                                    <div>
                                                        <FormTitle>
                                                            Sexo
                                                            <h3>
                                                                {element.beneficiario.sexo}
                                                            </h3>
                                                        </FormTitle>
                                                    </div>
                                                    <div>
                                                        <FormTitle>
                                                            Dependência
                                                            <h3>
                                                                {element.beneficiario.tipoDependencia}
                                                            </h3>
                                                        </FormTitle>
                                                    </div>

                                                    <div>
                                                        <FormTitle>
                                                            Data de nascimento
                                                            <h3>
                                                                {element.beneficiario.dataNascimento}
                                                            </h3>
                                                        </FormTitle>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    } else {
                                        return ''
                                    }
                                })
                            }
                        </div>

                    </Container>
                </FullPageFlexSection>
            </ResumoStyled>
        </>

    )
}

