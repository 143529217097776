import styled from 'styled-components';

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 50px;
  @media (max-width: ${({ theme }) => theme.mobile}){
    display:none;
  }
`;

export const Main = styled.main`
  padding: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}){
    padding: 10px;
    .mobile {
      padding: 0px 0px 0px 10px;
      display: block !important;
      margin-bottom: -70px;
    }
  }
  background-color: ${({ theme }) => theme.colors.backgroundWhite};  
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
