import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formPost } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Button from '../../Buttons/Button';
import Loading from '../Loading';
import EmptyTable from '../EmptyTable';

const TableApprove = () => {
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const { addToast } = useToast();
  const [dadoEquipe, setDadoEquipe] = useState(sessionStorage.getItem('dadoEquipe'));


  const submit = async (vendaID, index) => {
    setLoading((l) => {
      const arr = [...l];
      arr[index] = true;
      return arr;
    });

    try {
      await formPost('/PortalCorretor/AprovarVenda', {
        vendaID,
      });
      addToast({
        title: 'Venda aprovada',
        type: 'success',
      });
    } catch (err) {
      addToast({
        title: 'Erro ao aprovar venda, tente novamente',
        type: 'error',
      });
    } finally {
      setLoading((l) => {
        const arr = [...l];
        arr[index] = false;
        return arr;
      });
    }
  };

  const auxEquipe = sessionStorage.getItem('dadoEquipe');
  useEffect(() => {
    setDadoEquipe(sessionStorage.getItem('dadoEquipe'))
  }, [auxEquipe, dadoEquipe, setDadoEquipe])

  useEffect(() => {
    if (user.corretora) {
      formPost('/PortalCorretor/ListarVendaAceita', {
        filtros: 'corretoraID.toString() == "' + user.corretoraID + '"',
        paginacao: -1,
        pagina: 0,
      })
        .then((response) => {
          setData(response.data.dados.lista);
          setLoading(new Array(response.data.dados.lista.length).fill(false));
        })
        .catch(() => {
          addToast({
            title: 'Erro ao buscar lista de vendas, tente novamente',
            type: 'error',
          });
        })
        .finally(() => {
          setLoadingTable(false);
        });
    } else {
      formPost('/PortalCorretor/ListarVendaAceita', {
        filtros: 'produtorID.toString() == "' + user.produtorID + '"',
        paginacao: -1,
        pagina: 0,
      })
        .then((response) => {
          setData(response.data.dados.lista);
          setLoading(new Array(response.data.dados.lista.length).fill(false));
        })
        .catch(() => {
          addToast({
            title: 'Erro ao buscar lista de vendas, tente novamente',
            type: 'error',
          });
        })
        .finally(() => {
          setLoadingTable(false);
        });
    }
  }, [setData, setLoadingTable]);
  console.log(data)

  if (loadingTable) {
    return <Loading />;
  }

  if (data.length === 0) {
    return <EmptyTable />;
  }

  return (
    <TableContainer>
      <thead>
        <tr>
          <th>Criação</th>
          <th>Vigência</th>
          <th>Aceita</th>
          <th>Beneficiário</th>
          <th>Produto</th>
          <th>Corretora</th>
          {/* <th>Documentos</th> */}
          {/* <th>Aprovar</th> */}
        </tr>
      </thead>
      <tbody>
        {data.map(
          (
            {
              vendaID,
              corretora,
              titular,
              dataCadastro,
              produto,
              dataVigencia,
              dataAceite,
              equipeVendasID,
            },
            index,
          ) => {
            if (!user.corretora && (equipeVendasID === dadoEquipe)) {
              return (
                <tr key={vendaID}>
                  <td>{dataCadastro}</td>
                  <td>{dataVigencia}</td>
                  <td>{dataAceite}</td>
                  <td>{titular}</td>
                  <td>{produto}</td>
                  <td>{corretora}</td>
                  {/* <td>
                <Button small>Documentos</Button>
              </td> */}
                  {/* <td>
                <Button
                  onClick={() => submit(vendaID, index)}
                  loading={loading[index]}
                  small
                  alternativeButton
                >
                  Aprovar
                </Button>
              </td> */}
                </tr>)
            } else if (user.corretora) {
              return (
                <tr key={vendaID}>
                  <td>{dataCadastro}</td>
                  <td>{dataVigencia}</td>
                  <td>{dataAceite}</td>
                  <td>{titular}</td>
                  <td>{produto}</td>
                  <td>{corretora}</td>
                  {/* <td>
                <Button small>Documentos</Button>
              </td> */}
                  {/* <td>
                <Button
                  onClick={() => submit(vendaID, index)}
                  loading={loading[index]}
                  small
                  alternativeButton
                >
                  Aprovar
                </Button>
              </td> */}
                </tr>)
            } else {
              return ''
            }
          },
        )}
      </tbody>
    </TableContainer>
  );
};

const TableContainer = styled.table`
  margin: 15px 0;
  border: 0;
  width: 100%;

  th {
    font-size: 16px;
    font-family: 'SegoeUI';
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }

  td {
    font-size: 14px;
    padding: 5px;
    font-family: 'SegoeUI';
    font-weight: normal;
    color: ${({ theme }) => theme.colors.blue};
    vertical-align: middle;
    text-align: center;
    * {
      margin: auto;
    }
  }
`;

export default TableApprove;
