import styled from 'styled-components';

export const Container = styled.h1`
  font-size: 16px !important;
  font-family: 'Exo';
  font-weight: bold;
  margin-bottom: 0px !important;
  color: ${({ theme }) => theme.colors.blue};
  ${({ theme, noBorder }) => !noBorder && `border-bottom: 3px solid ${theme.colors.green}`};
  width: ${({ center }) => center ? '100%' : 'fit-content'};
  text-align: ${({ center }) => center ? 'center' : 'initial'};
`;
