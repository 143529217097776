import styled from 'styled-components';

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 50px;
  @media (max-width: ${({ theme }) => theme.mobile}){
    display:none;
  }
`;

export const Main = styled.main`
  padding: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}){
    padding: 10px;
  }
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  .breadcrumb {
    width: 100%;
    padding: 0px 16px;
    margin: -5px;
    list-style: none;
    li {
      display: inline;
      font-size: 15px;
    }
    li a {
      color: ${({ theme }) => theme.colors.gray};
      text-decoration: none;
    }
    li+li:before {
      color:  ${({ theme }) => theme.colors.blue};
      padding: 8px;
      font-weight: bold;
      content: "/";
    }
    li a:hover {
      color: #01447e;
      text-decoration: underline;
    }

  }  
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &>div{
    padding: 0 10px;
    width: 50%;
  }
`;

export const MessageContainer = styled.p`
  padding: 35px;
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  border-radius: 30px;
`;
