import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StyledMenu } from './styles';
import MenuItem from './MenuItem';
import barcode from '../../assets/images/barcode.png';
import SelectSidebar from '../Inputs/Select/SelectSidebar';
import { useAuth } from '../../hooks/auth';


const Menu = ({ open, ...props }) => {
  const isHidden = !!open;
  const history = useHistory();
  const { user } = useAuth();
  const [dadoEquipe, setDadoEquipe] = useState();

  useEffect(() => {
    setDadoEquipe(sessionStorage.getItem('dadoEquipe'))
  }, [dadoEquipe, setDadoEquipe]);
  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <MenuItem ></MenuItem>
      {!user?.corretora &&
        <MenuItem selected>
          Corretora
          <SelectSidebar style={{ marginTop: '5px', width: '600px', padding: '0px 200px 0px 180px', color: 'black' }}
            placeholderDefault
            onChange={(e) => {
              sessionStorage.setItem('dadoEquipe', e.target.value);
              setDadoEquipe(e.target.value);
            }}
            value={sessionStorage.getItem('dadoEquipe')}
          >
            {user?.equipes.map(equipes => (
              <option
                style={{ color: 'black', marginLeft: '-100px' }}
                value={equipes.equipeVendasID}
              >
                {equipes.corretora}
              </option>
            ),
            )}
          </SelectSidebar>
        </MenuItem>
      }
      <MenuItem
        style={{ position: 'relative', top: '', bottom: '0', left: '0', right: ' 0', margin: 'auto' }}
        onClick={() => history.push('/cadastro')}>
        Cotação
      </MenuItem>
      {/* <MenuItem onClick={() => history.push('/cadastro')}>Cadastro de Beneficiário</MenuItem> */}
      <MenuItem onClick={() => history.push('/cadastro-envio')}>Status da Proposta</MenuItem>
      <MenuItem onClick={() => history.push('/vendas-confirmadas')}>Vendas Confirmadas</MenuItem>
      {/* <MenuItem hasIcon icon={barcode} iconText="2ª Via do Boleto" /> */}
    </StyledMenu>
  );
};

export default Menu;

