import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderDeclaracao from '../../components/Headers/HeaderDeclaracao';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import Container from '../../components/Containers/Container';
import RadioButton from '../../components/Inputs/RadioButton';
import Pagination from '../../components/Pagination';
import api, { formPost } from '../../services/api';
import { Main, Flex } from './styles';
import Resumo from '../../components/ResumoVenda';

const Declaracao = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const vendaID = query.get('vendaID');
  const [radioValue, setRadio] = useState(false);
  const [venda, setVenda] = useState();
  const [produto, setProduto] = useState();
  const [dados, setDados] = useState();

  useEffect(() => {
    formPost('/PortalCorretor/SelecionarVenda', {
      vendaID,
    }).then(({ data }) => {
      setDados(data);
      setVenda(data.venda);
      setProduto(data.produto);
    })
  }, [vendaID, setDados, setVenda, setProduto]);

  const beneficiarios = (venda ? (
    venda.beneficiarios.map((b) => {
      const parsedData = {
        ...b,
      };
      return {
        beneficiario: parsedData,
      };
    })
  ) : (''))

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ width: '100%' }}>
          <HeaderDeclaracao />
          <Main>

            {dados ? (
              <Resumo venda={venda} produto={produto} beneficiarios={beneficiarios} />
            ) : ''}

            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Declaração de saúde">
                <Flex>
                  <p>
                    <span>1.</span> No preenchimento desta declaração, V. Sa.
                    tem a opção de ser orientado (a), sem ônus financeiro por um
                    médico indicado pela operadora ou por um profissional de sua
                    confiança, caso em que as despesas com honorários serão de
                    sua responsabilidade.
                  </p>
                  <p>
                    <span>2.</span> A <b>Declaração de Saúde</b> tem por
                    objetivo registrar a existência de doenças ou lesões
                    preexistentes, como sendo aquelas de que V. Sa. tenha
                    conhecimento, no momento da assinatura desta Solicitação de
                    Adesão, com relação a si próprio ou a qualquer de seus
                    dependentes.
                  </p>
                  <p>
                    <span>3.</span> Havendo declaração de doença ou lesão
                    preexistente é oferecida, pela operadora, a seguinte opção:
                    Cobertura Parcial Temporária: na qual o beneficiário não
                    terá direito aos Procedimentos de Alta Complexidade (PAC),
                    cirúrgicos ou leitos de alta tecnologia relacionados à
                    doença ou lesão declarada, por um período máximo de 24
                    (vinte e quatro) meses.
                  </p>
                  <p>
                    <span>4.</span> Nenhuma cobertura poderá ser negada ao
                    consumidor para doenças ou lesões preexistentes não
                    declaradas até que a operadora apresente as provas concretas
                    à <b>ANS</b> de que o consumidor omitiu a doença ou lesão no
                    preenchimento desta declaração. Até a decisão da <b>ANS</b>,
                    não haverá suspensão do benefício nem do atendimento. 5. A
                    omissão de informação sobre a existência de doença ou lesão
                    preexistente, da qual o consumidor saiba ser portador no
                    momento do reenchimento desta Declaração, desde que
                    comprovada pela <b>ANS</b>, poderá acarretar a suspensão ou
                    o cancelamento do contrato. Nesse caso, V. Sa. será
                    responsável pelo pagamento das despesas realizadas como
                    tratamento da doença ou lesão omitida, a partir da data em
                    que tiver sido recebido o comunicado ou a notificação da
                    operadora, alegando a existência de doença ou lesão
                    preexistente não declarada.
                  </p>
                  <div>
                    <RadioButton
                      label="li e estou ciente"
                      name="radio"
                      value={radioValue}
                      checked={radioValue}
                      onChange={() => {
                        setRadio((s) => !s);
                      }}
                    />
                    {radioValue && (
                      <Pagination
                        proximo
                        proximoOnClick={() =>
                          history.push('/declaracao-form', {
                            vendaID,
                          })
                        }
                      />
                    )}
                  </div>
                </Flex>
              </Container>
            </FullPageFlexSection>
          </Main>
        </div>
      </div>
    </>
  );
};

export default Declaracao;
