import styled from 'styled-components';
import { Field } from 'formik';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
  box-shadow: 0.5px 1px #bab8b8;
  width: 90%;
  border: 0;
  background-color: ${({ theme }) => theme.colors.backgroundWhite};
  border-radius: 10px;
  img{
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
    right: 0;
    margin-right: 10px;
    height: 6px;
    z-index:1;
  }
  @media (max-width: ${({ theme }) => theme.mobile}){
    width: 95% !important;
  }

`;

export const SelectStyle = styled(Field)`
  width: 100%;
  height: 100%;

  border: 0;
  color: ${({ theme }) => theme.colors.blue};
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  padding: 0 60px 0px 30px;
  z-index: 2;
  background-color: transparent;
  cursor: pointer;

  &, &::placeholder{
    font-family: 'SegoeUI';
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Error = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding: 2px 0px 5px 20px;
  height: 16px;
  width: 100%;
  margin-bottom: 20px;
`;
