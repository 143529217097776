import React from 'react';
import { Container, Section } from './styles';

const FullPageFlexSection = ({
  title,
  darkBackground = false,
  children,
  justifyContent,
  ...props
}) => {
  return (
    <Section {...props}>
      {title && (
        <>
          <h1>{title}</h1>
          <div />
        </>
      )}
      <Container justifyContent={justifyContent} dark={darkBackground}>
        <div>{children}</div>
      </Container>
    </Section>
  );
};

export default FullPageFlexSection;
