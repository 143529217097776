import React from 'react';
// import { useHistory } from 'react-router-dom';
import {
  Container,
  FirstContainer,
  Bar,
  UserArea,
  TabArea,
  Tab,
  SearchContainer,
} from './styles';
import HeaderMobile from '../HeaderMobile';

const HeaderPortal = () => {
  // const history = useHistory();

  return (
    <>
      <HeaderMobile />
      <Container>
        <FirstContainer>
          <Bar>
            <span>Declaração de saúde</span>
            <SearchContainer />
            <UserArea />
          </Bar>
          <TabArea>
            <Tab selected>Declaração saúde</Tab>
          </TabArea>
        </FirstContainer>
      </Container>
    </>
  );
};

export default HeaderPortal;
