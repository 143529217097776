import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderPortal from '../../components/Headers/HeaderPortal';
import FormTitle from '../../components/Form/FormTitle';
import Select from '../../components/Inputs/Select';
import DateInput from '../../components/Inputs/DateInput';
import Button from '../../components/Buttons/Button';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import Container from '../../components/Containers/Container';
import { Main, Flex, ButtonContainer, Divider } from './styles';
import { requestForm } from './requests';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import Loading from '../../components/Tables/Loading';
import api from '../../services/api';
import { parseDateString } from '../../utils/parser';
import SplitInputs from '../../components/Inputs/SplitInputs';
import PlanCard from '../../components/Cards/PlanCard';
import PlanCardSelect from '../../components/Cards/PlanCard/PlanCardSelect';
import ContainerTitle from '../../components/Containers/ContainerTitle';
import ContainerSubtitle from '../../components/Containers/ContainerSubtitle';
import SelectSidebar from '../../components/Inputs/Select/SelectSidebar';

const today = new Date();

const CadastroSchema = Yup.object().shape({
  beneficiarios: Yup.array().of(
    Yup.object().shape({
      dataNascimento: Yup.date()
        .typeError('Data inválida')
        .max(today, 'Data inválida')
        .transform(parseDateString)
        .required('Obrigatório'),
    }),
  ),
  abrangenciaID: Yup.string().required('Campo obrigatório'),
  acomodacaoID: Yup.string().required('Campo obrigatório'),
  empresaID: Yup.string().required('Campo obrigatório'),
  grupoRegionalID: Yup.string().required('Campo obrigatório'),
  profissaoID: Yup.string().required('Campo obrigatório'),
  segmentacaoAssistencialID: Yup.string().required('Campo obrigatório'),
  tipoCoparticipacaoID: Yup.string().required('Campo obrigatório'),
});

const SalesPortal = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [loadingPage, setLoadingPage] = useState(true);
  const [form, setForm] = useState({
    abrangencia: [],
    acomodacao: [],
    empresa: [],
    grupoRegional: [],
    profissao: [],
    segmentacaoAssistencial: [],
    tipoCoparticipacao: [],
  });
  const [planos, setPlanos] = useState(false);
  const { state } = useLocation();
  const [plans, setPlans] = useState([]);
  const [cotacaoID, setCotacaoID] = useState('');
  const [quantidade, setQuantidade] = useState(0);
  const [selected, setSelected] = useState(
    plans[0] ? plans[0] : null,
  );
  const [calculo, setCalculo] = useState(false);
  const [dadoEquipe, setDadoEquipe] = useState(sessionStorage.getItem('dadoEquipe'));

  useEffect(() => {
  }, [dadoEquipe, setDadoEquipe]);

  useEffect(() => {
  }, [plans, setPlans]);
  useEffect(() => {
    requestForm()
      .then((response) => {
        setForm(response);
        setLoadingPage(false);
      })
      .catch(() =>
        addToast({
          title: 'Falha ao carregar dados, tente novamente',
          type: 'error',
        }),
      );
  }, []);

  const initialValuesProp = useMemo(() => {
    const dataForm = sessionStorage.getItem('data');
    const initial = dataForm ? JSON.parse(dataForm) : {
      beneficiarios: [
        {
          dataNascimento: '',
          titular: true,
        },
      ],
      abrangenciaID: '',
      acomodacaoID: '',
      empresaID: '',
      grupoRegionalID: '',
      profissaoID: '',
      segmentacaoAssistencialID: '',
      tipoCoparticipacaoID: '',
    };
    return initial;
  }, []);


  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar>
          {!user.corretora &&
            <SidebarList title="Corretoras">
              <SelectSidebar
                onChange={(e) => {
                  sessionStorage.setItem('dadoEquipe', e.target.value);
                  setDadoEquipe(e.target.value);
                }}
                value={
                  sessionStorage.getItem('dadoEquipe')}
              >
                {user.equipes.map(equipes => (
                  <option
                    value={
                      (equipes.equipeVendasID)
                    }
                  >
                    {(equipes.corretora)}
                  </option>
                ),
                )}
              </SelectSidebar>
            </SidebarList>}
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
              Cotação
            </SidebarListItem>}
          </SidebarList>
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          <Main>
            <ul className="breadcrumb">
              <li><a href="/portal"><span>Home</span></a></li>
              <li><span>Cotação</span></li>
            </ul>
            {loadingPage ? (
              <Loading />
            ) : (
              <Formik
                enableReinitialize
                validationSchema={CadastroSchema}
                initialValues={initialValuesProp}
                onSubmit={(values) => {
                  setLoading(true);
                  try {
                    const data = values;
                    data.produtorID = user.produtorID;
                    data.equipeVendasID = dadoEquipe;
                    const props = Object.keys(data);
                    console.log(data)
                    sessionStorage.setItem('data', JSON.stringify(data));
                    api
                      .post('/PortalCorretor/CotarColetivoAdesao', data, {
                        'Content-Type': 'application/json',
                      })
                      .then((response) => {
                        if (response.data.dados.lista) {
                          setPlans(response.data.dados.lista);
                          setCotacaoID(response.data.dados.cotacaoID);
                          setQuantidade(values.beneficiarios.length);
                          setPlanos(true);
                        } else {
                          throw new Error(response.data.mensagem);
                        }
                      })
                      .catch(() => {
                        setLoading(false);
                        addToast({ type: 'error', title: 'error' });
                      });
                  } catch (err) {
                    setLoading(false);
                    addToast({ type: 'error', title: 'error' });
                  }
                }}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <Flex>
                      <FullPageFlexSection>
                        <Container
                          title="Cotação"
                          subtitle="Dados do beneficiário"
                          style={{ height: '100%', flex: 1 }}
                          noBorder
                        >
                          <Divider />
                          <SplitInputs>
                            <div>
                              <FormTitle>
                                Data de nascimento
                              </FormTitle>
                              <FieldArray name="beneficiarios" id="beneficiarios">
                                {({ push, remove }) => (
                                  <>
                                    {values.beneficiarios.length > 0 &&
                                      values.beneficiarios.map(
                                        ({ dataNascimento }, index, arr) => (
                                          <DateInput
                                            key={
                                              index === 0
                                                ? 'Titular'
                                                : `Dependente ${index + 1}`
                                            }
                                            placeholder={
                                              index === 0
                                                ? 'Titular'
                                                : `Dependente ${index + 1}`
                                            }
                                            plus={index + 1 === arr.length}
                                            onPlus={() =>
                                              push({ dataNascimento: '' })
                                            }
                                            minus={index > 0}
                                            onMinus={() => remove(index)}
                                            name={`beneficiarios.${index}.dataNascimento`}
                                            id={`beneficiarios.${index}.dataNascimento`}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `beneficiarios.${index}.dataNascimento`,
                                                e,
                                              );
                                              setFieldValue(
                                                `beneficiarios.${index}.titular`,
                                                index === 0,
                                              );
                                            }}
                                            value={dataNascimento}
                                            maxDate={new Date(Date.now())}
                                          />
                                        ),
                                      )}
                                  </>
                                )}
                              </FieldArray>
                            </div>
                            <div>
                              <FormTitle>Região do Beneficiário</FormTitle>
                              <Select
                                placeholder="Unidade Federativa"
                                name="grupoRegionalID"
                                id="grupoRegionalID"
                                onChange={(e) => {
                                  setFieldValue('grupoRegionalID', e.target.value);
                                }}
                                placeholderDefault
                                value={values.grupoRegionalID + ''}
                              >
                                {form.grupoRegional.map(
                                  ({ grupoRegionalID, nome }) => (
                                    <option
                                      key={grupoRegionalID}
                                      value={grupoRegionalID}
                                    >
                                      {nome}
                                    </option>
                                  ),
                                )}
                              </Select>
                            </div>
                          </SplitInputs>
                          <SplitInputs>
                            <div>
                              <FormTitle>Profissão do Beneficiário</FormTitle>
                              <Select
                                placeholder="Profissão"
                                name="profissaoID"
                                id="profissaoID"
                                onChange={(e) => {
                                  setFieldValue('profissaoID', e.target.value);
                                }}
                                placeholderDefault
                                value={values.profissaoID + ''}
                              >
                                {form.profissao.map(({ profissaoID, nome }) => (
                                  <option key={profissaoID} value={profissaoID}>
                                    {nome}
                                  </option>
                                ))}
                              </Select>
                            </div>
                            <div>
                              <FormTitle>Entidade de classe</FormTitle>
                              <Select
                                placeholder="Entidade"
                                name="empresaID"
                                id="empresaID"
                                onChange={(e) => {
                                  setFieldValue('empresaID', e.target.value);
                                }}
                                placeholderDefault
                                all
                                allText="Todas"
                                value={values.empresaID + ''}
                              >
                                {form.empresa.map(({ empresaID, nomeFantasia }) => (
                                  <option key={empresaID} value={empresaID}>
                                    {nomeFantasia}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </SplitInputs>
                        </Container>
                        <Container
                          title="Cotação"
                          subtitle="Dados do plano"
                          style={{ height: '100%', flex: 1 }}
                          noBorder
                        >
                          <Divider />
                          <SplitInputs>
                            <div>
                              <FormTitle>Abrangência</FormTitle>
                              <Select
                                placeholder="Abrangência"
                                name="abrangenciaID"
                                id="abrangenciaID"
                                onChange={(e) => {
                                  setFieldValue('abrangenciaID', e.target.value);
                                }}
                                placeholderDefault
                                all
                                allText="Todas"
                                value={values.abrangenciaID + ''}
                              >
                                {form.abrangencia.map(({ abrangenciaID, nome }) => (
                                  <option key={abrangenciaID} value={abrangenciaID}>
                                    {nome}
                                  </option>
                                ))}
                              </Select>
                            </div>
                            <div>
                              <FormTitle>Coparticipação</FormTitle>
                              <Select
                                placeholder="Coparticipação"
                                name="tipoCoparticipacaoID"
                                id="tipoCoparticipacaoID"
                                onChange={(e) => {
                                  setFieldValue(
                                    'tipoCoparticipacaoID',
                                    e.target.value,
                                  );
                                }}
                                placeholderDefault
                                all
                                allText="Todas"
                                value={values.tipoCoparticipacaoID + ''}
                              >
                                {form.tipoCoparticipacao.map(
                                  ({ tipoCoparticipacaoID, nome }) => (
                                    <option
                                      key={tipoCoparticipacaoID}
                                      value={tipoCoparticipacaoID}
                                    >
                                      {nome}
                                    </option>
                                  ),
                                )}
                              </Select>
                            </div>
                          </SplitInputs>
                          <SplitInputs>
                            <div>
                              <FormTitle>Cobertura</FormTitle>
                              <Select
                                placeholder="Cobertura"
                                name="segmentacaoAssistencialID"
                                id="segmentacaoAssistencialID"
                                onChange={(e) => {
                                  setFieldValue(
                                    'segmentacaoAssistencialID',
                                    e.target.value,
                                  );
                                }}
                                placeholderDefault
                                all
                                allText="Todas"
                                value={values.segmentacaoAssistencialID + ''}
                              >
                                {form.segmentacaoAssistencial.map(
                                  ({ segmentacaoAssistencialID, nome }) => (
                                    <option
                                      key={segmentacaoAssistencialID}
                                      value={segmentacaoAssistencialID}
                                    >
                                      {nome}
                                    </option>
                                  ),
                                )}
                              </Select>
                            </div>
                            <div>
                              <FormTitle>Acomodação</FormTitle>
                              <Select
                                placeholder="Acomodação"
                                name="acomodacaoID"
                                id="acomodacaoID"
                                onChange={(e) => {
                                  setFieldValue('acomodacaoID', e.target.value);
                                }}
                                placeholderDefault
                                all
                                allText="Todas"
                                value={values.acomodacaoID + ''}
                              >
                                {form.acomodacao.map(({ acomodacaoID, nome }) => (
                                  <option key={acomodacaoID} value={acomodacaoID}>
                                    {nome}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </SplitInputs>
                        </Container>
                      </FullPageFlexSection>
                    </Flex>
                    <ButtonContainer>
                      <Button
                        type="submit"
                        onClick={() => {
                          setCalculo(true);
                        }}
                      >
                        Calcular
                      </Button>
                    </ButtonContainer>
                  </Form>
                )}
              </Formik>
            )}
            {calculo ?
              <Flex style={{ alignItems: 'flex-start', marginTop: '25px' }}>
                <div
                  style={{
                    width: '100%',
                    flex: 1,
                    justifyContent: 'flex-start',
                    padding: '0px 20px',
                  }}
                >
                  <ContainerTitle
                    noBorder
                    style={{
                      marginBottom: '50px',
                    }}
                  >
                    Cotação
                    <ContainerSubtitle>Opções disponíveis</ContainerSubtitle>
                  </ContainerTitle>
                  <PlanCardSelect>
                    {plans.map((val) => (
                      <button
                        type="button"
                        className={
                          selected &&
                            val.contratoProdutoID === selected.contratoProdutoID
                            ? 'selected'
                            : 'opcao'
                        }
                        onClick={() => {
                          setSelected(val);
                        }}
                        key={val.contratoProdutoID}
                      >
                        {val.nome}
                      </button>
                    ))}
                  </PlanCardSelect>
                </div>
                <FullPageFlexSection style={{ flex: 1 }}>
                  <Container style={{ marginTop: '-10px' }} >
                    {plans.length === 0 ? (<PlanCard empty />) : (
                      selected ? (
                        <PlanCard
                          name={`Plano [ ${selected.nome} | ${selected.tipo} )`}
                          price={`R$ ${selected.valorTotal.toFixed(2)} / mês`}
                          plan={selected}
                          empty={plans.length === 0}
                          submit={() => {
                            // setDadoEquipe(sessionStorage.getItem('dadoEquipe'))
                            history.push('cadastro-plano', {
                              plan: selected,
                              cotacaoID,
                              quantidade,
                              // dadoEquipe: sessionStorage.getItem('dadoEquipe'),
                              ...state,
                            });
                          }
                          }
                        />
                      ) : (
                        <PlanCard noSelected />
                      )
                    )}
                  </Container>
                </FullPageFlexSection>
              </Flex>
              : null}
          </Main>
        </div>
      </div>
    </>
  );
};

export default SalesPortal;
