import React from 'react';
import { Container } from './styles';

const ContainerTitle = ({ noBorder = false, center, children, ...props }) => (
  <Container noBorder={noBorder} center={center} {...props}>
    {children}
  </Container>
);

export default ContainerTitle;
