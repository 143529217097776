import {formPost} from '../../services/api';

export const  ListarAbrangencia = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarAbrangencia',form)
  return response.data.dados.lista;
}

export const  ListarAcomodacao = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarAcomodacao',form)
  return response.data.dados.lista;
}

export const  ListarEmpresa = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarEmpresa',form)
  return response.data.dados.lista;
}

export const  ListarGrupoRegional = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarGrupoRegional',form)
  return response.data.dados.lista;
}

export const  ListarProfissao = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarProfissao',form)

  return response.data.dados.lista;
}

export const  ListarTipoDependencia = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarTipoDependencia',form)

  return response.data.dados.lista;
}

export const  ListarTipoEstadoCivil = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarTipoEstadoCivil',form)

  return response.data.dados.lista;
}

export const  ListarSegmentacaoAssistencial = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarSegmentacaoAssistencial',form)

  return response.data.dados.lista;
}

export const  ListarTipoCoparticipacao = async () => {
  const form = {
    paginacao : -1,
    pagina : 0,
  }

  const response = await formPost('/PortalCorretor/ListarTipoCoparticipacao',form)

  return response.data.dados.lista;
}

export const requestForm = async () => {
  return {
    abrangencia: await ListarAbrangencia(),
    acomodacao: await ListarAcomodacao(),
    empresa: await ListarEmpresa(),
    grupoRegional: await ListarGrupoRegional(),
    profissao: await ListarProfissao(),
    segmentacaoAssistencial: await ListarSegmentacaoAssistencial(),
    tipoCoparticipacao: await ListarTipoCoparticipacao(),
    tipoDependencia: await ListarTipoDependencia(),
    tipoEstadoCivil: await ListarTipoEstadoCivil()
  }
}
