import React from 'react';
import { ContainerStyle, Remove, Header } from './styles';
import ContainerTitle from '../ContainerTitle';
import ContainerSubtitle from '../ContainerSubtitle';
import removeIcon from '../../../assets/images/remove.png';

const Container = ({
  children,
  title,
  subtitle,
  noBorder = false,
  fullHeight = true,
  centerTitle = false,
  onRemove,
  ...props
}) => (
  <ContainerStyle fullHeight={fullHeight} {...props}>
    <Header>
      {title && (
        <ContainerTitle noBorder={noBorder} center={centerTitle}>
          {title}
          {subtitle && <ContainerSubtitle>{subtitle}</ContainerSubtitle>}
        </ContainerTitle>
      )}
      {onRemove && (
        <Remove type="button" onClick={onRemove}>
          <img src={removeIcon} alt="Adicionar" />
        </Remove>
      )}
    </Header>
    {children}
  </ContainerStyle>
);

export default Container;
