import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/images/logoLogin.png';

const Sidebar = ({ children }) => (
  <Aside>
    <LogoContainer>
      <img src={logo} alt="Logo da Addere" />
    </LogoContainer>
    <MenuContainer>{children}</MenuContainer>
  </Aside>
);

const Aside = styled.aside`
  display: fixed;
  height: 100vh;
  width: 260px;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
`;

const MenuContainer = styled.menu`
  width: 100%;
  justify-self: center;
`;

const LogoContainer = styled.div`
  height: 250px;
  img {
    width: 200px;
    padding: 30px 20px 10px 20px;
  }
`;

export default Sidebar;
