import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import HeaderPortal from '../../components/Headers/HeaderPortal';
// import TextInput from '../../components/Inputs/TextInput';
// import FormTitle from '../../components/Form/FormTitle';
// import SimpleButton from '../../components/Buttons/SimpleButton';
import TablePending from '../../components/Tables/TablePending';
import TableSend from '../../components/Tables/TableSend';
import TableApprove from '../../components/Tables/TableApprove';
import TableAnalyze from '../../components/Tables/TableAnalyze';

import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import SidebarList from '../../components/Sidebar/SidebarList';
import SidebarListItem from '../../components/Sidebar/SidebarList/SidebarListItem';
import Container from '../../components/Containers/Container';
import { Main } from './styles';
import SelectSidebar from '../../components/Inputs/Select/SelectSidebar';

const SalesPortal = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [dadoEquipe, setDadoEquipe] = useState();

  useEffect(() => {
  }, [dadoEquipe, setDadoEquipe]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar>
          {!user.corretora &&
            <SidebarList title="Corretoras">
              <SelectSidebar
                onChange={(e) => {
                  sessionStorage.setItem('dadoEquipe', e.target.value);
                  setDadoEquipe(e.target.value);
                }}
                value={
                  sessionStorage.getItem('dadoEquipe')}
              >
                {user.equipes.map(equipes => (
                  <option
                    value={equipes.equipeVendasID}
                  >
                    {(equipes.corretora)}
                  </option>
                ),
                )}
              </SelectSidebar>
            </SidebarList>}
          <SidebarList title="Movimentação">
            <SidebarListItem onClick={() => history.push('/portal')}>
              Home
            </SidebarListItem>
            {user.corretora && <SidebarListItem onClick={() => history.push('/corretores')}>
              Corretores
            </SidebarListItem>}
            {!user.corretora && <SidebarListItem onClick={() => history.push('/cadastro')}>
              Cotação
            </SidebarListItem>}
            {/* <SidebarListItem onClick={() => history.push('/cadastro-plano')}>
              Cadastro de Beneficiário
            </SidebarListItem> */}
          </SidebarList>
          {/* <SidebarList title="Material de Apoio">
            <SidebarListItem>Tabela de Preços</SidebarListItem>
            <SidebarListItem>Ficha Astra</SidebarListItem>
            <SidebarListItem>Declaração de Saúde</SidebarListItem>
          </SidebarList> */}
        </Sidebar>
        <div style={{ width: '100%' }}>
          <HeaderPortal />
          {/* <Header fullHeader /> */}
          <Main>
            <ul className="breadcrumb">
              <li><a href="/portal"><span>Home</span></a></li>
              <li><span>Status Proposta</span></li>
            </ul>
            {/* <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Enviar declaração de saúde">
                <Flex>
                  <div>
                    <p>
                      Envie o link para que o beneficiário preencha a
                      declaração. Acompanhe o status da proposta.Quando
                      preenchida você poderá encaminhar o documento para a
                      operadora.
                    </p>
                    <FormTitle style={{ marginTop: '10px' }}>
                      Destinatário
                    </FormTitle>
                    <TextInput placeholder="beneficiario@gmail.com" />
                  </div>
                  <div>
                    <FormTitle>Mensagem</FormTitle>
                    <MessageContainer>
                      Olá, [Nome do Corretor] seu consultor em saúde. Estou
                      auxiliando você no processo de adesão de plano de saúde
                      através da Addere Administradora. Clique no link:
                      addereadm.com/formulário E preencha sua declaração de
                      saúde online. Obrigada por escolher a Addere.
                    </MessageContainer>
                    <SimpleButton
                      style={{ marginLeft: 'auto' }}
                      onClick={() => history.push('/cadastro-finalizado')}
                    >
                      Enviar
                    </SimpleButton>
                  </div>
                </Flex>
              </Container>
            </FullPageFlexSection> */}
            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Vendas com pendência">
                <p>
                  Vendas que foram auditadas e possuem alguma não conformidade,
                  por favor revisar.
                </p>
                <TablePending />
              </Container>
            </FullPageFlexSection>
            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Vendas pendente de análise">
                <p>
                  Vendas com a declaração saúde respondida pendentes de análise
                </p>
                <TableAnalyze />
              </Container>
            </FullPageFlexSection>
            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Enviar declaração de saúde para o beneficiário">
                <p>
                  As declarações que ainda não foram preenchidas aparecem
                  listadas abaixo.
                </p>
                <TableSend />
              </Container>
            </FullPageFlexSection>
            {/* <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Aprovar venda">
                <p>
                  As declarações que já foram preenchidas aparecem listadas
                  abaixo. Envie e-mail automaticamente para a operadora ao
                  clicar em enviar.
                </p>
                <TableApprove />
              </Container>
            </FullPageFlexSection> */}
          </Main>
        </div>
      </div>
    </>
  );
};

export default SalesPortal;
