import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { formPost } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Button from '../../Buttons/Button';
import Loading from '../Loading';
import EmptyTable from '../EmptyTable';

const TablePending = () => {
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const { addToast } = useToast();
  const history = useHistory();
  const [dadoEquipe, setDadoEquipe] = useState(sessionStorage.getItem('dadoEquipe'));

  const submit = async (vendaID, index) => {
    setLoading((l) => {
      const arr = [...l];
      arr[index] = true;
      return arr;
    });
    history.push(`/cadastro-plano?vendaID=${vendaID}`);
  };

  const auxEquipe = sessionStorage.getItem('dadoEquipe');
  useEffect(() => {
    setDadoEquipe(sessionStorage.getItem('dadoEquipe'))
  }, [auxEquipe, dadoEquipe, setDadoEquipe])

  useEffect(() => {
    if (user.corretora) {
      formPost('/PortalCorretor/ListarVendaComPendencia', {
        filtros: 'corretoraID.toString() == "' + user.corretoraID + '"',
        paginacao: -1,
        pagina: 0,
      })
        .then((response) => {
          setData(response.data.dados.lista);
          setLoading(new Array(response.data.dados.lista.length).fill(false));
        })
        .catch(() => {
          addToast({
            title: 'Erro ao buscar lista de vendas, tente novamente',
            type: 'error',
          });
        })
        .finally(() => {
          setLoadingTable(false);
        });
    } else {
      formPost('/PortalCorretor/ListarVendaComPendencia', {
        filtros: 'produtorID.toString() == "' + user.produtorID + '"',
        paginacao: -1,
        pagina: 0,
      })
        .then((response) => {
          setData(response.data.dados.lista);
          setLoading(new Array(response.data.dados.lista.length).fill(false));
        })
        .catch(() => {
          addToast({
            title: 'Erro ao buscar lista de vendas, tente novamente',
            type: 'error',
          });
        })
        .finally(() => {
          setLoadingTable(false);
        });
    }

  }, [setData, setLoadingTable]);

  if (loadingTable) {
    return <Loading />;
  }

  if (data.length === 0) {
    return <EmptyTable />;
  }

  return (
    <TableContainer>
      <thead>
        <tr>
          <th>Criação</th>
          <th>Vigência</th>
          <th>Beneficiário</th>
          <th>Dias Validade</th>
          <th>Nº Proposta</th>
          <th>Produto</th>
          <th>Corretora</th>
          {/* <th>Documentos</th> */}
          <th>Editar</th>
        </tr>
      </thead>
      <tbody style={{ background: '#ffeac7' }}>
        {
          data.map(
            (
              {
                vendaID,
                corretora,
                corretoraID,
                titular,
                dataCadastro,
                produto,
                dataVigencia,
                dataAceite,
                diasValidade,
                numeroProposta,
                equipeVendasID,
              },
              index,
            ) => {
              if (!user.corretora && (equipeVendasID === dadoEquipe)) {
                return (
                  <tr key={vendaID} 
                      style={{ background: '#ff000073' }}
                    >
                    <td>{dataCadastro}</td>
                    <td>{dataVigencia}</td>
                    <td>{titular}</td>
                    <td>{diasValidade}</td>
                    <td>{numeroProposta}</td>
                    <td>{produto}</td>
                    <td>{corretora}</td>
                    {/* <td>
                <Button small>Documentos</Button>
              </td> */}
                    <td>
                      <Button
                        onClick={() => submit(vendaID, index)}
                        loading={loading[index]}
                        small
                        alternativeButton
                      >
                        Revisar
                      </Button>
                    </td>
                  </tr>)
              } else if (user.corretora) {
                return (
                  <tr key={vendaID} 
                      style={{ background: '#ff000073' }}
                    >
                    <td>{dataCadastro}</td>
                    <td>{dataVigencia}</td>
                    <td>{titular}</td>
                    <td>{diasValidade}</td>
                    <td>{numeroProposta}</td>
                    <td>{produto}</td>
                    <td>{corretora}</td>
                    {/* <td>
                <Button small>Documentos</Button>
              </td> */}
                    <td>
                      <Button
                        onClick={() => submit(vendaID, index)}
                        loading={loading[index]}
                        small
                        alternativeButton
                      >
                        Editar
                      </Button>
                    </td>
                  </tr>)
              } else {
                return '';
              }

            },
          )
        }
      </tbody>
    </TableContainer>
  );
};

const TableContainer = styled.table`
  margin: 15px 0;
  border: 0;
  width: 100%;

  th {
    font-size: 16px;
    font-family: 'SegoeUI';
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }

  td {
    font-size: 15px;
    padding: 5px;
    font-family: 'SegoeUI';
    font-weight: normal;
    color: ${({ theme }) => theme.colors.blue};
    vertical-align: middle;
    text-align: center;
    * {
      margin: auto;
    }
  }
`;

export default TablePending;
