import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { formPost } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Button from '../../Buttons/Button';
import Loading from '../Loading';
import EmptyTable from '../EmptyTable';

const TableSend = () => {
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const { addToast } = useToast();
  const history = useHistory();
  const [dadoEquipe, setDadoEquipe] = useState(sessionStorage.getItem('dadoEquipe'));

  const submit = async (tipo, vendaID, index) => {
    if (tipo === 'enviar') {
      setLoading((l) => {
        const arr = [...l];
        arr[index] = true;
        return arr;
      });
      try {
        await formPost('/PortalCorretor/EnviarEmailCadastroBeneficiario', {
          vendaID,
        });
        addToast({
          title: 'Email enviado!',
          type: 'success',
        });
      } catch (err) {
        addToast({
          title: 'Erro ao enviar email, tente novamente',
          type: 'error',
        });
      } finally {
        setLoading((l) => {
          const arr = [...l];
          arr[index] = false;
          return arr;
        });
      }
    } else if (tipo === 'editar') {
      setLoading((l) => {
        const arr = [...l];
        arr[index] = true;
        return arr;
      });
      history.push(`/cadastro-plano?vendaID=${vendaID}`);
    }

  };

  const auxEquipe = sessionStorage.getItem('dadoEquipe');
  useEffect(() => {
    setDadoEquipe(sessionStorage.getItem('dadoEquipe'))
  }, [auxEquipe, dadoEquipe, setDadoEquipe])

  useEffect(() => {
    if (user.corretora) {
      formPost('/PortalCorretor/ListarVendaAAceitar', {
        filtros: 'corretoraID.toString() == "' + user.corretoraID + '"',
        paginacao: -1,
        pagina: 0,
      })
        .then((response) => {
          setData(response.data.dados.lista);
          setLoading(new Array(response.data.dados.lista.length).fill(false));
        })
        .finally(() => {
          setLoadingTable(false);
        });
    } else {
      formPost('/PortalCorretor/ListarVendaAAceitar', {
        filtros: 'produtorID.toString() == "' + user.produtorID + '"',
        paginacao: -1,
        pagina: 0,
      })
        .then((response) => {
          setData(response.data.dados.lista);
          setLoading(new Array(response.data.dados.lista.length).fill(false));
        })
        .finally(() => {
          setLoadingTable(false);
        });
    }
  }, [setData, setLoadingTable]);

  if (loadingTable) {
    return <Loading />;
  }

  if (data.length === 0) {
    return <EmptyTable />;
  }

  return (
    <TableContainer>
      <thead>
        <tr>
          <th>Criação</th>
          <th>Vigência</th>
          <th>Beneficiário</th>
          <th>Dias Validade</th>
          <th>Nº Proposta</th>
          <th>Produto</th>
          <th>Corretora</th>
          <th>Editar</th>
          <th>Enviar</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          (
            {
              vendaID,
              corretora,
              titular,
              dataCadastro,
              produto,
              dataVigencia,
              diasValidade,
              numeroProposta,
              equipeVendasID,
            },
            index,
          ) => {
            if (!user.corretora && (equipeVendasID === dadoEquipe)) {
              return (
                <tr key={vendaID} 
                    // style={{ background: diasValidade <= 3 ? '#ff000073' : '' }}
                  >
                  <td>{dataCadastro}</td>
                  <td>{dataVigencia}</td>
                  <td>{titular}</td>
                  <td>{diasValidade}</td>
                  <td>{numeroProposta}</td>
                  <td>{produto}</td>
                  <td>{corretora}</td>
                  <td>
                    <Button
                      onClick={() => submit('editar', vendaID, index)}
                      loading={loading[index]}
                      small
                      alternativeButton
                    >
                      Editar
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => submit('enviar', vendaID, index)}
                      loading={loading[index]}
                      small
                      alternativeButton
                    >
                      Enviar
                    </Button>
                  </td>
                </tr>)
            } else if (user.corretora) {
              return (
                <tr key={vendaID} 
                    // style={{ background: diasValidade <= 3 ? '#ff000073' : '' }}
                  >
                  <td>{dataCadastro}</td>
                  <td>{dataVigencia}</td>
                  <td>{titular}</td>
                  <td>{diasValidade}</td>
                  <td>{numeroProposta}</td>
                  <td>{produto}</td>
                  <td>{corretora}</td>
                  <td>
                    <Button
                      onClick={() => submit('editar', vendaID, index)}
                      loading={loading[index]}
                      small
                      alternativeButton
                    >
                      Editar
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => submit('enviar', vendaID, index)}
                      loading={loading[index]}
                      small
                      alternativeButton
                    >
                      Enviar
                    </Button>
                  </td>
                </tr>
              )
            } else {
              return ''
            }
          },
        )}
      </tbody>
    </TableContainer>
  );
};

const TableContainer = styled.table`
  margin: 15px 0;
  border: 0;
  width: 100%;

  th {
    font-size: 16px;
    font-family: 'SegoeUI';
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }

  td {
    font-size: 15px;
    padding: 5px;
    font-family: 'SegoeUI';
    font-weight: normal;
    color: ${({ theme }) => theme.colors.blue};
    vertical-align: middle;
    text-align: center;
    * {
      margin: auto;
    }
  }
`;

export default TableSend;
