import React from 'react';
import { Switch } from 'react-router-dom';
import StartPage from '../pages/StartPage';
import HomePage from '../pages/HomePage';
import SalesPortal from '../pages/SalesPortal';
import Cadastro from '../pages/Cadastro';
import CadastroPlano from '../pages/CadastroPlano';
import CadastroConcluido from '../pages/CadastroConcluido';
import CadastroFinalizado from '../pages/CadastroFinalizado';
import CadastroEnvio from '../pages/CadastroEnvio';
import VendasConfirmadas from '../pages/VendasConfirmadas';
import Corretores from '../pages/Corretores';
import Declaracao from '../pages/Declaracao';
import DeclaracaoResumo from '../pages/DeclaracaoResumo';
import DeclaracaoForm from '../pages/DeclaracaoForm';
import Complementares from '../pages/Complementares';
import DeclaracaoFinalizada from '../pages/DeclaracaoFinalizada';
import Planos from '../pages/Planos';
import Route from './Route';
import CadastroCorretor from '../pages/CadastroCorretor';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={StartPage} />
    <Route path="/dashboard" exact component={HomePage} isPrivate />
    <Route path="/portal" exact component={SalesPortal} isPrivate />
    <Route path="/cadastro" exact component={Cadastro} isPrivate />
    <Route path="/planos" exact component={Planos} isPrivate />
    <Route path="/cadastro-plano" exact component={CadastroPlano} isPrivate />
    <Route
      path="/cadastro-concluido"
      exact
      component={CadastroConcluido}
      isPrivate
    />
    <Route
      path="/cadastro-finalizado"
      exact
      component={CadastroFinalizado}
      isPrivate
    />
    <Route path="/cadastro-envio" exact component={CadastroEnvio} isPrivate />
    <Route path="/vendas-confirmadas" exact component={VendasConfirmadas} isPrivate />
    <Route path="/corretores" exact component={Corretores} isPrivate />
    <Route path="/cadastro-corretor" exact component={CadastroCorretor} isPrivate />
    <Route path="/declaracao-saude" exact component={Declaracao} isPublic />
    <Route
      path="/declaracao-resumo"
      exact
      component={DeclaracaoResumo}
      isPublic
    />
    <Route path="/declaracao-form" exact component={DeclaracaoForm} isPublic />
    <Route
      path="/declaracao-finalizada"
      exact
      component={DeclaracaoFinalizada}
      isPublic
    />
    <Route path="/complementares" exact component={Complementares} isPublic />
  </Switch>
);

export default Routes;
