import styled from 'styled-components';

export const Container = styled.div`
  height: 18px;
  width: 70px;
  padding: 0 26px;
  z-index: 999;
`;

export const StyledBurger = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  margin-left: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  z-index: 999;
  span {
    bottom: 0.2rem;
    right: 0;
    width: 0.7rem;
    height: 0.20rem;
    background: ${({ theme, open, dark }) => !open && !dark ? theme.colors.header.background : theme.colors.header.backgroundLight};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: absolute;
    z-index: 1000;

    :first-child {
      opacity: ${({ open }) => !open ? '0' : '1'};
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    /* :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    } */
    :nth-child(2) {
      opacity: ${({ open }) => !open ? '0' : '1'};
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

export const Icon = styled.img`
  height: 18px;
  padding: 0 26px;
  z-index: 999;
`;
