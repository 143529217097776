import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border: 0;
  background-color: transparent;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    & > *{
      width: 100% !important;
    }
  }
  & > *{
    width: calc(50% - 10px);
  }
`;
