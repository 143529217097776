import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  padding: 10px 0;
  
  h1{
    font-size: 24px;
    font-family: 'Exo';
    margin-bottom: 8px !important;
    color: ${({ theme }) => theme.colors.darkBlue};
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

  }
  &>div:nth-child(2){
    width:180px;
    height: 10px;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.green}
  }
`;

export const Container = styled.div`

  ${({ dark, theme }) => dark && `background-color: ${theme.colors.darkGreen};`}
  display: flex;
  align-items: center;
  justify-content: center;
  &>div{
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    justify-content:  ${({ justifyContent }) => justifyContent ? justifyContent : 'center'};
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.mobile}){
      padding: 10px;
    }
  }
`;
