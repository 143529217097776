import styled from 'styled-components';

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 50px;
  @media (max-width: ${({theme}) =>theme.mobile}){
    display:none;
  }
`;

export const Main = styled.main`
  padding: 40px;
  @media (max-width: ${({theme}) =>theme.mobile}){
    padding: 10px;
  }
  background-color: ${({theme}) =>theme.colors.backgroundWhite};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &>div{
    padding: 0 10px;
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  p{
    margin-bottom: 10px;
    line-height: 1.75;
  }

  span{
    font-weight: bold;
    color: ${({theme}) =>theme.colors.green};
  }
`;

export const MessageContainer = styled.p`
  padding: 35px;
  background-color: ${({theme}) =>theme.colors.backgroundWhite};
  border-radius: 30px;
`;

export const Item = styled.div`
  height: 100%;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start !important;

  span{
    margin-right: 10px;
  }
  h3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  p{
    font-size: 14px;
  }
`;
export const Beneficiarios = styled.div`
  align-items: flex-start !important;

  height: 100%;
  align-self: flex-start;
  flex-direction: column;
  h3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: ${({theme}) =>theme.colors.green};
  }
`;
export const Esclarecimentos = styled.div`
  align-items: flex-start !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-self: flex-start;
  h3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }

  textarea{
    height: 180px;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    width: 100%;
    border: 0;
    margin-bottom: 20px;
    background-color: ${({theme}) => theme.colors.backgroundWhite};
    border-radius: 30px;
  }
  textarea::placeholder{
    font-family: 'SegoeUI';
    font-size: 20px;
    color: ${({theme}) => theme.colors.blue};
  }

  p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    font-family: 'Exo';

  }
`;

export const Row = styled.div`
  width: 100%;
  padding-top: 10px !important;
`;

export const Question = styled.div`
  width: 35%;
  margin-bottom: 10px;
  span{
    margin-right: 10px;
  }
`;

export const Column = styled.div`
  &>*{
    width: 150px;
    text-align: center;
    margin: 0 auto;
  }
  &>div{
    display: flex;
    width: 150px;
    justify-content: space-between;
    align-items: center;
  }
`;
