import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import HeaderDeclaracao from '../../components/Headers/HeaderDeclaracao';
import FullPageFlexSection from '../../components/Sections/FullPageFlexSection';
import Sidebar from '../../components/Sidebar';
import Container from '../../components/Containers/Container';
import RadioButton from '../../components/Inputs/RadioButton';
import Pagination from '../../components/Pagination';
import api, { formPost } from '../../services/api';
import Loading from '../../components/Tables/Loading';
import { useToast } from '../../hooks/toast';
import Button from '../../components/Buttons/Button';

import {
  Main,
  Flex,
  Item,
  Beneficiarios,
  Esclarecimentos,
  Row,
  Question,
  Column,
} from './styles';

const DeclaracaoResumo = () => {
  const history = useHistory();
  const [radioValue, setRadio] = useState(false);
  const { state } = useLocation();
  const { declaracaoSaude, beneficiarios, vendaID } = state;
  const [perguntas, setPerguntas] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    formPost('/PortalCorretor/ListarDeclaracaoSaude', {
      paginacao: -1,
      pagina: 0,
      vendaID: state.vendaID,
    })
      .then((response) => {
        setPerguntas(response.data.dados.perguntas);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, [setPerguntas]);

  const submit = () => {
    setLoading(true);
    api
      .post('/PortalCorretor/SalvarDeclaracaoSaude', {
        ...declaracaoSaude,
        vendaID,
      })
      .then((response) => {
        if (response.data.sucesso) {
          history.push('declaracao-finalizada');
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setLoading(false);
        addToast({
          title: 'Erro ao salvar declaração',
          type: 'error',
        });
      });
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ width: '100%' }}>
          <HeaderDeclaracao />
          <Main>
            <FullPageFlexSection style={{ flexDirection: 'column' }}>
              <Container title="Resumo">
                {loadingPage ? (
                  <Loading />
                ) : (
                  <>
                    <p>
                      Você respondeu sim para os itens listados abaixo. Agora,
                      descreva e esclareça a doença existente (data, tratamento,
                      quadro atual e tudo mais que julgar importante para
                      avaliação de sua saúde). Passe o mouse ou clique sob o
                      item para relembrar a questão.
                    </p>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        resposta: perguntas.map(
                          ({ operadoraDeclaracaoSaudeID }) => ({
                            beneficiario: beneficiarios.map(() => ''),
                            operadoraDeclaracaoSaudeID,
                          }),
                        ),
                      }}
                    >
                      {() => (
                        <Form>
                          <Flex style={{ flexDirection: 'column' }}>
                            <Row style={{ height: 'initial' }}>
                              <Question />
                              {beneficiarios.map(({ nome }) => (
                                <Column key={nome}>
                                  <p>{nome}</p>
                                </Column>
                              ))}
                            </Row>
                            <FieldArray name="resposta">
                              <>
                                {perguntas.map(
                                  (
                                    {
                                      pergunta,
                                      codigo,
                                      operadoraDeclaracaoSaudeID,
                                    },
                                    index,
                                  ) => (
                                    <Row key={codigo}>
                                      <Question>
                                        <span>{index + 1}</span>
                                        {pergunta}
                                      </Question>
                                      <FieldArray
                                        name={`resposta.${index}.beneficiario`}
                                      >
                                        <>
                                          {beneficiarios.map(
                                            (b, beneficiario) => {
                                              const {
                                                sim,
                                              } = declaracaoSaude.respostas.find(
                                                (resposta) =>
                                                  resposta.operadoraDeclaracaoSaudeID ===
                                                  operadoraDeclaracaoSaudeID &&
                                                  resposta.vendaBeneficiarioID ===
                                                  b.vendaBeneficiarioID,
                                              );

                                              return (
                                                <Column
                                                  key={b.vendaBeneficiarioID}
                                                >
                                                  <div>
                                                    <RadioButton
                                                      label="sim"
                                                      name={`resposta.${index}.beneficiario.${beneficiario}`}
                                                      value="S"
                                                      checked={sim}
                                                      disabled
                                                    />
                                                    <RadioButton
                                                      label="não"
                                                      name={`resposta.${index}.beneficiario.${beneficiario}`}
                                                      value="N"
                                                      checked={!sim}
                                                      disabled
                                                    />
                                                  </div>
                                                </Column>
                                              );
                                            },
                                          )}
                                        </>
                                      </FieldArray>
                                    </Row>
                                  ),
                                )}
                              </>
                            </FieldArray>
                          </Flex>
                        </Form>
                      )}
                    </Formik>
                    {declaracaoSaude.respostas.map((resposta) => {
                      if (!resposta.sim) {
                        return null;
                      }
                      const perguntaFiltrada = perguntas.filter(
                        ({ operadoraDeclaracaoSaudeID }) =>
                          operadoraDeclaracaoSaudeID ===
                          resposta.operadoraDeclaracaoSaudeID,
                      );
                      const beneficiarioFiltrada = beneficiarios.filter(
                        ({ vendaBeneficiarioID }) =>
                          vendaBeneficiarioID === resposta.vendaBeneficiarioID,
                      );
                      const { pergunta, ordem } = perguntaFiltrada[0];
                      const { nome } = beneficiarioFiltrada[0];
                      return (
                        <Flex
                          key={
                            resposta.operadoraDeclaracaoSaudeID +
                            resposta.vendaBeneficiarioID
                          }
                        >
                          <Item>
                            <h3>Item</h3>

                            <p>
                              <span>{ordem}</span>
                              {pergunta}
                            </p>
                          </Item>
                          <Beneficiarios>
                            <h3>Beneficiários</h3>
                            <p>{nome}</p>
                            <h3>Data do diagnóstico</h3>
                            <p>{resposta.dataOcorrido}</p>
                          </Beneficiarios>
                          <Esclarecimentos>
                            <h3>Esclarecimentos</h3>
                            <p>{resposta.esclarecimento}</p>
                          </Esclarecimentos>
                        </Flex>
                      );
                    })}
                  </>
                )}
              </Container>
              <Container title="Faça sua declaração">
                <Flex>
                  <p>
                    <span>1.</span> Declaro que me foram oferecidas as opções 2
                    e 3 abaixo especificadas e que, tendo conhecimento de todos
                    os itens da Declaração de Saúde, optei por não fazer a
                    entrevista médica qualificada, assumindo total
                    responsabilidade pelas informações por mim prestadas nesta
                    declaração.
                  </p>
                  <p>
                    <span>2.</span> Declaro que fui orientado por médico
                    particular credenciado da operadora no preenchimento desta
                    Declaração de Saúde.
                  </p>
                  <p>
                    <span>3.</span> Declaro que fui orientado por médico
                    particular de minha confiança e/ou médico da administradora
                    de benefícios no preenchimento desta Declaração de Saúde.
                  </p>
                </Flex>
              </Container>
              <Container title="Indicador de verdade">
                <Flex>
                  <p>
                    Declaro, para todos os efeitos legais, que li e entendi esta
                    declaração e que nada omiti sobre meu estado de saúde e de
                    meus dependentes, tendo prestado informações completas e
                    verídicas. Autorizo, também, que médicos, clínicas ou
                    quaisquer entidades públicas ou privadas prestem aos médicos
                    da operadora informações sobre meu estado de saúde e de meus
                    dependentes, bem como as moléstias das quais tenhamos,
                    porventura, sofrido e o resultado de exames, isentando-os,
                    desde já, de qualquer responsabilidade que implique ofensa
                    ou sigilo profissional.
                  </p>
                  <div>
                    <RadioButton
                      label="Declaro ser verdade"
                      name="radio"
                      value={radioValue}
                      checked={radioValue}
                      onChange={() => {
                        setRadio((s) => !s);
                      }}
                    />
                    <Pagination
                      anterior
                      anteriorOnClick={() => history.go(-1)}
                    />
                    {radioValue && (
                      <Button style={{ marginBottom: '10px' }} onClick={() => submit()} loading={loading}>
                        Confirmar
                      </Button>
                    )}
                  </div>
                </Flex>
              </Container>
            </FullPageFlexSection>
          </Main>
        </div>
      </div>
    </>
  );
};

export default DeclaracaoResumo;
