import React from 'react';
import ReactLoading from 'react-loading';
import { Container } from './styles';

const Button = ({ children, loading, ...props }) => (
  <Container {...props} disabled={loading}>
    {loading ? (
      <ReactLoading type="spin" color="#fff" height="30px" width="30px" />
    ) : (
      children
    )}
  </Container>
);

export default Button;
