import React from 'react';
import styled from 'styled-components';
import EmptyTable from '../EmptyTable';

const TableHistory = ({ data }) => {
  if (data.length === 0) {
    return <EmptyTable />;
  }

  return (
    <TableContainer>
      <thead>
        <tr>
          <th>Status Beneficiário</th>
          <th>Status Auditoria</th>
          <th>Data</th>
          <th>Usuário</th>
          <th>Observação</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          ({
            vendaHistoricoID,
            observacao,
            dataCadastro,
            usuario,
            situacaoBeneficiario,
            situacaoAuditoria,
          }) => (
            <tr key={vendaHistoricoID}>
              <td>{situacaoBeneficiario}</td>
              <td>{situacaoAuditoria}</td>
              <td>{dataCadastro}</td>
              <td>{usuario}</td>
              <td style={{ backgroundColor:(situacaoAuditoria === 'Com Pendência' ? 'rgba(255, 0, 0, 0.45)' : '')}}>{observacao}</td>
            </tr>
          ),
        )}
      </tbody>
    </TableContainer>
  );
};

const TableContainer = styled.table`
  margin: 15px 0;
  border: 0;
  width: 100%;

  th {
    font-size: 16px;
    font-family: 'SegoeUI';
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }

  td {
    font-size: 15px;
    padding: 5px;
    font-family: 'SegoeUI';
    font-weight: normal;
    color: ${({ theme }) => theme.colors.blue};
    vertical-align: middle;
    text-align: center;
    * {
      margin: auto;
    }
  }
`;

export default TableHistory;
